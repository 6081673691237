import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { SentinelsApi, sentinelsApiRef } from '../api';
import { useAsync } from 'react-use';
import { trimEnd } from 'lodash';

export function useSentinelsApi<T>(
  f: (api: SentinelsApi) => Promise<T>,
  deps: any[] = [],
) {
  const sentinelsApi = useApi(sentinelsApiRef);
  return useAsync(async () => {
    return await f(sentinelsApi);
  }, deps);
}

export const useBaseUrl = () => {
  try {
    const config = useApi(configApiRef);
    return config.getOptionalString('app.baseUrl');
  } catch {
    return undefined;
  }
};

export const useBasePath = () => {
  const base = 'http://localhost:7007';
  const url = useBaseUrl() ?? '/';
  const { pathname } = new URL(url, base);
  return trimEnd(pathname, '/');
};
