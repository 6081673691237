import React, { Fragment } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { nvdApiRef } from '../../api';
import { NvdConfig } from '../../config';
import { Cve } from '@giocolas/backstage-plugin-nvd-common';
import { trimEnd } from 'lodash';
import { ResponseErrorPanel } from '@backstage/core-components';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Typography
} from '@material-ui/core';

const CveSingleCard = (props: { item: Cve, nvdConfig: NvdConfig }) => {
  const { item, nvdConfig } = props;

  return( 
    <Card style={{ boxShadow: 'none' }}>
      <CardContent>
        <Box>
          <Typography variant='body2'>
            <b>{item.id}</b>
          </Typography>
          <Typography variant='body2'>
            published on{' '}
            {new Date(
              item.published
            ).toLocaleDateString()}
          </Typography>
          <Typography variant='body2' style={{ marginBottom: '8px' }}>
            {item.sourceIdentifier}
          </Typography>
          <Fragment>
            {item.score ? (
              <Chip size='small' variant='outlined' label={item.score} />
            ) : null}
            <Chip
              size='small'
              label={item.severity || 'N/A'}
              style={{
                backgroundColor: nvdConfig.readSeverityColor(item.severity)?.backgroundColor,
                color: nvdConfig.readSeverityColor(item.severity)?.foregroundColor
              }}
            />
          </Fragment>
          <Typography variant="body2">
            {item.description}
          </Typography>
        </Box>
      </CardContent>
      <CardActions>
        <Button color='primary' onClick={() => {
          window.open(`${trimEnd(nvdConfig.readDetailUrl(), '/')}/${item.id}`, `_blank`, `noreferrer`);
        }}
        >
          Open
        </Button>
      </CardActions>
    </Card>
  );
}

export const CveSummaryCard = () => {

  const configApi = useApi(configApiRef);
  const nvdApi = useApi(nvdApiRef);

  const nvdConfig = NvdConfig.create(configApi);

  const { value, loading, error } = useAsync(() => {
    return nvdApi.getSummary();
  }, [ nvdApi, ]);

  if (loading) {
    return (
      <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <>
      {value?.map(item => (
        <Fragment key={item.id}>
          <CveSingleCard nvdConfig={nvdConfig} item={item} />
          <Divider />
        </Fragment>
      ))}
    </>
  );
}