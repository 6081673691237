import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@material-ui/core/Divider';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Grid } from '@material-ui/core';
const {
  ScoringGetAttributeTitles,
  ScoringSoftwareAttributes,
  ScoringGetSoftwarePoints,
  ScoringSoftwareTitleSummary,
  ScoringAddSoftwarePoints,
  ScoringSummaries,
  ScoringCatalogSoftware,
} = require('./ScoringApiClient');
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography, Box } from '@material-ui/core';
import { ScoringAttribute } from './ScoringAttribute';
import { ScoringLegend } from './ScoringLegend';
import { AttributeData, StoreAttributeData } from '../../types';

export const ScoringDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const softId = location.state.id;
  const [expanded, setExpanded] = useState<string[]>([]);
  const [attributeData, setAttributeData] = useState<AttributeData[]>([]);
  const [changedData, setChangedData] = useState<AttributeData>(
    () => ({} as AttributeData),
  );

  const [storeAttributeData, setStoreAttributeData] =
    useState<StoreAttributeData>({
      asIsScore: null,
      attributeId: null,
      commentField: '',
      isDeclared: false,
      softId: '',
      titleId: null,
      toBeScore: null,
    });

  const [scoreAverages, setScoreAverages] = useState<
    {
      software_id: string;
      titles: {
        title_id: number;
        title_asis_summary_score: number;
        title_tobe_summary_score: number;
      }[];
    }[]
  >([]);

  const { titleValue, titleLoading } = ScoringGetAttributeTitles();
  const { attributeValue, attributeLoading } = ScoringSoftwareAttributes();
  const { titleSummaryValue, titleSummaryLoading } =
    ScoringSoftwareTitleSummary();
  const { pointsValue, pointsLoading } =
    ScoringGetSoftwarePoints(storeAttributeData);
  const { softwarePointsLoading, softwarePointsValue } =
    ScoringAddSoftwarePoints(storeAttributeData, storeAttributeData);

  const { sumValue, sumLoading } = ScoringSummaries();
  const { catalogValue, catalogLoading } = ScoringCatalogSoftware();

  useEffect(() => {
    setAttributeData(() => {
      if (!pointsLoading && pointsValue && pointsValue.data) {
        return pointsValue.data;
      } else {
        return [];
      }
    });
  }, [pointsLoading, pointsValue]);

  useEffect(() => {
    if (!sumLoading && !titleSummaryLoading) {
      const averages = sumValue.data.map(
        (software: {
          software_id: string;
          as_is_score_summary: number;
          to_be_score_summary: number;
        }) => ({
          software_id: software.software_id,
          as_is_score_summary: software.as_is_score_summary,
          to_be_score_summary: software.to_be_score_summary,
          titles: titleSummaryValue.data
            .filter(
              (title: { software_id: string }) =>
                title.software_id === software.software_id,
            )
            .map(
              (title: {
                title_id: number;
                title_asis_summary_score: number;
                title_tobe_summary_score: number;
              }) => ({
                title_id: title.title_id,
                title_asis_summary_score: title.title_asis_summary_score,
                title_tobe_summary_score: title.title_tobe_summary_score,
              }),
            ),
        }),
      );
      setScoreAverages(averages);
    }
  }, [catalogValue, titleSummaryValue, catalogLoading, titleSummaryLoading]);

  useEffect(() => {
    if (
      !softwarePointsLoading &&
      softwarePointsValue &&
      softwarePointsValue.responseData
    ) {
      const responseObj: {
        software_id: string;
        title_id: number;
        attribute_id: number;
        as_is_score: number;
        changed_at: Date;
        id?: number;
        is_declared: boolean;
        not_declared_comment: string;
        to_be_score: number;
      } = softwarePointsValue.responseData.data;
      const existingIndex = attributeData.findIndex(
        (item: {
          software_id: string;
          title_id: number;
          attribute_id: number;
        }) =>
          item.software_id === responseObj.software_id &&
          item.title_id === responseObj.title_id &&
          item.attribute_id === responseObj.attribute_id,
      );

      if (existingIndex !== -1) {
        const updatedAttributeData: any = [...attributeData];
        updatedAttributeData[existingIndex] = responseObj;
        setAttributeData(updatedAttributeData);
      } else {
        setAttributeData(prevData => [...prevData, responseObj]);
      }

      const existingAveragesIndex = scoreAverages.findIndex(
        (item: { software_id: string; titles: { title_id: number }[] }) =>
          item.software_id === responseObj.software_id &&
          item.titles.find(title => title.title_id === responseObj.title_id),
      );

      if (existingAveragesIndex !== -1) {
        const updatedScoreAverages: {
          software_id: string;
          titles: {
            title_id: number;
            title_asis_summary_score: number;
            title_tobe_summary_score: number;
          }[];
        }[] = [...scoreAverages];
        const softwareToUpdate = updatedScoreAverages[
          existingAveragesIndex
        ] as {
          software_id: string;
          titles: {
            title_id: number;
            title_asis_summary_score: number;
            title_tobe_summary_score: number;
          }[];
        };

        const titleToUpdateIndex = softwareToUpdate.titles.findIndex(
          (title: { title_id: number }) =>
            title.title_id === responseObj.title_id,
        );

        if (titleToUpdateIndex !== -1) {
          softwareToUpdate.titles[titleToUpdateIndex].title_asis_summary_score =
            softwarePointsValue.responseData.asIsAvgScore;
          softwareToUpdate.titles[titleToUpdateIndex].title_tobe_summary_score =
            softwarePointsValue.responseData.toBeAvgScore;
        } else {
          softwareToUpdate.titles.push({
            title_id: responseObj.title_id,
            title_asis_summary_score:
              softwarePointsValue.responseData.asIsAvgScore,
            title_tobe_summary_score:
              softwarePointsValue.responseData.toBeAvgScore,
          });
        }
        updatedScoreAverages[existingAveragesIndex] = softwareToUpdate;
        setScoreAverages(updatedScoreAverages);
      } else {
        const newSoftware = {
          software_id: responseObj.software_id,
          as_is_score_summary:
            softwarePointsValue.responseData.asIsSoftAvgScore,
          to_be_score_summary:
            softwarePointsValue.responseData.toBeSoftAvgScore,
          titles: [
            {
              title_id: responseObj.title_id,
              title_asis_summary_score: softwarePointsValue.responseData
                .asIsAvgScore
                ? softwarePointsValue.responseData.asIsAvgScore
                : 0.0,
              title_tobe_summary_score: softwarePointsValue.responseData
                .toBeAvgScore
                ? softwarePointsValue.responseData.toBeAvgScore
                : 0.0,
            },
          ],
        };
        setScoreAverages(prevScoreAverages => [
          ...prevScoreAverages,
          newSoftware,
        ]);
      }
    }
  }, [softwarePointsLoading, softwarePointsValue]);

  if (
    titleLoading ||
    attributeLoading ||
    pointsLoading ||
    titleSummaryLoading ||
    softwarePointsLoading ||
    sumLoading ||
    catalogLoading
  )
    return <div>Loading...</div>;

  const softData = catalogValue.data.find(
    (item: { metadata: { uid: string } }) => item.metadata.uid === softId,
  );

  const onAccordionChange =
    (panel: string) => (_: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(
        isExpanded
          ? [...expanded, panel]
          : expanded.filter(item => item !== panel),
      );
    };

  const commonScoringAttributeProps = {
    softId,
    attributeData,
    setAttributeData,
    scoreAverages,
    storeAttributeData,
    setStoreAttributeData,
    changedData,
    setChangedData,
  };

  return (
    <>
      <div
        key={softId}
        style={{
          backgroundColor: '#152935',
          width: '100%',
          height: 70,
          padding: 10,
        }}
      >
        <Grid container>
          <Grid item xs={10} sm={10} md={10} lg={11} xl={11}>
            <Stack
              style={{
                color: '#FFFFFF',
                fontSize: '20px',
                fontFamily: 'Cairo, sans-serif',
                fontWeight: 700,
                marginBottom: '1px',
              }}
            >
              Service Excellence Maturity Model!
            </Stack>
            <Stack
              style={{
                color: '#FFFFFF',
                opacity: 0.8,
                fontSize: '16px',
                fontFamily: 'Cairo, sans-serif',
                fontWeight: 400,
              }}
            >
              {softData.metadata.title}
            </Stack>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
            <Stack
              style={{
                color: '#FFFFFF',
                fontSize: '14px',
                fontWeight: 700,
                marginBottom: '3px',
              }}
            >
              Owner
            </Stack>
            <Stack
              style={{ color: 'rgba(255, 255, 255, 0.8)', fontSize: '14px' }}
            >
              {softData.spec.owner}
            </Stack>
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        // spacing={2}
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 10,
          paddingBottom: 10,
          width: '100%',
        }}
      >
        <Grid item xs={12} sm={3} md={2} lg={3} xl={4}>
          <Button
            variant="contained"
            style={{ margin: 3 }}
            onClick={() => navigate(-1)}
          >
            Go Back
          </Button>
          <Button variant="outlined" onClick={() => setExpanded([])}>
            Close tabs
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          md={10}
          lg={9}
          xl={8}
          style={{
            display: 'flex',
          }}
        >
          <ScoringLegend isMain={false} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 10,
          paddingBottom: 10,
          width: '100%',
        }}
      >
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <Typography variant="h5" style={{ color: '#004E75' }}>
            Group and attributes
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Typography
            variant="h5"
            style={{
              color: '#004E75',
              width: '45%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            AS IS
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Typography
            variant="h5"
            style={{
              color: '#004E75',
              width: '40%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            TO BE
          </Typography>
        </Grid>
      </Grid>
      {titleValue.data.map(
        (item: {
          display_order: number;
          title_name: string;
          title_id: number;
        }) => (
          <Box
            key={item.display_order}
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Accordion
              key={item.display_order}
              expanded={expanded.includes(item.display_order.toString())}
              onChange={onAccordionChange(item.display_order.toString())}
            >
              <AccordionSummary
                style={{ backgroundColor: '#F2F5F8', height: '40px' }}
              >
                <Grid container spacing={2} direction="row">
                  <div
                    style={{
                      width: '3%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ExpandMoreIcon
                      style={{
                        color: '#008FD6',
                        transition: 'transform 0.3s ease',
                        transform: expanded.includes(
                          item.display_order.toString(),
                        )
                          ? 'rotate(360deg)'
                          : 'rotate(270deg)',
                      }}
                    />
                  </div>
                  <ScoringAttribute
                    content={item.title_name}
                    isTitle={true}
                    titleId={item.title_id}
                    attributeId={0}
                    titleName={item.title_name}
                    {...commonScoringAttributeProps}
                  />
                </Grid>
              </AccordionSummary>
              <Divider />
              {attributeValue.data.map(
                (attr: {
                  title_id: number;
                  display_order: number;
                  attribute_name: string;
                  attribute_id: number;
                }) =>
                  attr.title_id === item.title_id && (
                    <AccordionDetails
                      key={attr.display_order + item.display_order}
                    >
                      <Grid container direction="row" spacing={2}>
                        <div
                          style={{
                            width: '3%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        ></div>
                        <ScoringAttribute
                          content={attr.attribute_name}
                          isTitle={false}
                          titleId={item.title_id}
                          attributeId={attr.attribute_id}
                          titleName={item.title_name}
                          {...commonScoringAttributeProps}
                        />
                      </Grid>
                    </AccordionDetails>
                  ),
              )}
            </Accordion>
          </Box>
        ),
      )}
    </>
  );
};
