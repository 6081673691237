import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { NvdApi, GetCvesUsingNamedParametersRequest, GetCvesUsingFiltersRequest } from './NvdApi';
import { ResponseError } from '@backstage/errors';
import { Cve } from '@giocolas/backstage-plugin-nvd-common';

export class NvdClient implements NvdApi {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { fetchApi: FetchApi; discoveryApi: DiscoveryApi }) {
    this.fetchApi = options.fetchApi;
    this.discoveryApi = options.discoveryApi;
  }

  private addFilter(existingFilter: string | undefined, paramName: string, paramValue: string): string {
    if (!existingFilter) {
      return '?'.concat(paramName, '=', paramValue);
    }
    return existingFilter.concat('&', paramName, '=', paramValue);
  }

  async getBaseUrl(): Promise<string> {
    return this.discoveryApi.getBaseUrl('nvd');
  }

  async getSummary(): Promise<Cve[]> {
    const url = `${await this.getBaseUrl()}/summary`;

    const response = await this.fetchApi.fetch(`${url}`, {
      method: 'GET',
    });

    const data = (await response.json()) as Cve[];
    
    return data;
  }

  async getUsingNamedParameters(req: GetCvesUsingNamedParametersRequest): Promise<Cve[]> {
    const url = `${await this.getBaseUrl()}/list`;

    let queryParams: string | undefined;
    if (req.severityTypes) {
      queryParams = this.addFilter(queryParams, 'severityTypes', req.severityTypes.join(','));
    }
    if (req.scoreMin) {
      queryParams = this.addFilter(queryParams, 'scoreMin', req.scoreMin.toString());
    }
    if (req.scoreMax) {
      queryParams = this.addFilter(queryParams, 'scoreMax', req.scoreMax.toString());
    }
    if (req.publishedDateFrom) {
      queryParams = this.addFilter(queryParams, 'publishedDateFrom', req.publishedDateFrom.concat('T00:00:00.000Z'));
    }
    if (req.publishedDateTo) {
      queryParams = this.addFilter(queryParams, 'publishedDateTo', req.publishedDateTo.concat('T23:59:59.999Z'));
    }
    if (req.orderColumn) {
      queryParams = this.addFilter(queryParams, 'orderColumn', req.orderColumn);
    }
    if (req.orderType) {
      queryParams = this.addFilter(queryParams, 'orderType', req.orderType);
    }
    if (req.limit) {
      queryParams = this.addFilter(queryParams, 'limit', req.limit.toString());
    }

    const response = await this.fetchApi.fetch(`${url}${queryParams || ''}`, {
      method: 'GET',
    });

    const data = (await response.json()) as Cve[];
    
    return data;
  }

  async getUsingFilters(req: GetCvesUsingFiltersRequest): Promise<Cve[]> {
    const { filter = [] } = req;
    const params: string[] = [];

    for (const filterItem of [filter].flat()) {
      const filterParts: string[] = [];
      for (const [key, value] of Object.entries(filterItem)) {
        for (const v of [value].flat()) {
          if (typeof v === 'string') {
            filterParts.push(
              `${encodeURIComponent(key)}=${encodeURIComponent(v)}`,
            );
          }
        }
      }

      if (filterParts.length) {
        params.push(`filter=${filterParts.join(',')}`);
      }
    }

    const query = params.length ? `?${params.join('&')}` : '';
    const url = `${await this.getBaseUrl()}/all`;
    const response = await this.fetchApi.fetch(`${url}/${query}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    const data = (await response.json()) as Cve[];
    
    return data;
  }
}