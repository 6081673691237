import React, { Fragment } from 'react';
import { PageWithHeader, Content } from '@backstage/core-components';
import { Grid, Typography, Container, Button, Accordion, AccordionSummary, AccordionDetails, Divider,  } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const Manifesto = () => {
    return (
        <Fragment>
            <PageWithHeader title="OnePlatform Manifesto" subtitle="Key Concepts" themeId="home">
                <Content noPadding>
                    <Container style={{ marginLeft: "auto", marginRight: "auto", paddingTop: 45, paddingBottom: 45, maxWidth: "97%" }}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={2}>
                                                <ListIcon />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="h5">Table of Contents</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="row">
                                            <Button href="#1" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>1 - Modular and Interoperable</Button>
                                            <Button href="#2" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>2 - Consistent</Button>
                                            <Button href="#3" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>3 - Future-proof</Button>
                                            <Button href="#4" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>4 - Sustainable</Button>
                                            <Button href="#5" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>5 - Self Service</Button>
                                            <Button href="#6" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>6 - Resilient</Button>
                                            <Button href="#7" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>7 - Cooperative</Button>
                                            <Button href="#8" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>8 - Data sharing</Button>
                                            <Button href="#9" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>9 - Flexible</Button>
                                            <Button href="#10" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>10 - Agnostic</Button>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={9} style={{ marginLeft: 50 }}>
                                <Typography variant='body1'>
                                    <div>At TeamSystem, we build a <b>platform to support our services.</b> This to achieve economies of scale and ensure non-functional requirements. Our platform is <b>the foundation of our product strategy</b> and supports all other products. Every design and service decision must be aligned with our strategic objectives and respect the need of the market we need to serve.</div>
                                </Typography>
                                <Typography variant='body1'>
                                    <div><b>Our approach is “Platform as a product”,</b> emphasizing that it <b>is our first and foremost product</b> and serves all others in our portfolio. It must be developed with the concentration, attention, and care that each product deserves.</div>
                                </Typography>
                                <Typography variant='body1'>
                                    <div>We are committed in building a platform that follows these <b>principles</b>:</div>
                                </Typography>
                                <Divider style={{marginTop: "20px", marginBottom: "20px"}} />

                                <div id="1">
                                    <Typography variant="h3">Modular and Interoperable</Typography>
                                    <Typography variant="body1">Modularity and interoperability allow you to easily integrate new services and replace existing ones without significant impact.
The platform is designed with a modular and agnostic architecture, independent of both products and underlying technologies. This approach facilitates the integration and interchangeability of vendor-specific services. It means we can upgrade or replace components without disruption, ensuring maximum flexibility and supporting continuous innovation</Typography>
                                    <br/>
                                </div>

                                <div id="2">
                                    <Typography variant="h3">Consistent</Typography>
                                    <Typography variant="body1">The platform must be uniform and predictable, providing consistency across different modules and services. We want to provide a uniform, intuitive and usable experience to developers, product teams and end customers regardless of which part of the system they are using, reducing the learning curve and improving operational efficiency.</Typography>
                                    <br/>
                                </div>

                                <div id="3">
                                    <Typography variant="h3">Future-proof</Typography>
                                    <Typography variant="body1">We think long-term, ensuring that the platform can evolve with new technologies and market demands.
The platform adopts modern but consolidated technologies to create solid and sustainable technological foundations in the long term.
We use Continuous Discovery practices to anticipate market trends and user needs, ensuring that the platform remains relevant and creates value over time.</Typography>
                                    <br/>
                                </div>

                                <div id="4">
                                    <Typography variant="h3">Sustainable</Typography>
                                    <Typography variant="body1">Sustainability, both technical and operational, is a central principle, to reduce technical debt and maintain efficiency, lower costs and consumption.
We promote green software engineering practices to minimize environmental impact.</Typography>
                                    <br/>
                                </div>

                                <div id="5">
                                    <Typography variant="h3">Self Service</Typography>
                                    <Typography variant="body1">Users must be able to configure and use services without depending on external interventions. User autonomy is a key principle. Through detailed documentation and self-service support, we reduce the need for direct technical assistance, improving operational efficiency. We guide users with clear rules and best practices, maintaining flexibility and context to foster innovation.</Typography>
                                    <br/>
                                </div>

                                <div id="6">
                                    <Typography variant="h3">Resilient</Typography>
                                    <Typography variant="body1">The platform is designed to be robust, ensuring continuity even in stress or failure situations.
We ensure that the system remains constantly available, stable, secure and operational even under stress. This involves minimizing downtime, implementing failover mechanisms and carrying out regular maintenance and monitoring. The goal is to provide uninterrupted service and maintain user trust by preventing failures and addressing issues quickly.</Typography>
                                    <br/>
                                </div>

                                <div id="7">
                                    <Typography variant="h3">Cooperative</Typography>
                                    <Typography variant="body1">It must foster collaboration between teams, products and users, creating an integrated ecosystem. We believe in open collaboration.
We believe in open collaboration, encouraging contributions, integration and knowledge sharing. The platform grows thanks to the contribution of all those who use it.
We expect and promote direct contributions: even the answers to specific needs can become standard through the contribution and continuous feedback of everyone.</Typography>
                                    <br/>
                                </div>

                                <div id="8">
                                    <Typography variant="h3">Data sharing</Typography>
                                    <Typography variant="body1">Data is not just a by-product, but a key value of the platform, designed to be accessible and useful.
We promote the certification and quality of data shared between services and products. This approach guarantees reliable answers to users and exploits the opportunities offered by the market. The provenance and authenticity of content are priorities to maintain integrity and trust in information.</Typography>
                                    <br/>
                                </div>

                                <div id="9">
                                    <Typography variant="h3">Flexible</Typography>
                                    <Typography variant="body1">It must easily adapt to different needs and variable contexts.
We enhance the elasticity of the platform's services through automation and rapid scalability, ensuring both high performance and cost optimization. We constantly monitor to anticipate needs, managing increasing volumes of data and transactions reliably.</Typography>
                                    <br/>
                                </div>

                                <div id="10">
                                    <Typography variant="h3">Agnostic</Typography>
                                    <Typography variant="body1">The platform must not be tied to specific technologies or suppliers, offering maximum flexibility.
We design the system so that it does not depend on a specific technology, supplier or product.</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Content>
            </PageWithHeader>
        </Fragment>
    )
};