import React, { Fragment } from "react";
import { PageWithHeader, Content } from '@backstage/core-components';
import { Grid, Typography, Divider, Container, Button, Tooltip, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const SystemArchitecture = () => {
    return (
        <Fragment>
            <PageWithHeader title="System Architecture Overview" subtitle="Key Concepts" themeId="home">
                <Content noPadding>
                    <Container style={{ marginLeft: "auto", marginRight: "auto", paddingTop: 45, paddingBottom: 45, maxWidth: "97%" }}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={2}>
                                                <ListIcon />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="h5">Table of Contents</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="row">
                                            <Button href="#1" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>1. General overview</Button>
                                            <Button href="#2" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>2. Legacy App</Button>
                                            <Button href="#3" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>3. oneFront Cloud Native App</Button>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={9} style={{ marginLeft: 50 }}>
                                <div id="1">
                                    <Typography variant="h3">1. General overview</Typography>
                                    <Typography variant="body1">
                                        Due to its history and technological evolution, two main macro-areas coexist - and are evolving - in TeamSystem:
                                        <ul>
                                            <li>Legacy Apps</li>
                                            <li>Cloud Native Apps</li>
                                        </ul>
                                        Below you can find a representation of the TeamSystem Software and Services ecosystem.
                                    </Typography>
                                    <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} style={{ textAlign: "center" }}>
                                            <a href="/resources/images/architecture/generalOverview.png" target="_blank">
                                                <img src="/resources/images/architecture/generalOverview.png" alt="General Overview" width="100%" />
                                            </a>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div id="2" style={{ marginTop: 30 }}>
                                    <Typography variant="h3">2. Legacy App</Typography>
                                    <Typography variant="body1">
                                        If the Cloud Native Apps, by their nature, use the platform services natively, for the Legacy Apps this is made possible thanks to a component called "<b>Integration Hub</b>", which acts as <b>a "bridge" between the Legacy App and the API Gateway</b>.
                                    </Typography>
                                    <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} style={{ textAlign: "center" }}>
                                            <a href="/resources/images/architecture/integrationHubFlow.png" target="_blank">
                                                <img src="/resources/images/architecture/integrationHubFlow.png" alt="Integration Hub Flow" width="100%" />
                                            </a>
                                        </Grid>
                                    </Grid>
                                    <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                                </div>
                                <div id="3" style={{ marginTop: 30 }}>
                                    <Typography variant="h3">3. oneFront Cloud Native App</Typography>
                                    <Typography variant="body1">
                                        <b>OneFront</b> is a <b>framework</b> that <b>allows developers to build a OnePlatform-compliant application</b> quickly and effectively.<br /><br />
                                        oneFront is built with the <b>8 Design Principles</b> in mind, so the main goal is to <b>let the developer think only about his application domain</b>, without having to think about the authentication flows, to implement the logic to make an API call and so on.<br /><br />
                                        Through the oneFront SDK you can take advantage of <b>out-of-the-box features</b> such as:
                                        <ul>
                                            <li><b>Authentication & Authorization</b><br />the whole flow is completely managed by the specific layer inside oneFront that communicates directly with the TSID</li>
                                            <li><b>Api requests</b><br />simply indicating the endpoint into the Registry (that will redirect the flow through the API gateway), and getting the response (with all the nice-to-have features such as retries, authentication token, etc...)</li>
                                            <li><b>Custom Hooks/Components</b><br />to interact with the ecosystem (get user infos, expose a menu through the Launchpad and so on)</li>
                                        </ul>
                                        Here is an architectural diagram that illustrates how a oneFront App works.
                                    </Typography>

                                    <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={4} style={{ textAlign: "center" }}>
                                            <Tooltip title="Context" placement="bottom">
                                                <a href="/resources/images/architecture/oneFrontApp_context.png" target="_blank">
                                                    <img src="/resources/images/architecture/oneFrontApp_context.png" alt="oneFront App Context" width={110} />
                                                </a>
                                            </Tooltip>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4} style={{ textAlign: "center" }}>
                                            <Tooltip title="Container" placement="bottom">
                                                <a href="/resources/images/architecture/oneFrontApp_container.png" target="_blank">
                                                    <img src="/resources/images/architecture/oneFrontApp_container.png" alt="oneFront App Container" width={180} />
                                                </a>
                                            </Tooltip>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4} style={{ textAlign: "center" }}>
                                            <Tooltip title="Components" placement="bottom">
                                                <a href="/resources/images/architecture/oneFrontApp_components.png" target="_blank">
                                                    <img src="/resources/images/architecture/oneFrontApp_components.png" alt="oneFront App Components" width={300} />
                                                </a>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Content>
            </PageWithHeader>
        </Fragment>
    )
};