export type CSSProperties = {
  [key: string]: string | number;
};

export const arrowStyle: () => {
  arrowIcon: React.CSSProperties;
  arrowColor: React.CSSProperties;
} = () => {
  return {
    arrowIcon: { marginBottom: '-5px', color: '#005075', cursor: 'pointer' },
    arrowColor: { color: '#005075', fontFamily: 'Cairo, sans-serif' },
  };
};

export const chipRender = (severity: string) => {
  let chipColor;
  let wordColor;

  if (severity === 'LOW') {
    chipColor = '#f2cc0c';
  } else if (severity === 'MEDIUM') {
    chipColor = '#ec971f';
  } else if (severity === 'HIGH') {
    chipColor = '#d9534f';
    wordColor = '#FFFFFF';
  }

  return {
    backgroundColor: chipColor,
    color: wordColor,
  };
};
export const linkStyle = {
  box: {
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: 'grey',
    },
  },
};

export const tableMaxWidth = '150px';
