import { Content, PageWithHeader } from '@backstage/core-components';
import { Container, Link, Typography } from "@material-ui/core";
import React, { Fragment } from "react";

export const OneFront = () => {
    return (
        <Fragment>
            <PageWithHeader title="oneFront" subtitle="Key Concepts" themeId="home">
                <Content noPadding>
                    <Container style={{ marginLeft: "auto", marginRight: "auto", paddingTop: 45, paddingBottom: 45, maxWidth: "97%" }}>
                        <Typography>Please refer to: <Link href='https://development.teamsystem.com/docs/default/system/one-front/'>OneFront Documentation</Link></Typography>
                    </Container>
                </Content>
            </PageWithHeader>
        </Fragment>
    )
};