import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  AdoptionMatrix,
  AdoptionMatrixApi,
  AdoptionMatrixRequestOptions,
  AdoptionMatrixSummary,
  AdoptionMatrixUpdate,
  Title
} from './AdoptionMatrixApi';

const getOptionsFilter = (props: AdoptionMatrixRequestOptions): string => {
  const { type, excludeLegacy, areas, subareas, owners, engManagers, superPOs } = props;

  const queryParamType = `?type=${type || 'A'}`;
  const queryParamExcludeLegacy = `${excludeLegacy ? '&excludeLegacy' : ''}`;
  const queryParamAreas = areas && areas.length > 0 ? `&areas=${areas.join(',')}` : '';
  const queryParamSubareas = subareas && subareas.length > 0 ? `&subareas=${subareas.join(',')}` : '';
  const queryParamOwners = owners && owners.length > 0 ? `&owners=${owners.join(',')}` : '';
  const queryParamEngManagers = engManagers && engManagers.length > 0 ? `&engManagers=${engManagers.join(',')}` : '';
  const queryParamSuperPOs = superPOs && superPOs.length > 0 ? `&superPOs=${superPOs.join(',')}` : '';

  return `${queryParamType}${queryParamExcludeLegacy}${queryParamAreas}${queryParamSubareas}${queryParamOwners}${queryParamEngManagers}${queryParamSuperPOs}`;
};

export class AdoptionMatrixClient implements AdoptionMatrixApi {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { fetchApi: FetchApi; discoveryApi: DiscoveryApi }) {
    this.fetchApi = options.fetchApi;
    this.discoveryApi = options.discoveryApi;
  }

  async getBaseUrl(): Promise<string> {
    return this.discoveryApi.getBaseUrl('adoption');
  }

  async getTitles(): Promise<Title[]> {
    const url = `${await this.getBaseUrl()}/titles`;

    const response = await this.fetchApi.fetch(`${url}`, {
      method: 'GET',
    });
    const data = (await response.json()) as Title[];
    
    return data;
  }

  async getAdoptions(props: AdoptionMatrixRequestOptions): Promise<AdoptionMatrix[]> {
    const url = `${await this.getBaseUrl()}`;
    const filter = getOptionsFilter(props);

    const response = await this.fetchApi.fetch(`${url}${filter}`, {
      method: 'GET',
    });
    const data = (await response.json()) as AdoptionMatrix[];
    
    return data;
  }

  async getAdoption(name: string): Promise<AdoptionMatrix> {
    const url = `${await this.getBaseUrl()}/${name}`;

    const response = await this.fetchApi.fetch(`${url}`, {
      method: 'GET',
    });
    const data = (await response.json()) as AdoptionMatrix;
    
    return data;
  }

  async postAdoption(newAdoptionMatrix: AdoptionMatrix): Promise<boolean> {
    const url = `${await this.getBaseUrl()}`;

    const adoptionToSave = {
      'area_name': newAdoptionMatrix.area_name,
      'subarea_name': newAdoptionMatrix.subarea_name,
      'module_name': newAdoptionMatrix.module_name,
      'adoption': [],
    } as AdoptionMatrixUpdate;
    newAdoptionMatrix.adoption.map((item) => {
      adoptionToSave.adoption.push({
        'title_id': item.title_id,
        'status': item.status,
        'deadline': item.deadline,
        'note': item.note,
      });
    });

    await this.fetchApi.fetch(`${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(adoptionToSave),
    });

    return true;
  }

  async getSummary(props: AdoptionMatrixRequestOptions): Promise<AdoptionMatrixSummary[]> {
    const url = `${await this.getBaseUrl()}/summary`;
    const filter = getOptionsFilter(props);

    const response = await this.fetchApi.fetch(`${url}${filter}`, {
      method: 'GET',
    });
    const data = (await response.json()) as AdoptionMatrixSummary[];
    
    return data;
  }
}