import {
  GetSentinelsUsingNamedParametersResponse,
  SentinelList,
} from '../types';
import {
  SentinelsApi,
  GetSentinelsUsingFiltersRequest,
  GetSentinelsUsingNamedParametersRequest,
} from './SentinelsApi';
import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { ServiceUnavailableError } from '@backstage/errors';

export const sentinelsApiRef = createApiRef<SentinelsApi>({
  id: 'plugin.sentinels.service',
});

export class SentinelsClient implements SentinelsApi {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { fetchApi: FetchApi; discoveryApi: DiscoveryApi }) {
    this.fetchApi = options.fetchApi;
    this.discoveryApi = options.discoveryApi;
  }

  private addFilter(
    existingFilter: string | undefined,
    paramName: string,
    paramValue: string,
  ): string {
    if (!existingFilter) {
      return '?'.concat(paramName, '=', paramValue);
    }
    return existingFilter.concat('&', paramName, '=', paramValue);
  }

  async getBaseUrl(): Promise<string> {
    return this.discoveryApi.getBaseUrl('sentinels');
  }

  async getSentinels(number: string | undefined): Promise<SentinelList> {
    var url;
    if (number) {
      url = `${await this.getBaseUrl()}/infoTable/?number=${number}`;
    } else {
      url = `${await this.getBaseUrl()}/infoTable`;
    }

    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 400) {
      throw new ServiceUnavailableError();
    }

    const data = (await response.json()) as SentinelList;

    return data;
  }

  async getUsingNamedParameters(
    req: GetSentinelsUsingNamedParametersRequest,
  ): Promise<GetSentinelsUsingNamedParametersResponse[]> {
    const url = `${await this.getBaseUrl()}/infoTable`;

    let queryParams: string | undefined;
    if (req.severityTypes) {
      queryParams = this.addFilter(
        queryParams,
        'severityTypes',
        req.severityTypes.join(','),
      );
    }
    if (req.publishedDateFrom) {
      queryParams = this.addFilter(
        queryParams,
        'publishedDateFrom',
        req.publishedDateFrom.concat('T00:00:00.000Z'),
      );
    }
    if (req.publishedDateTo) {
      queryParams = this.addFilter(
        queryParams,
        'publishedDateTo',
        req.publishedDateTo.concat('T23:59:59.999Z'),
      );
    }

    if (queryParams) {
      const response = await this.fetchApi.fetch(`${url}${queryParams || ''}`, {
        method: 'GET',
      });

      const data = (await response.json()) as SentinelList[];

      return data;
    }

    return [];
  }

  async getUsingFilters(
    req: GetSentinelsUsingFiltersRequest,
  ): Promise<SentinelList[]> {
    const { filter = [] } = req;
    const params: string[] = [];

    for (const filterItem of [filter].flat()) {
      const filterParts: string[] = [];
      for (const [key, value] of Object.entries(filterItem)) {
        for (const v of [value].flat()) {
          if (typeof v === 'string') {
            filterParts.push(
              `${encodeURIComponent(key)}=${encodeURIComponent(v)}`,
            );
          }
        }
      }

      if (filterParts.length) {
        params.push(`filter=${filterParts.join(',')}`);
      }
    }

    const query = params.length ? `?${params.join('&')}` : '';
    const url = `${await this.getBaseUrl()}/all`;
    const response = await this.fetchApi.fetch(`${url}/${query}`, {
      method: 'GET',
    });

    const data = (await response.json()) as SentinelList[];

    return data;
  }

  async getSentinelsByFilters(
    _req: GetSentinelsUsingFiltersRequest,
  ): Promise<SentinelList> {
    throw new Error('Method not implemented.');
  }
}
