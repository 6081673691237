import React, { Fragment, useEffect } from "react";
import { PageWithHeader, Content } from '@backstage/core-components';

const RedirectComponent = () => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.replace('/docs/default/component/public-cloud-governance');
        }, 500);

        return (() => clearTimeout(timeout));
    }, []);

    return (
        <>Will redirect to appropriate Docs page...</>
    );
};

export const PublicCloudGovernance = () => {
    return (
        <Fragment>
            <PageWithHeader title="Public Cloud Governance" subtitle="Guidelines" themeId="home">
                <Content noPadding>
                    <RedirectComponent />
                </Content>
            </PageWithHeader>
        </Fragment>
    )
};