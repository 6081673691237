import React, { PropsWithChildren } from 'react';
import { makeStyles, styled } from '@material-ui/core';

import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  SidebarSubmenu,
  SidebarSubmenuItem,
  SidebarExpandButton,
  SidebarScrollWrapper,
} from '@backstage/core-components';

import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CategoryIcon from '@material-ui/icons/Category';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import HelpCenterIcon from '@material-ui/icons/Help';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PowerInputIcon from '@material-ui/icons/PowerInput';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ScoreboardIcon from '@material-ui/icons/Score';
import SecurityIcon from '@material-ui/icons/Security';
// import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
// import StorefrontIcon from '@material-ui/icons/Storefront';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 2.4 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});
const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};
const SidebarDividerCustom = styled('hr')(({ theme }) => ({
  height: 1,
  width: '100%',
  background: 'rgb(90, 104, 114)',
  border: 'none',
  margin: theme.spacing(0, 0),
  marginTop: '6px',
  marginBottom: '6px',
}));

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDividerCustom />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        <SidebarItem icon={HomeIcon} to="/" text="Home" />

        <SidebarItem
          icon={PowerInputIcon}
          text="Key Concepts"
          style={{
            width: '94% !important',
            marginLeft: 'auto !important',
            marginRight: 'auto !important',
          }}
        >
          <SidebarSubmenu title="Key Concepts">
            <SidebarSubmenuItem
              title="System Architecture Overview"
              to="keyConcepts/systemArchitecture"
            />
            <SidebarSubmenuItem
              title="Manifesto"
              to="keyConcepts/manifesto"
            />
            <SidebarSubmenuItem
              title="Design Principles"
              to="keyConcepts/designPrinciples"
            />
            <SidebarSubmenuItem
              title="Privacy & Security by Design"
              to="keyConcepts/psByDesign"
            />
            <SidebarSubmenuItem
              title="Vapor Design System"
              to="keyConcepts/vaporDesign"
            />
            <SidebarSubmenuItem title="Biosphere" to="keyConcepts/biosphere" />
            <SidebarSubmenuItem
              title="onePlatform"
              to="keyConcepts/onePlatform"
            />
            <SidebarSubmenuItem title="oneFront" to="keyConcepts/oneFront" />
            <SidebarSubmenuItem title="Glossary" to="keyConcepts/glossary" />
            <SidebarSubmenuItem title="TS Digital" to="keyConcepts/tsDigital" />
            <SidebarSubmenuItem
              title="Accessibility guide"
              to="/accessibility"
            />
          </SidebarSubmenu>
        </SidebarItem>

        <SidebarItem
          icon={AssignmentTurnedInIcon}
          text="Guidelines"
          style={{
            width: '94% !important',
            marginLeft: 'auto !important',
            marginRight: 'auto !important',
          }}
        >
          <SidebarSubmenu title="Guidelines">
            <SidebarSubmenuItem
              title="Public Cloud Governance"
              to="guidelines/publicCloudGovernance"
            />
            <SidebarSubmenuItem
              title="Open Source Licenses"
              to="guidelines/openSourceLicenses"
            />
            <SidebarSubmenuItem
              title="Security"
              to="guidelines/security"
            />
          </SidebarSubmenu>
        </SidebarItem>

        <SidebarItem
          icon={PlayCircleOutlineIcon}
          to="gettingStarted"
          text="Getting started"
        />

        <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog" />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={LiveHelpIcon} to="qeta" text="Q&amp;A" />
        <SidebarItem
          icon={AnnouncementIcon}
          to="announcements"
          text="Announcements"
        />
        <SidebarItem
          icon={HelpCenterIcon}
          onClick={() => {
            window.open(
              'https://teamsystem.atlassian.net/servicedesk',
              '_blank',
              'noreferrer',
            );
          }}
          text="Tech Support"
        />
        <SidebarItem
          icon={SettingsEthernetIcon}
          to="teamsystem-m2mtoken"
          text="M2M Token"
        />
        {/* <SidebarItem icon={ScoreboardIcon} to="scoring" text="Maturity Model" /> */}
        <SidebarItem icon={ScoreboardIcon} to="adoption" text="Adoption" />
        <SidebarItem icon={AssignmentIcon} to="sentinels" text="Sentinels" />
        <SidebarItem icon={SecurityIcon} to="nvd" text="Vulnerabilities" />
        {/* <SidebarItem icon={SpeakerNotesIcon} to="tech-talk" text="Tech-Talk" /> */}

        {/* <SidebarItem icon={StorefrontIcon} to="bazaar" text="Bazaar" /> */}

        <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />

        <SidebarDividerCustom />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDividerCustom />
      <SidebarScrollWrapper>
        {/* Items in this group will be scrollable if they run out of space */}
      </SidebarScrollWrapper>
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
      <SidebarExpandButton key="test" />
    </Sidebar>
    {children}
  </SidebarPage>
);
