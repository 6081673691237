import React from 'react';
import { Container } from '@material-ui/core';
import {
  Content,
  Header,
  InfoCard,
  InfoCardVariants,
  Page,
} from '@backstage/core-components';
import { Route, Routes } from 'react-router-dom';
import { CveSummaryCard } from '../CveSummaryCard';

export const CveSummaryPageContent = (props: { variant?: InfoCardVariants }) => {
  const { variant } = props;
  
  return (
    <Content className="nvdHomePage">
      <Container maxWidth="lg">
        <InfoCard variant={variant}>
          <CveSummaryCard />
        </InfoCard>
      </Container>
    </Content>
  );
}

type Props = {
  title?: string;
  subtitle?: string;
  headerElements?: JSX.Element[];
};

export const CveSummaryPage = (props: Props) => {
  return(
    <Page themeId="tool">
      <Header title={props.title ?? 'NVD'} subtitle={props.subtitle}>
        {props.headerElements}
      </Header>
      <Routes>
        <Route path='/' element={<CveSummaryPageContent />} />
      </Routes>
    </Page>
  );
}