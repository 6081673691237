import { makeStyles } from '@material-ui/core';

//Style for ScoringMainPage component
export const linkStyle = {
  color: '#004E75',
  border: 'none',
  padding: 0,
  fontSize: 'inherit',
  fontWeight: 'bold',
  cursor: 'pointer',
};

//Styles for ScoringSlider component
const valueColor = (value: number | number[] | undefined) => {
  switch (value) {
    case 0:
      return 'grey';
    case 1:
      return '#D92B2C';
    case 2:
      return '#F5B9B5';
    case 3:
      return '#FDB51C';
    case 4:
      return '#097C28';
    default:
      if (value && Number(value) > 0 && Number(value) < 1) {
        return '#D92B2C';
      } else {
        return 'whitesmoke';
      }
  }
};

export const sliderUseStyles = makeStyles(() => ({
  thumb: {
    display: 'none',
  },
  track: {
    height: 15,
    borderRadius: 20,
    backgroundColor: (props: { scoreValue: string }) =>
      valueColor(Number(props.scoreValue)),
  },
  rail: {
    height: 15,
    borderRadius: 20,
    backgroundColor: 'grey',
  },
}));

export const ScoringModalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  '@media (min-width: 960px)': {
    width: '30%',
  },
};

export const ModalDividerStyle = {
  backgroundColor: '#008BCC',
  width: '100%',
  height: 2,
  margin: 0,
};
