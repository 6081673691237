import { Cve } from '@giocolas/backstage-plugin-nvd-common';
import { createContext, useContext } from 'react';
import { CveFilter } from '../types';

export class NoopFilter implements CveFilter {
  getBackendFilters() {
    return { '': null };
  }
}

export type DefaultCveFilters = {
  noop?: NoopFilter;
};

export type CveListContextProps<
  CveFilters extends DefaultCveFilters = DefaultCveFilters,
> = {
  /**
   * The currently registered filters, adhering to the shape of DefaultCveFilters
   */
  filters: CveFilters;

  /**
   * The resolved list of CVEs, after all filters are applied.
   */
  cves: Cve[];

  /**
   * The resolved list of CVEs, after _only cve-backend_ filters are applied.
   */
  backendCves: Cve[];

  /**
   * Update one or more of the registered filters. Optional filters can be set to `undefined` to
   * reset the filter.
   */
  updateFilters: (
    filters:
      | Partial<CveFilters>
      | ((prevFilters: CveFilters) => Partial<CveFilters>),
  ) => void;

  /**
   * Filter values from query parameters.
   */
  queryParameters: Partial<Record<keyof CveFilters, string | string[]>>;

  loading: boolean;
  error?: Error;
};

export const CveListContext = createContext<
  CveListContextProps<any> | undefined
>(undefined);

export function useCveList<
  NvdFilters extends DefaultCveFilters = DefaultCveFilters,
>(): CveListContextProps<NvdFilters> {
  const context = useContext(CveListContext);
  if (!context)
    throw new Error('useCveList must be used within CveListProvider');
  return context;
}