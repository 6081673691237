/**
 * Possible severity type
 */
export type SeverityTypeValues = 'NONE' | 'LOW' | 'MEDIUM' | 'HIGH' | 'CRITICAL'
export const SeverityTypeStringArray = [ 'NONE', 'LOW', 'MEDIUM', 'HIGH', 'CRITICAL'];

/**
 * Interface for manage CVE data
 */
export interface Cve {
  id: string,
  sourceIdentifier?: string,
  published: Date,
  lastModified: Date,
  vulnStatus?: string,
  description: string,
  score?: number,
  severity?: string,
}