import React, { Fragment } from "react";
import { BackstageTheme } from "@backstage/theme";
import { makeStyles } from '@material-ui/core/styles';
import { PageWithHeader, Content } from '@backstage/core-components';
import { useTheme, Grid, Typography, Container, Button, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles<BackstageTheme, { fontColor: string; backgroundImage: string }>(() => ({
    vaporHeroContainer: {
        height: "400px",
        padding: 28,
        backgroundImage: "url('/resources/images/vaporHero.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
    }
}));

export const VaporDesign = () => {
    const backstageTheme = useTheme<BackstageTheme>();
    const themeId = "documentation";
    const theme = backstageTheme.getPageTheme({ themeId });
    const classes = useStyles({
        fontColor: theme.fontColor,
        backgroundImage: theme.backgroundImage,
    });

    return (
        <Fragment>
            <PageWithHeader title="Vapor Design System" subtitle="Key Concepts" themeId="home">
                <Content noPadding>
                    <Container style={{ marginLeft: "auto", marginRight: "auto", paddingTop: 45, paddingBottom: 45, maxWidth: "97%" }}>
                        <Grid container spacing={0} justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={2}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={2}>
                                                <ListIcon />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="h5">Table of Contents</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="row">
                                            <Button href="#1" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>1. Intro</Button>
                                            <Button href="#2" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>2. Guiding principles</Button>
                                            <Button href="#3" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>3. Who is Vapor for?</Button>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={2}>
                                                <img src="/resources/images/gitlabLogo.png" alt="Biosphere" width="20px" />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="h5">Biosphere</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Button href="https://biosphere.teamsystem.com/vapor/angular" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>Vapor Angular components</Button>
                                            <Button href="https://biosphere.teamsystem.com/vapor/react" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>Vapor React components</Button>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                                        <Grid container direction="row">
                                            <Grid item xs={2}>
                                                <img src="/resources/images/msteamsLogo.png" alt="Biosphere" width="20px" />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="h5">Channels</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="row">
                                            <Button href="https://teams.microsoft.com/l/channel/19%3a3972a8b854fe41a488164db92968d9a9%40thread.tacv2/Generale?groupId=2c2ad480-7a8a-46b2-b5a1-92ce6b2b8945&tenantId=bb364f09-07cf-4eca-9b92-1f26a92d5f3f" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>General</Button>
                                            <Button href="https://teams.microsoft.com/l/channel/19%3a816fd7aa26db4e43a7c41b2a1048ffe1%40thread.tacv2/Angular?groupId=2c2ad480-7a8a-46b2-b5a1-92ce6b2b8945&tenantId=bb364f09-07cf-4eca-9b92-1f26a92d5f3f" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>Angular</Button>
                                            <Button href="https://teams.microsoft.com/l/channel/19%3ac911b6f9a56d436da7bddb9ef8865559%40thread.tacv2/Angular%2520Forum?groupId=2c2ad480-7a8a-46b2-b5a1-92ce6b2b8945&tenantId=bb364f09-07cf-4eca-9b92-1f26a92d5f3f" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>Angular Forum</Button>
                                            <Button href="https://teams.microsoft.com/l/channel/19%3a2183b80e6b694148a4f981a1a39c79c3%40thread.tacv2/Angular%2520Release%2520%255B%2520bot%2520%255D?groupId=2c2ad480-7a8a-46b2-b5a1-92ce6b2b8945&tenantId=bb364f09-07cf-4eca-9b92-1f26a92d5f3f" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>Angular Release (bot)</Button>
                                            <Button href="https://teams.microsoft.com/l/channel/19%3a3a7af2244c314464882b286e0d57400e%40thread.tacv2/React?groupId=2c2ad480-7a8a-46b2-b5a1-92ce6b2b8945&tenantId=bb364f09-07cf-4eca-9b92-1f26a92d5f3f" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>React</Button>
                                            <Button href="https://teams.microsoft.com/l/channel/19%3a1ff96a6818394fdeb50c83bbaea6af5a%40thread.tacv2/React%2520Forum?groupId=2c2ad480-7a8a-46b2-b5a1-92ce6b2b8945&tenantId=bb364f09-07cf-4eca-9b92-1f26a92d5f3f" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>React Forum</Button>
                                            <Button href="https://teams.microsoft.com/l/channel/19%3a83c2b7c354d945539066db8affc132ba%40thread.tacv2/Vapor%2520Design%2520Forum?groupId=2c2ad480-7a8a-46b2-b5a1-92ce6b2b8945&tenantId=bb364f09-07cf-4eca-9b92-1f26a92d5f3f" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>Vapor Design Forum</Button>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                                        <Grid container direction="row">
                                            <Grid item xs={12}>
                                                <Typography variant="h5">Other</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="row">
                                            <Button href="https://docs.google.com/spreadsheets/d/1mzEr27IRLeqXudF_PDustbntsolgk4U8EkOgCi1NPWM/edit?usp=sharing" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>React/Angular Progress Sheet</Button>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            
                            <Grid item xs={9} style={{ marginLeft: 50 }}>
                                <div className={classes.vaporHeroContainer}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" style={{ color: "#FFFFFF", width: "60%", marginBottom: 20 }}>
                                                Vapor is TeamSystem’s design system for digital products and experiences. The system consists of user interface guidelines, working code, design tools and resources.
                                            </Typography>
                                            <Button variant="contained" color="primary" onClick={() => { window.open("https://vapor.teamsystem.com/", "_blank", "noreferrer") }}>
                                                Visit the Portal
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div id="1" style={{marginTop: 30}}>
                                    <Typography variant="h3">1. Intro</Typography>
                                    <Typography variant="body1">
                                    Vapor is a substance in the gas phase at a temperature lower than its critical temperature, which means that vapor can be condensed to a liquid by increasing the pressure on it without reducing the temperature
                                    </Typography>
                                </div>

                                <div id="2" style={{marginTop: 30}}>
                                    <Typography variant="h3">2. Guiding principles</Typography>
                                    <Typography variant="body1">
                                        <b>Vapor is inclusive</b><br />
                                        Vapor is designed to be accessible to everyone, regardless of ability or situation. Our components are committed to <a href="https://www.w3.org/TR/WCAG20/" target="_blank" style={{textDecoration: "underline"}}>WCAG 2.0</a> guidelines (AA).
                                    </Typography>
                                    <Typography variant="body1" style={{marginTop: 10}}>
                                        <b>Vapor is modular and flexible</b><br />
                                        Vapor components are built modularly to guarantee top notch flexibility in execution. Its components work impeccably alone or combined with each other, in any situation that suits the needs of a user.
                                    </Typography>
                                    <Typography variant="body1" style={{marginTop: 10}}>
                                        <b>Vapor is user-centered</b><br />
                                        Vapor components are designed focusing on the users' needs, habits and desires and are continuously revised upstream interviews and research on real people.
                                    </Typography>
                                    <Typography variant="body1" style={{marginTop: 10}}>
                                        <b>Vapor is consistent</b><br />
                                        Based on TeamSystem Design Language, every Vapor element and component is designed to fit within complex environments and guarantee a coherent user experience.
                                    </Typography>
                                </div>

                                <div id="3" style={{marginTop: 30}}>
                                    <Typography variant="h3">3. Who is Vapor for?</Typography>
                                    <Typography variant="body1">
                                        <b>For Designers</b><br />
                                        Vapor represents a cohesive and consistent language for product and web designers with an extensive and continuosly growing set of design assets with guidances. It contains a complete set of user-centered interface guidelines, components and documentation, helping designers to speed up their work in a smart way.
                                    </Typography>
                                    <Typography variant="body1" style={{marginTop: 10}}>
                                        <b>For Developers</b><br />
                                        Vapor is designed for developers and its libraries are regularly updated and maintained by the Vapor Team on our <a href="https://biosphere.teamsystem.com/vapor" target="_blank" style={{textDecoration: "underline"}}>Biosphere platform</a>.
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Content>
            </PageWithHeader>
        </Fragment>
    )
};