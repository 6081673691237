import {
  CatalogResponse,
  SoftwareAttributes,
  SoftwareScoring,
  SoftwareNames,
  ScoringData,
  AttributeTitles,
  TitleSummary,
  ScoreSummaryResponse,
} from '../types';
import { ScoringApi } from './ScoringApi';
import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';

export const scoringApiRef = createApiRef<ScoringApi>({
  id: 'plugin.scoring.service',
});

export class ScoringClient implements ScoringApi {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { fetchApi: FetchApi; discoveryApi: DiscoveryApi }) {
    this.fetchApi = options.fetchApi;
    this.discoveryApi = options.discoveryApi;
  }

  async getBaseUrl(): Promise<string> {
    return this.discoveryApi.getBaseUrl('scoring');
  }

  async getCatalog(): Promise<CatalogResponse> {
    const url = `${await this.getBaseUrl()}/softwarecatalog`;
    const response = await this.fetchApi.fetch(url);

    if (response.status === 403) {
      throw new Error(response.statusText);
    }

    const data = (await response.json()) as CatalogResponse;

    return data;
  }

  async getAttributes(): Promise<SoftwareAttributes> {
    const url = `${await this.getBaseUrl()}/softwareattributes`;
    const response = await this.fetchApi.fetch(url);
    if (response.status === 403) {
      throw new Error(response.statusText);
    }
    const data = (await response.json()) as SoftwareAttributes;
    return data;
  }

  async getScoringSummaries(): Promise<ScoreSummaryResponse> {
    const url = `${await this.getBaseUrl()}/scoringsummaries`;
    const response = await this.fetchApi.fetch(url);
    if (response.status === 403) {
      throw new Error(response.statusText);
    }
    const data = (await response.json()) as ScoreSummaryResponse;
    return data;
  }

  async getScoring(): Promise<ScoringData> {
    const url = `${await this.getBaseUrl()}/getscoring`;
    const response = await this.fetchApi.fetch(url);
    if (response.status === 403) {
      throw new Error(response.statusText);
    }
    const data = (await response.json()) as ScoringData;
    return data;
  }

  async getAttributeTitles(): Promise<AttributeTitles> {
    const url = `${await this.getBaseUrl()}/attributetitles`;
    const response = await this.fetchApi.fetch(url);
    if (response.status === 403) {
      throw new Error(response.statusText);
    }
    const titleData = (await response.json()) as AttributeTitles;
    return titleData;
  }

  async getTitleSummaryScore(): Promise<TitleSummary> {
    const url = `${await this.getBaseUrl()}/softwareTitleSummary`;
    const response = await this.fetchApi.fetch(url);
    if (response.status === 403) {
      throw new Error(response.statusText);
    }
    const summary = (await response.json()) as TitleSummary;
    return summary;
  }

  async getNewSoftwares(): Promise<SoftwareNames> {
    const url = `${await this.getBaseUrl()}/softwares`;
    const response = await this.fetchApi.fetch(url);
    if (response.status === 403) {
      throw new Error(response.statusText);
    }
    const newSoftwares = (await response.json()) as SoftwareNames;
    return newSoftwares;
  }

  async addScorePoints(data: SoftwareScoring): Promise<ScoringData> {
    try {
      if (!data.softId || !data.titleId || !data.attributeId) {
        throw new Error('Missing required data for scoring.');
      }
      const url = `${await this.getBaseUrl()}/addscore/${data.softId}/${
        data.titleId
      }/${data.attributeId}`;
      const response = await this.fetchApi.fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.status === 400) {
        throw new Error(response.statusText);
      }
      const dataResult = (await response.json()) as ScoringData;

      return dataResult;
    } catch (error) {
      throw error;
    }
  }
}
