import {
  M2mTokenFieldResults,
  M2mTokenPost,
  TickedData,
} from '../types';
import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { AuthenticationError } from '@backstage/errors';
import { M2mtokenApi } from './M2mTokenApi';

export const m2mTokenApiRef = createApiRef<M2mtokenApi>({
  id: 'plugin.m2mtoken.service',
});

export class M2mtokenClient implements M2mtokenApi {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { fetchApi: FetchApi; discoveryApi: DiscoveryApi }) {
    this.fetchApi = options.fetchApi;
    this.discoveryApi = options.discoveryApi;
  }

  async getBaseUrl(): Promise<string> {
    return this.discoveryApi.getBaseUrl('m2mtoken');
  }

  async getFields(): Promise<M2mTokenFieldResults> {
    const url = `${await this.getBaseUrl()}/fields`;

    const response = await this.fetchApi.fetch(url);

    if (response.status === 403) {
      throw new AuthenticationError();
    }

    const data = (await response.json()) as M2mTokenFieldResults;

    return data;
  }

  async getToken(data: TickedData): Promise<M2mTokenPost> {
    const url = `${await this.getBaseUrl()}/token`;

    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.status === 400) {
      throw new AuthenticationError();
    }
    const dataResult = (await response.json()) as M2mTokenPost;
    return dataResult;
  }
}