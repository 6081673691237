import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { badgesApiRef, BadgesClient } from './api';

export const badgesAccrediblePlugin = createPlugin({
  id: 'badges',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: badgesApiRef,
      deps: { fetchApi: fetchApiRef, discoveryApi: discoveryApiRef },
      factory: ({ fetchApi, discoveryApi }) =>
        new BadgesClient({ fetchApi, discoveryApi }),
    }),
  ]
});

export const EntityUserBadgesCard = badgesAccrediblePlugin.provide(
  createRoutableExtension({
    name: 'BadgesAccredibleCard',
    component: () =>
      import('./components/EntityUserBadgesCard').then(m => m.BadgesCard),
    mountPoint: rootRouteRef,
  }),
);