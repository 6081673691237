import {
    AnalyticsApi,
    AnalyticsEvent,
    FetchApi,
    DiscoveryApi,
    IdentityApi,
} from '@backstage/core-plugin-api';
import { DeferredCapture } from '../../util/DeferredCapture';
import { GainSightClient } from '../../service/GainSightClient';

/**
 * GainSight Analytics API provider for the Backstage Analytics API.
 * @public
 */
export class GainSightAnalytics implements AnalyticsApi {

  private readonly client: GainSightClient;
  private readonly capture: DeferredCapture;

  private constructor(options: {
    fetchApi: FetchApi;
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    const { fetchApi, discoveryApi, identityApi } = options;

    this.client = new GainSightClient({ fetchApi, discoveryApi });
    this.client.postAccount();
    this.capture = new DeferredCapture({ defer: true, client: this.client });
    this.setUser(identityApi).then(() => {
      return;
    });
  }

  static fromConfig(options: { fetchApi: FetchApi, discoveryApi: DiscoveryApi, identityApi: IdentityApi }) {
    return new GainSightAnalytics({ ...options });
  }

  captureEvent(event: AnalyticsEvent) {
    this.capture.process(event);
  }

  private async setUser(identityApi: IdentityApi) {
    const { userEntityRef } = await identityApi.getBackstageIdentity();
    const { displayName, email } = await identityApi.getProfileInfo();

    this.capture.setReady(userEntityRef, displayName || '', email || '');
  }
}