import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {ScoringApi, scoringApiRef} from '../api';
import { useAsync } from 'react-use';

export function useScoringApi<T>(
  f: (api: ScoringApi) => Promise<T>,
  deps: any[] = [],
) {
  const scoringApi = useApi(scoringApiRef);
  return useAsync(async () => {
    return await f(scoringApi);
  }, deps);
}

export const useBaseUrl = () => {
  try {
    const config = useApi(configApiRef);
    return config.getOptionalString('app.baseUrl');
  } catch {
    return undefined;
  }
};