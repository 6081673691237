import React from 'react';
import { Table } from '@backstage/core-components';
import { ScoringCatalogSoftware, ScoringSummaries } from './ScoringApiClient';
import { Link } from 'react-router-dom';
import { ScoringSlider } from '../microcomponents/ScoringSlider';
import Stack from '@mui/material/Stack';
import { Typography } from '@material-ui/core';
import { ScoringLegend } from './ScoringLegend';
import { linkStyle } from '../styles/ScoringStyles';

interface TableColumn {
  title: string;
  field: string;
  sorting?: boolean;
  render?: (rowData: {
    id: string;
    softwareTitle: string;
    as_is_summary: number;
    to_be_summary: number;
  }) => JSX.Element;
  width?: string;
  headerStyle?: React.CSSProperties;
}

export const ScoringMainPage = () => {
  const { catalogValue, catalogLoading } = ScoringCatalogSoftware();
  const { sumValue, sumLoading } = ScoringSummaries();

  if (catalogLoading || sumLoading) return <div>Loading...</div>;

  const columns: TableColumn[] = [
    {
      title: 'Software',
      field: 'softwareName',
      sorting: false,
      render: rowData => (
        <Link
          to={`softwaredetails/${rowData.id}`}
          style={linkStyle}
          state={rowData}
        >
          {rowData.softwareTitle}
        </Link>
      ),
      width: '50%',
    },
    {
      title: 'AS IS',
      field: 'as_is_summary',
      render: rowData => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            width="100%"
          >
            <Typography
              style={{
                width: '20%',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {rowData.as_is_summary?.toFixed(2)}
            </Typography>
            <ScoringSlider
              scoreValue={
                rowData.as_is_summary > 0 && rowData.as_is_summary < 1
                  ? rowData.as_is_summary
                  : Math.floor(rowData.as_is_summary)
              }
              minValue={0}
              maxValue={4}
              isDisabled={true}
              sliderStyle={{
                width: '80%',
                display: 'flex',
                alignItems: 'center',
              }}
            />
          </Stack>
        );
      },
      width: '25%',
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'TO BE',
      field: 'to_be_summary',
      render: rowData => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            width="100%"
          >
            <Typography
              style={{
                width: '20%',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {rowData.to_be_summary?.toFixed(2)}
            </Typography>
            <ScoringSlider
              scoreValue={
                rowData.to_be_summary > 0 && rowData.to_be_summary < 1
                  ? rowData.to_be_summary
                  : Math.floor(rowData.to_be_summary)
              }
              minValue={0}
              maxValue={4}
              isDisabled={true}
              sliderStyle={{
                width: '80%',
                display: 'flex',
                alignItems: 'center',
              }}
            />
          </Stack>
        );
      },
      width: '25%',
      headerStyle: {
        textAlign: 'center',
      },
    },
  ];

  const data =
    catalogValue?.data.map(
      (soft: { metadata: { uid: string; title: string } }) => {
        const softAvg = sumValue?.data?.find(
          (avg: { software_id: string }) =>
            avg.software_id === soft.metadata.uid,
        );
        const asIsScore = softAvg && softAvg.as_is_score_summary;
        const toBeScore = softAvg && softAvg.to_be_score_summary;
        const softwareData = {
          id: soft.metadata.uid,
          softwareTitle: soft.metadata.title,
          // as_is_summary: asIsScore ? Number(asIsScore) : 0,
          // to_be_summary: toBeScore ? Number(toBeScore) : 0,
          as_is_summary: asIsScore ? Number(asIsScore) : 0,
          to_be_summary: toBeScore ? Number(toBeScore) : 0,
        };
        return softwareData;
      },
    ) ?? [];
  return (
    <>
      <ScoringLegend isMain={true} />
      <Table
        columns={columns}
        data={data}
        options={{
          draggable: false,
          filtering: false,
          search: false,
          paging: false,
          thirdSortClick: false,
          headerStyle: { borderTop: 'none' },
        }}
      />
    </>
  );
};
