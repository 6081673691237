import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { InfoCard, Header, Page, Content } from '@backstage/core-components';
import { FetchTable } from '../fetchTable/FetchTable';

import { useParams, useLocation } from 'react-router-dom';

export const Sentinels = () => {
  const location = useLocation();
  const data = location.state;

  const { id: number } = useParams<{ id?: string }>();

  return (
    <>
      <Page themeId="tool">
        <Header title="Sentinels" />
        <Content>
          <Grid container spacing={4} direction="column">
            <Grid item xs={12}>
              <InfoCard
                title={
                  number ? `Sentinel Number:${data?.number}  ` : 'Sentinels'
                }
                subheader={number ? `Subject:${data?.subject}` : ''}
              >
                <Typography component="div" variant="body1">
                  <Grid item>
                    <FetchTable />
                  </Grid>
                </Typography>
              </InfoCard>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </>
  );
};
