import React from 'react';

import logoTs from "./logo/logo-ts.png";

const LogoFull = () => {

  return (
    <img src={logoTs} alt="logo ts" style={{width: 155}} />
  );
};

export default LogoFull;
