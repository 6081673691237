import React, { Fragment } from "react";
import { PageWithHeader, Content } from '@backstage/core-components';
import { Grid, Typography, Divider, Container, Button, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const OnePlatform = () => {
    return (
        <Fragment>
            <PageWithHeader title="onePlatform" subtitle="Key Concepts" themeId="home">
                <Content noPadding>
                    <Container style={{ marginLeft: "auto", marginRight: "auto", paddingTop: 45, paddingBottom: 45, maxWidth: "97%" }}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={2}>
                                                <ListIcon />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="h5">Table of Contents</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="row">
                                            <Button href="#1" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>1. General overview</Button>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={2}>
                                                <img src="/resources/images/gitlabLogo.png" alt="Biosphere" width="20px" />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="h5">Biosphere</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Button href="https://biosphere.teamsystem.com/oneplatform" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>onePlatform</Button>
                                            <Button href="https://biosphere.teamsystem.com/oneplatform/onefront" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>oneFront</Button>
                                            <Button href="https://biosphere.teamsystem.com/oneplatform/islands" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>Islands</Button>
                                            <Button href="https://biosphere.teamsystem.com/oneplatform/js-report" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>JS Report</Button>
                                            <Button href="https://biosphere.teamsystem.com/oneplatform/standard-codes" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>Standard Codes</Button>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                                        <Grid container direction="row">
                                            <Grid item xs={2}>
                                                <img src="/resources/images/msteamsLogo.png" alt="Biosphere" width="20px" />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="h5">Channels</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="row">
                                            <Button href="https://teams.microsoft.com/l/channel/19%3afDYjWPocFZD0HKQVCixBv0Cs7w9iBJ089G_P5Te3Y8g1%40thread.tacv2/Generale?groupId=4d968cb3-20ab-4ccb-9651-a7febbbc4136&tenantId=bb364f09-07cf-4eca-9b92-1f26a92d5f3f" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>General</Button>
                                            <Button href="https://teams.microsoft.com/l/channel/19%3a777a55c5eb7f4ac0b9fd5d73f5ede1c2%40thread.tacv2/JSR%2520(JSReport)?groupId=4d968cb3-20ab-4ccb-9651-a7febbbc4136&tenantId=bb364f09-07cf-4eca-9b92-1f26a92d5f3f" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>JS Report</Button>
                                            <Button href="https://teams.microsoft.com/l/channel/19%3a0904db1660934c1388164331cda842b6%40thread.tacv2/STC%2520(Standard%2520Codes)?groupId=4d968cb3-20ab-4ccb-9651-a7febbbc4136&tenantId=bb364f09-07cf-4eca-9b92-1f26a92d5f3f" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>STC (Standard Codes)</Button>
                                            <Button href="https://teams.microsoft.com/l/channel/19%3a545a6a69b46a41b6bd9eebf40bcdfcb3%40thread.tacv2/PDS%2520(Portale%2520dello%2520sviluppatore)?groupId=4d968cb3-20ab-4ccb-9651-a7febbbc4136&tenantId=bb364f09-07cf-4eca-9b92-1f26a92d5f3f" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>Developer Portal</Button>
                                            <Button href="https://teams.microsoft.com/l/channel/19%3acfcdc08f386544b8a4db9f167336d7e8%40thread.tacv2/SRE%2520(Site%2520reliability)?groupId=4d968cb3-20ab-4ccb-9651-a7febbbc4136&tenantId=bb364f09-07cf-4eca-9b92-1f26a92d5f3f" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>SRE (Site reliability)</Button>
                                            <Button href="https://teams.microsoft.com/l/channel/19%3ad320d2235bb24ac2b5756ecfb592a7a0%40thread.tacv2/Review?groupId=4d968cb3-20ab-4ccb-9651-a7febbbc4136&tenantId=bb364f09-07cf-4eca-9b92-1f26a92d5f3f" target="_blank" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>Review</Button>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={9} style={{ marginLeft: 50 }}>
                                <div id="1">
                                    <Typography variant="h3">1. General overview</Typography>
                                    <Typography variant='body1' gutterBottom>
                                        <b>"TS One Platform"</b> <em>(in seguito 1P)</em> <b>è una piattaforma di servizi cloud computing</b> a disposizione in modalità <b>PaaS (Platform as a Service)</b>.<br />
                                        Il modello PaaS fornisce un <b>framework</b> su cui gli sviluppatori si possono basare <b>per sviluppare o costruire applicazioni basate sul cloud</b>.<br />
                                        In modo analogo a come viene creata una funzione di Excel, una soluzione PaaS consente agli sviluppatori di creare applicazioni usando componenti software integrati.<br />
                                        Le funzionalità cloud come <b>scalabilità</b>, <b>disponibilità elevata</b> e capacità <b>multi-tenant</b> sono incluse, per ridurre la quantità di codice che gli sviluppatori devono scrivere.
                                    </Typography>

                                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                                        <Grid item xs={3}><img src="/resources/images/dockerLogo.png" alt="Docker" width={200} /></Grid>
                                        <Grid item xs={3}><img src="/resources/images/reactLogo.png" alt="React" width={70} /></Grid>
                                        <Grid item xs={3}><img src="/resources/images/nodejsLogo.png" alt="NodeJS" width={200} /></Grid>
                                        <Grid item xs={3}><img src="/resources/images/forrestjsLogo.png" alt="ForrestJS" width={70} /></Grid>
                                    </Grid>

                                    <Typography variant="body1">
                                        The framework also provides some key functionalities that every modern Web App should have, like <b>registry service</b> and, through the <b>TSId login service</b>, also <b>authentication</b> and <b>authorization</b>.
                                        The following is a picture representing the OneFront overview:
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Content>
            </PageWithHeader>
        </Fragment>
    )
};