import { AnalyticsEvent } from '@backstage/core-plugin-api';
import { GainSightClient } from '../service/GainSightClient';

export class DeferredCapture {

  private userId: string = '';

  private queue: AnalyticsEvent[] | undefined;
  private client: GainSightClient;

  constructor({ defer = true, client }: { defer: boolean, client: GainSightClient }) {
    this.queue = defer ? [] : undefined;
    this.client = client;
  }

  setReady(userId: string, profileName: string, email: string) {
    this.userId = userId;
    this.client.postUser(userId, profileName, email);
    if (this.queue) {
      this.queue.forEach((event) => { this.sendDeferred(event) })
      this.queue = undefined;
    }
  }

  process(data: AnalyticsEvent) {
    if (this.queue) {
      this.queue.push(data);
      return;
    }
    this.client.postEvent(this.userId, data);
  }

  private sendDeferred(data: AnalyticsEvent) {
    this.client.postEvent(this.userId, data);
  }
}