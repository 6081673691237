import { createApiFactory, createPlugin, createRoutableExtension, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import { nvdApiRef, NvdClient } from './api';
import { nvdRouteRef } from './routes';

export const nvdPlugin = createPlugin({
  id: 'nvd',
  routes: {
    root: nvdRouteRef,
  },
  apis: [
    createApiFactory({
      api: nvdApiRef,
      deps: { fetchApi: fetchApiRef, discoveryApi: discoveryApiRef },
      factory: ({ fetchApi, discoveryApi }) =>
        new NvdClient({ fetchApi, discoveryApi }),
    }),
  ]
});

export const CveIndexPage = nvdPlugin.provide(
  createRoutableExtension({
    name: 'NvdIndexPage',
    component: () => import('./components/CveIndexPage').then(m => m.CveIndexPage),
    mountPoint: nvdRouteRef,
  }),
);

export const CveSummaryPage = nvdPlugin.provide(
  createRoutableExtension({
    name: 'NvdSummaryPage',
    component: () => import('./components/CveSummaryPage').then(m => m.CveSummaryPage),
    mountPoint: nvdRouteRef,
  }),
);