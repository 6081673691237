import { useApi } from '@backstage/core-plugin-api';
import { Cve } from '@giocolas/backstage-plugin-nvd-common';
import { compact, isEqual } from 'lodash';
import qs from 'qs';
import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useDebounce from 'react-use/lib/useDebounce';
import useMountedState from 'react-use/lib/useMountedState';

import { nvdApiRef } from '../api';
import { CveFilter } from '../types';
import {
  DefaultCveFilters,
  NoopFilter,
  CveListContext,
} from './useCveList';

const reduceBackendFilters = (
  filters: CveFilter[],
): Record<string, string | string[] | null> => {
  return filters.reduce((compoundFilter, filter) => {
    return {
      ...compoundFilter,
      ...(filter.getBackendFilters ? filter.getBackendFilters() : {}),
    };
  }, {} as Record<string, string | string[] | null>);
};

type OutputState<CveFilters extends DefaultCveFilters> = {
  appliedFilters: CveFilters;
  cves: Cve[];
  backendCves: Cve[];
};

export const CveListProvider = <
  CveFilters extends DefaultCveFilters,
>({
  children,
}: PropsWithChildren<{}>) => {

  const isMounted = useMountedState();
  const nvdApi = useApi(nvdApiRef);
  const [requestedFilters, setRequestedFilters] = useState<CveFilters>(
    {} as CveFilters,
  );

  const location = useLocation();
  const queryParameters = useMemo(
    () =>
      (qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }).filters ?? {}) as Record<string, string | string[]>,
    [location],
  );

  const [outputState, setOutputState] = useState<OutputState<CveFilters>>({
    appliedFilters: {
      noop: new NoopFilter(), // Init with a noop filter to trigger initial request
    } as CveFilters,
    cves: [],
    backendCves: [],
  });

  const [{ loading, error }, refresh] = useAsyncFn(
    async () => {
      const compacted: CveFilter[] = compact(
        Object.values(requestedFilters),
      );
      const cveFilter = (c: Cve) =>
        compacted.every(
          filter => !filter.filterCve || filter.filterCve(c),
        );
      const backendFilter = reduceBackendFilters(compacted);
      const previousBackendFilter = reduceBackendFilters(
        compact(Object.values(outputState.appliedFilters)),
      );

      const queryParams = Object.keys(requestedFilters).reduce(
        (params, key) => {
          const filter = requestedFilters[key as keyof CveFilters] as
            | CveFilter
            | undefined;
          if (filter?.toQueryValue) {
            params[key] = filter.toQueryValue();
          }
          return params;
        },
        {} as Record<string, string | string[]>,
      );

      if (!isEqual(previousBackendFilter, backendFilter)) {
        const response = await nvdApi.getUsingFilters({
          filter: backendFilter,
        });
        setOutputState({
          appliedFilters: requestedFilters,
          backendCves: response,
          cves: response.filter(cveFilter),
        });
      } else {
        setOutputState({
          appliedFilters: requestedFilters,
          backendCves: outputState.backendCves,
          cves: outputState.backendCves
            .filter(cveFilter),
        });
      }

      if (isMounted()) {
        const oldParams = qs.parse(location.search, {
          ignoreQueryPrefix: true,
        });
        const newParams = qs.stringify(
          { ...oldParams, filters: queryParams },
          { addQueryPrefix: true, arrayFormat: 'repeat' },
        );
        const newUrl = `${window.location.pathname}${newParams}`;
        window.history?.replaceState(null, document.title, newUrl);
      }
    },
    [
      nvdApi,
      queryParameters,
      requestedFilters,
      outputState,
    ],
    { loading: true },
  );

  useDebounce(refresh, 10, [requestedFilters]);

  const updateFilters = useCallback(
    (
      update:
        | Partial<CveFilters>
        | ((prevFilters: CveFilters) => Partial<CveFilters>),
    ) => {
      setRequestedFilters(prevFilters => {
        const newFilters =
          typeof update === 'function' ? update(prevFilters) : update;
        return { ...prevFilters, ...newFilters };
      });
    },
    [],
  );

  const value = useMemo(
    () => ({
      filters: outputState.appliedFilters,
      cves: outputState.cves,
      backendCves: outputState.backendCves,
      updateFilters,
      queryParameters,
      loading,
      error,
    }),
    [outputState, updateFilters, queryParameters, loading, error],
  );

  return (
    <CveListContext.Provider value={value}>
      {children}
    </CveListContext.Provider>
  );

}