import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  analyticsApiRef,
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { GainSightAnalytics } from '@backstage/plugin-analytics-module-gainsight';
import { catalogApiRef, entityPresentationApiRef } from '@backstage/plugin-catalog-react';
import { DefaultEntityPresentationApi } from '@backstage/plugin-catalog';
import BusinessIcon from '@material-ui/icons/Business';
import CategoryIcon from '@material-ui/icons/Category';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: analyticsApiRef,
    deps: { fetchApi: fetchApiRef, discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ fetchApi, discoveryApi, identityApi }) =>
      GainSightAnalytics.fromConfig( { fetchApi, discoveryApi, identityApi } ),
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: entityPresentationApiRef,
    deps: { catalogApi: catalogApiRef, },
    factory: ({ catalogApi }) => {
      return DefaultEntityPresentationApi.create({
        catalogApi: catalogApi,
        kindIcons: {
          area: BusinessIcon,
          module: CategoryIcon,
        },
      });
    },
  }),
];