import React, { useEffect, useState } from 'react';
import {
  Table,
  TableColumn,
  Progress,
  ResponseErrorPanel,
  Content,
} from '@backstage/core-components';
import useAsync from 'react-use/lib/useAsync';
import {
  SentinelsGetAll,
  SentinelsFilterQuery,
} from '../sentinels/SentinelsApiClient';
import { useParams } from 'react-router-dom';
import { Chip, Box, Tooltip } from '@material-ui/core';
import { CatalogFilterLayout } from '@backstage/plugin-catalog-react';
import { useNavigate } from 'react-router-dom';
import { SentinelResponse, Sentinel, ChipRender } from '../../types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { SeverityTypePicker } from '../SeverityTypePicker';
import { sentinelsApiRef } from '../../api/SentinelsClient';
import { useApi } from '@backstage/core-plugin-api';
import { chipRender, tableMaxWidth, arrowStyle, linkStyle } from './Styles';
import { makeStyles } from '@material-ui/core/styles';

export const SentinelsData = () => {
  const { id: numberId } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  const sentinelsApi = useApi(sentinelsApiRef);
  const [sentinels, setSetinels] = useState<SentinelResponse[] | undefined>([]);
  const [severityTypes, setSeverityTypes] = useState<string[]>([]);
  const [querySentinels, setQueryTypes] = useState<
    SentinelResponse[] | undefined
  >([]);

  const { sentinelsValue, sentinelsValueLoading, sentinelsValueError } =
    SentinelsGetAll(numberId);

  const { sentinelsQueryValue } = SentinelsFilterQuery(
    severityTypes,
    sentinelsApi,
  );

  const useStyles = makeStyles(linkStyle);
  const classes = useStyles();

  useEffect(() => {
    const callSentinelsValue = () => {
      setQueryTypes(sentinelsQueryValue?.data);
      setSetinels(sentinelsValue?.data);
      if (sentinelsValueLoading) {
        return <Progress />;
      } else if (sentinelsValueError) {
        return <ResponseErrorPanel error={sentinelsValueError} />;
      }
      return undefined;
    };

    callSentinelsValue();
  }, [
    sentinelsValue,
    sentinelsValueLoading,
    sentinelsValueError,
    sentinelsQueryValue,
  ]);

  const columns: TableColumn<ChipRender>[] = [
    {
      title: 'Number',
      field: 'number',
      width: tableMaxWidth,
      render: row => {
        return (
          <Tooltip placement="top" title="Sentinel Info">
            <Box
              className={classes.box}
              onClick={() => {
                navigate(
                  `/sentinels/${row.number
                    .slice(0, 4)
                    .concat('-')
                    .concat(row.number.slice(7))}`,
                  {
                    state: row,
                  },
                );
              }}
            >
              {row.number}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      title: 'Severity',
      field: 'severity',
      render: (data: ChipRender) => {
        return (
          <Chip
            label={data.severity ?? data}
            style={chipRender(data.severity ?? data)}
          />
        );
      },
    },
    { title: 'Last Update', field: 'sent_at' },
    { title: 'Status', field: 'status' },
    {
      title: 'Subject',
      field: 'subject',
      render: (rowData: ChipRender) => {
        if (rowData.subject[1] === '  ') {
          return (rowData.subject = 'No Subject');
        }
        return rowData.subject ?? rowData;
      },
    },
  ];

  if (numberId) {
    columns.shift();
  }

  const data = sentinels ?? [];

  const formatSentinelData = (value: Sentinel | undefined) => {
    if (!value) return null;

    return {
      id: value.id,
      severity: value.severity,
      number: value.number,
      status: value.status,
      subject: value.subject.split('-')[1].split(/\d+/),
      sent_at: new Date(value.sent_at).toLocaleString(
        'en-eu',
        numberId
          ? {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: false,
            }
          : { year: 'numeric', month: 'long', day: 'numeric' },
      ),
    };
  };

  const formattedData = (querySentinels || data)?.map(
    formatSentinelData,
  ) as unknown as Sentinel[];

  const ApiTable = () => {
    return (
      <Table
        isLoading={sentinelsValueLoading}
        options={{
          search: false,
          filtering: false,
          paging: true,
          searchAutoFocus: true,
          sorting: true,
          columnResizable: true,
          // grouping: true,
          showTextRowsSelected: true,
          doubleHorizontalScroll: true,
          padding: 'dense',
          showEmptyDataSourceMessage: !sentinelsValueLoading,
        }}
        columns={columns}
        data={formattedData}
      />
    );
  };

  return (
    <div>
      {!numberId ? (
        <Content>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <SeverityTypePicker
                initialSeverityTypes={severityTypes}
                onSelectSeverityTypes={values => setSeverityTypes(values)}
              />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <ApiTable />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </Content>
      ) : (
        <div>
          {numberId ? (
            <h3 style={arrowStyle().arrowColor}>
              <ArrowBackIcon
                onClick={() => navigate('/sentinels')}
                style={arrowStyle().arrowIcon}
              />
              All sentinels
            </h3>
          ) : (
            <div />
          )}
          <ApiTable />
        </div>
      )}
    </div>
  );
};

export const FetchTable = () => {
  const { value, loading, error } = useAsync(async (): Promise<
    Sentinel[] | undefined
  > => {
    return value;
  }, []);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return <SentinelsData />;
};
