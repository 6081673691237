import { BackstageOverrides } from "@backstage/core-components";
import { BackstageTheme, createTheme, lightTheme } from "@backstage/theme";

export const basicVaporTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    success: {
      main: "#09822A",
    },
    warning: {
      main: "#FDB927",
    },
    error:{
      main: "#D82829",
    }
  },
  defaultPageTheme: 'home',
  fontFamily: 'Cairo, sans-serif;'
});

export const createCustomThemeOverrides = (_theme: BackstageTheme,): BackstageOverrides => {
  return {
    BackstageHeader: {
      header: {
        width: 'auto',
        backgroundColor: "#152935",
        backgroundImage: "none",
        // borderBottom: `4px solid ${theme.palette.primary.main}`,
        padding: "12.5px"
      },
      title: {
        fontWeight: 700,
        fontSize: "20px",
        fontFamily: "Cairo, sans-serif;"
      },
      subtitle: {
        fontSize: "16px",  
        fontFamily: "Cairo, sans-serif;",
        fontWeight: 400,
        marginTop: "0px"
      }
    },
    BackstagePage: {
      root: {
        paddingTop: "0px"
      }
    },
    BackstageContent: {
      root: {
        background: "#FFFFFF"
      }
    },
    BackstageSidebar: {
      drawer: {
        // width: "224px !important",
        backgroundColor: "#152935",
      },
      // drawerOpen: {
      //   width: "224px !important",
      // }
    },
    BackstageSidebarItem: {
      root: {
        color: "#ffffff",
        border: "none",
        borderLeft: "none !important",
        fontFamily: "Cairo, sans-serif;",
        borderRadius: "3px",
        width: "94% !important",
        marginLeft: "auto !important",
        marginRight: "auto !important",
        height: "36px",
        "&.Mui-selected": {
          color: "#FFFFFF !important",
        },
        "&:hover": {
          backgroundColor: "rgb(157, 166, 173) !important",
        }
      },
      highlighted: {
        backgroundColor: "#364752",
        borderRadius: "3px",
        width: "94% !important",
        marginLeft: "auto !important",
        marginRight: "auto !important",
        height: "36px"
      },
      highlightable: {
        width: "94% !important",
        marginLeft: "auto !important",
        marginRight: "auto !important",
      },
      selected: {
        backgroundImage: "linear-gradient(315deg, rgb(0, 195, 234), rgb(0, 144, 209))",
        border: "none",
        borderLeft: "none !important",
        borderRadius: "3px",
        textShadow: "1px 1px 0 rgba(21,41,53,0.24)",
        fontWeight: 500,
        width: "94% !important",
        marginLeft: "auto !important",
        marginRight: "auto !important",
        height: "36px",
      },
      iconContainer: {
        width: "50px",
        marginLeft: "0px !important",
        marginRight: "-5px"
      },
      open: {
        width: "94% !important",
        marginLeft: "auto !important",
        marginRight: "auto !important",
      },
      submenuArrow: {
        marginLeft: "auto"
      },
    },
    MuiButton: {
      root: {
        "&:hover": {
          // backgroundColor: "rgb(157, 166, 173) !important",
          backgroundColor: "#FFFFFF !important",
        }
      },
      containedPrimary: {
        backgroundImage: "linear-gradient(315deg, rgb(0, 195, 234), rgb(0, 144, 209))",
        textShadow: "1px 1px 0 rgba(21,41,53,0.24)",
        fontWeight: 500,
        boxShadow: "none",
      },
      textPrimary: {
        color: "#0090D1",
        fontFamily: "Cairo, sans-serif;",
        fontWeight: 500,
        letterSpacing: "0.2px" 
      },
      outlinedPrimary: {
        borderColor: "#0090D1",
        color: "#0090D1",
        fontFamily: "Cairo, sans-serif;",
        fontWeight: 500,
        borderRadius: 0
      }
    },
    MuiStepIcon: {
      text: {
        textShadow: "1px 1px 0 rgba(21,41,53,0.24)",
        fontSize: "14px",
        fontFamily: "Cairo, sans-serif;",
        fontWeight: 700
      }
    },
    MuiStepLabel: {
      label: {
        color: "#005075",
        fontFamily: "Cairo, sans-serif;",
        fontWeight: 600,
      },
      active: {
        color: "#005075",
        fontFamily: "Cairo, sans-serif;",
        fontWeight: 600,
      }
    },
    MuiTypography: {
      h1: {
        fontFamily: "Cairo, sans-serif;",
        fontWeight: 700,
        fontSize: "42px",
        lineHeight: "1.33"
      },
      h2: {
        fontFamily: "Cairo, sans-serif;",
        fontWeight: 700,
        fontSize: "34px",
        lineHeight: "1.4"
      },
      h3: {
        fontFamily: "Cairo, sans-serif;",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "1.67"
      },
      h4: {
        fontFamily: "Cairo, sans-serif;",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "1.6"
      },
      h5: {
        fontFamily: "Cairo, sans-serif;",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "1.33"
      },
      h6: {
        fontFamily: "Cairo, sans-serif;"
      },
      body1: {
        fontFamily: "Cairo, sans-serif;"
      },
      body2: {
        fontFamily: "Cairo, sans-serif;"
      },
      subtitle1: {
        fontFamily: "Cairo, sans-serif;"
      },
      subtitle2: {
        fontFamily: "Cairo, sans-serif;"
      },
      root: {
        fontFamily: "Cairo, sans-serif;"
      }
    },
    MuiTabs: {
      root: {
        fontFamily: "Cairo, sans-serif;"
      },
      indicator: {
        backgroundColor: "#0090D1",
        height: "4px"
      }
    },
    MuiTab: {
      root: {
        fontFamily: "Cairo, sans-serif;",
        textAlign: "center",
        minWidth: "100px !important",
        backgroundColor: "#FFFFFF !important",
        borderBottom: "solid 1px #C9D9E8",
        "&.Mui-selected": {
          backgroundColor: "#F2F5F8 !important",
          backgroundImage: "none !important",
        },
        "&:hover": {
          backgroundColor: "#0090D1 !important",
          color: "#FFFFFF !important"
        }
      }
    },
    BackstageHeaderTabs: {
      defaultTab: {
        fontFamily: "Cairo, sans-serif;",
        color: "#5A6872",
      },
      selected: {
        backgroundColor: "#F2F5F8",
        color: "#0090D1",
        borderBottomColor: "#0090D1"
      }
    },
    BackstageCardTab: {
      root: {
        fontFamily: "Cairo, sans-serif;",
        color: "#5A6872",
      },
      selected: {
        color: "#0090D1",
        borderBottomColor: "#0090D1"
      }
    },
    BackstageTabbedCard: {
      indicator: {
        backgroundColor: "#0090D1",
      }
    },
    MuiCard: {
      root: {
        borderRadius: 0,
        // boxShadow: "none"
      }
    },
    MuiCardHeader: {
      title: {
        fontSize: '24px',
        color: "#005075",
        lineHeight: "24px",
        fontFamily: "Cairo, sans-serif;",
        fontWeight: 600,
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: 0
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: '24px',
        color: '#005075',
        letterSpacing: '0',
        lineHeight: '16px',
        fontFamily: "Cairo, sans-serif;",
        fontWeight: 600,
        textAlign: 'left',
      },
    },
    MuiDialogContent: {
      dividers: {
        borderTopColor: "#0090D1",
        borderTopWidth: "2px",
      }
    },
    MuiPaper: {
      elevation2: {
        borderRadius: 0,
        boxShadow: "none"
      }
    },
    BackstageTableHeader: {
      header: {
        fontSize: "14px",
        color: "#005075 !important",
        lineHeight: "20px",
        fontFamily: "Cairo, sans-serif;",
        fontWeight: "bold",
        // textTransform: "capitalize !important",
        borderBottomColor: "#9DA6AD"
      },
    },
    MuiTableRow: {
      root: {
        backroundColor: "#ffffff"
      }
    },
    MuiTableCell: {
      body: {
        borderBottom: "1px solid #9DA6AD",
        fontSize: '14px',
        color: '#152935',
        lineHeight: '18px',
        fontFamily: 'Roboto',
        fontWeight: 400,
      }
    },
    MuiLink: {
      root: {
        color: "#0090d1",
        },
      },
    BackstageSelectInputBase: {
      input: {
        borderRadius: 0,
        borderColor: "#C9D9E8",
        backgroundColor: "#F2F5F8",
        color: "#152935"
      },
    },
    MuiListItem: {
      root: {
        borderRadius: "3px",
        "&:hover": {
          backgroundColor: "rgb(157, 166, 173) !important",
          color: "white",
          "& .MuiListItemIcon-root": {
            color: "white"
          }
        }
      },
      selected: {
        backgroundImage: "linear-gradient(315deg, rgb(0, 195, 234), rgb(0, 144, 209))",
      }
    },
    MuiListItemText: {
      root: {
        whiteSpace: "normal"
      }
    },
    // MuiToggleButton: {
    //   root: {
    //     "&.Mui-selected": {
    //       color: "#FFFFFF !important",
    //     },
    //   }
    // }
  };
};