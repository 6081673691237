import React, { Fragment } from 'react';
import { PageWithHeader, Content, ItemCardGrid, ItemCardHeader, LinkButton } from '@backstage/core-components';
import { Card, CardActions, CardContent, CardMedia, Container, Typography } from '@material-ui/core';

export const GettingStarted = () => {
    return (
        <Fragment>
            <PageWithHeader title='Getting started' subtitle='An overview on principal technical services' themeId='home'>
                <Content noPadding>
                    <Container style={{ marginLeft: 'auto', marginRight: 'auto', paddingTop: 45, paddingBottom: 45, maxWidth: '97%' }}>
                        {/* <Typography variant='h3'>Introduction</Typography>
                        <Typography variant='body1' style={{marginBottom: 30}}>
                            Digital accessibility enables people with disabilities to interact with digital platforms. Including it in the design process from the beginning prevents needing to do strong reworks afterwards, elevates the quality of the design and translates in a better usability and user experience.
                            This guidelines aim to help you to include basic accessibility techniques in your daily work, even if you don't have any accessibility
                            knowledge
                            Creating accessible products and services requires the collaboration of all the stakeholders. Rather than focusing only in your area, take a look at the guidelines for all roles to have a good overview.
                        </Typography> */}
                        <ItemCardGrid>
                            <Card>
                                <CardMedia>
                                    <ItemCardHeader title='TeamSystem-ID' subtitle='Getting started'/>
                                </CardMedia>
                                <CardContent>
                                    <Typography variant='body1'>The TeamSystem ID v3 is an Identity and Access Management (IAM) system to securely control access to the resources and applications of the TeamSystem world.</Typography>
                                </CardContent>
                                <CardActions>
                                    <LinkButton color='primary' to='/docs/default/component/ts-id'>Explore</LinkButton>
                                </CardActions>
                            </Card>

                            <Card>
                                <CardMedia>
                                    <ItemCardHeader title='OneFront' subtitle='Getting started'/>
                                </CardMedia>
                                <CardContent>
                                    <Typography variant='body1'>OneFront is a framework that allows developers to build OnePlatform-compliant applications without the hassle.</Typography>
                                </CardContent>
                                <CardActions>
                                    <LinkButton color='primary' to='/docs/default/area/core-services-experience-onefront'>Explore</LinkButton>
                                </CardActions>
                            </Card>

                            <Card>
                                <CardMedia>
                                    <ItemCardHeader title='Policy Manager' subtitle='Getting started'/>
                                </CardMedia>
                                <CardContent>
                                    <Typography variant='body1'>Policy Manager is based on Open Policy Agent project at CNCF, and enable usage of permission policies on all OnePlatform services and components.</Typography>
                                </CardContent>
                                <CardActions>
                                    <LinkButton color='primary' to='/docs/default/module/policy-manager'>Explore</LinkButton>
                                </CardActions>
                            </Card>

                            <Card>
                                <CardMedia>
                                    <ItemCardHeader title='Hermes' subtitle='Getting started'/>
                                </CardMedia>
                                <CardContent>
                                    <Typography variant='body1'>Hermes is the Teamsystem streaming data platform with the purpose of conveying events of common interest among the application domains that make up OnePlatform.</Typography>
                                </CardContent>
                                <CardActions>
                                    <LinkButton color='primary' to='/docs/default/module/hermes'>Explore</LinkButton>
                                </CardActions>
                            </Card>

                            <Card>
                                <CardMedia>
                                    <ItemCardHeader title='Metering' subtitle='Getting started'/>
                                </CardMedia>
                                <CardContent>
                                    <Typography variant='body1'>It is the project that deals with the management of packages and the consumption of each package.</Typography>
                                </CardContent>
                                <CardActions>
                                    <LinkButton color='primary' to='/docs/default/module/metering'>Explore</LinkButton>
                                </CardActions>
                            </Card>

                            <Card>
                                <CardMedia>
                                    <ItemCardHeader title='Workspace' subtitle='Getting started'/>
                                </CardMedia>
                                <CardContent>
                                    <Typography variant='body1'>The Workspace is an unique service, designed for SMEs and Professionals to help them connect, manage, automate and configure stakeholders data.</Typography>
                                </CardContent>
                                <CardActions>
                                    <LinkButton color='primary' to='/docs/default/module/workspace'>Explore</LinkButton>
                                </CardActions>
                            </Card>
                        </ItemCardGrid>
                    </Container>
                </Content>
            </PageWithHeader>
        </Fragment>
    )
};