import { useSentinelsApi } from '../../utils/hooks';

import useAsync from 'react-use/lib/useAsync';
import { SentinelsQueryResponse } from '../../types';

export const SentinelsGetAll = (id: string | undefined) => {
  const {
    value: sentinelsValue,
    loading: sentinelsValueLoading,
    error: sentinelsValueError,
  } = useSentinelsApi(api => api.getSentinels(id), [id]);

  return {
    sentinelsValue,
    sentinelsValueLoading,
    sentinelsValueError,
  };
};

export const SentinelsFilterQuery = (severityTypes: string[], api: any) => {
  const {
    value: sentinelsQueryValue,
    loading: sentinelsQueryLoading,
    error: sentinelsQueryError,
  } = useAsync(() => {
    return api.getUsingNamedParameters({
      severityTypes: severityTypes.length ? severityTypes : undefined,
    }) as Promise<SentinelsQueryResponse>;
  }, [severityTypes]);
  return { sentinelsQueryValue, sentinelsQueryLoading, sentinelsQueryError };
};
