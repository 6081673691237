import React from 'react';
// import { useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { HomePageIFrameCard } from '@roadiehq/backstage-plugin-iframe';

export const TechnicalSupportPage = () => {

    // useEffect(() => {
    //     const script = document.createElement('script');
        
    //     script.src = "https://jsd-widget.atlassian.com/assets/embed.js";
    //     script.setAttribute('data-jsd-embedded', '');
    //     script.setAttribute('data-key', '28928667-433b-479a-847f-091d3871ef38');
    //     script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
    //     script.async = true;
        
    //     document.body.appendChild(script);
        
    //     return () => {
    //         document.body.removeChild(script);
    //     }
    // }, []);

    return (
        // <></>
        <Grid item xs={12} md={6}>
            <HomePageIFrameCard
                title="Technical Support"
                src="https://teamsystem.atlassian.net/servicedesk"
                width='100%'
                height='800'
            />
        </Grid>
  );
};