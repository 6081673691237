import React from 'react';
import { Box, TextField, Typography, makeStyles } from '@material-ui/core';
import { DateTime } from 'luxon';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    colorScheme: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export type DateTimePickerProps = {
  title: string;
  value: Date;
  onChangeValue: (value: Date | undefined) => void;
}

export const DateTimePicker = (props: DateTimePickerProps) => {

  const { title, value, onChangeValue, } = props;

  const classes = useStyles();

  return (
    <Box pb={1} pt={1}>
      <Typography
        variant='button'
        component='label'
        htmlFor='date-picker'
      >
        {title}
      </Typography>   
      <form className={classes.container} noValidate>
        <TextField
          id='date'
          type='date'
          defaultValue={DateTime.fromJSDate(value).toFormat('yyyy-MM-dd')}
          onKeyDown={event => {event.preventDefault()}}
          onChange={event => {onChangeValue(new Date(event.target.value))}}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </form>   
    </Box>
  );
}