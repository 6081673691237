import React, { useState, useEffect, Fragment } from 'react';
import { SentinelsGetAll } from './SentinelsApiClient';
import {
  Typography,
  Card,
  CardActions,
  CardContent,
  Button,
  Chip,
  Box,
  Divider,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { SentinelResponse } from '../../types';

export const SummarySentinels = (props: { classes: string | undefined }) => {
  const { sentinelsValue } = SentinelsGetAll('');

  const [sentinels, setSentinels] = useState<SentinelResponse[] | undefined>(
    undefined,
  );

  const navigate = useNavigate();

  useEffect(() => {
    return setSentinels(sentinelsValue?.data);
  }, [sentinelsValue]);

  return (
    <div>
      {sentinels?.slice(0, 3).map((item: SentinelResponse) => (
        <Fragment key={item.id}>
          <Card style={{ boxShadow: 'none' }}>
            <CardContent>
              <Box className={props.classes}>
                <Typography variant="body2">
                  <b>{item.number}</b>
                </Typography>
                <Typography variant="body2">
                  published on:
                  {new Date(item.sent_at).toLocaleDateString('en-GB')}
                </Typography>
                <Fragment>
                  {item.severity === 'LOW' ? (
                    <Chip
                      size="small"
                      label={item.severity}
                      style={{ backgroundColor: '#f2cc0c' }}
                    />
                  ) : null}
                  {item.severity === 'MEDIUM' ? (
                    <Chip
                      size="small"
                      label={item.severity}
                      style={{ backgroundColor: '#e9967a' }}
                    />
                  ) : null}
                  {item.severity === 'HIGH' ? (
                    <Chip
                      size="small"
                      label={item.severity}
                      style={{
                        backgroundColor: '#d9534f',
                        color: '#FFFFFF',
                      }}
                    />
                  ) : null}
                  {item.severity === 'INFORMATIONAL' ? (
                    <Chip
                      size="small"
                      label={item.severity}
                      style={{
                        backgroundColor: '#89CFF0',
                        color: '#FFFFFF',
                      }}
                    />
                  ) : null}
                </Fragment>
                <Typography variant="body2">{item.subject}</Typography>
              </Box>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                onClick={() => {
                  navigate(
                    `/sentinels/${
                      item.number.slice(0, 4) + '-' + item.number.slice(7)
                    }`,
                    {
                      state: item,
                    },
                  );
                }}
              >
                Discover more
              </Button>
            </CardActions>
          </Card>
          <Divider />
        </Fragment>
      ))}
    </div>
  );
};
