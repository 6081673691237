import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { Typography } from '@material-ui/core';
import LensRounded from '@material-ui/icons/LensRounded';
import { Grid, useMediaQuery } from '@material-ui/core';

export const ScoringLegend = ({ isMain }: { isMain: boolean }) => {
  const [justifyContentValue, setJustifyContentValue] = useState<
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  >('flex-end');
  const isExtraSmallScreen = useMediaQuery('(max-width: 599px)'); // xs breakpoint
  const isSmallScreen = useMediaQuery(
    '(min-width: 600px) and (max-width: 959px)',
  ); // sm breakpoint
  const isMediumScreen = useMediaQuery(
    '(min-width: 960px) and (max-width: 1279px)',
  ); // md breakpoint
  const isLargeScreen = useMediaQuery(
    '(min-width: 1280px) and (max-width: 1919px)',
  ); // lg breakpoint
  const isExtraLargeScreen = useMediaQuery('(min-width: 1920px)'); // xl breakpoint

  useEffect(() => {
    if (isExtraSmallScreen) setJustifyContentValue('flex-start');
    else if (isSmallScreen) setJustifyContentValue('flex-start');
    else if (isMediumScreen) {
      if (isMain) {
        setJustifyContentValue('flex-end');
      } else {
        setJustifyContentValue('flex-start');
      }
    } else if (isLargeScreen) setJustifyContentValue('flex-end');
    else if (isExtraLargeScreen) setJustifyContentValue('flex-end');
  }, [
    isExtraSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isExtraLargeScreen,
  ]);

  return (
    <Grid container alignItems="center" justifyContent={justifyContentValue}>
      <Grid item>
        <Stack direction="row" alignItems="center">
          <LensRounded fontSize="small" style={{ color: '#DCDCDC' }} />
          &nbsp;
          <Typography>0 - N/A</Typography>
        </Stack>
      </Grid>

      <Grid item>
        <Stack direction="row" alignItems="center">
          <LensRounded fontSize="small" color="error" />
          &nbsp;
          <Typography>1 - Not Adopted</Typography>
        </Stack>
      </Grid>

      <Grid item>
        <Stack direction="row" alignItems="center">
          <LensRounded fontSize="small" style={{ color: '#F5B9B5' }} />
          &nbsp;
          <Typography>2 - In definition/verify</Typography>
        </Stack>
      </Grid>

      <Grid item>
        <Stack direction="row" alignItems="center">
          <LensRounded fontSize="small" style={{ color: '#FDB51C' }} />
          &nbsp;
          <Typography>3 - Partially adopted</Typography>
        </Stack>
      </Grid>

      <Grid item>
        <Stack direction="row" alignItems="center">
          <LensRounded fontSize="small" style={{ color: '#097C28' }} />
          &nbsp;
          <Typography>4 - Adopted</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
