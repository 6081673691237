import React, { Fragment } from "react";
import { PageWithHeader, Content } from '@backstage/core-components';
import { Grid, Typography, Container, Button, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const DesignPrinciples = () => {
    return (
        <Fragment>
            <PageWithHeader title="Design Principles" subtitle="Key Concepts" themeId="home">
                <Content noPadding>
                    <Container style={{ marginLeft: "auto", marginRight: "auto", paddingTop: 45, paddingBottom: 45, maxWidth: "97%" }}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={2}>
                                                <ListIcon />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="h5">Table of Contents</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="row">
                                            <Button href="#1" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>1. Native SaaS, Multitenant, Multicloud, Mobile enabled</Button>
                                            <Button href="#2" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>2. Cybersecurity and Privacy by Design</Button>
                                            <Button href="#3" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>3. Collaborative platforms talking through APIs</Button>
                                            <Button href="#4" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>4. Once only i.e. high adoption of OnePlatform</Button>
                                            <Button href="#5" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>5. Digital first / AI embedded</Button>
                                            <Button href="#6" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>6. Design to: cost/ to onboard/ to retain/ to maintain</Button>
                                            <Button href="#7" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>7. Engagement in SW / Buy in SW</Button>
                                            <Button href="#8" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>8. Enhance collaboration between stakeholders</Button>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={9} style={{ marginLeft: 50 }}>
                                <div id="1">
                                    <Typography variant="h3">1. Native SaaS, Multitenant, Multicloud, Mobile enabled</Typography>
                                    <Typography variant="body1">
                                        <ul>
                                            <li>Sfruttare il cloud, oltre all'archiviazione e hosting, con ridimensionamento elastico di risorse e aggiornamento continuo. Automazione.</li>
                                            <li>Architetture componibili, a servizi. Ogni componente è collegabile, scalabile, sostituibile e può essere continuamente migliorato attraverso uno sviluppo agile per soddisfare i requisiti aziendali in continua evoluzione</li>
                                            <li>API al centro: tutte le funzionalità esposte via API</li>
                                        </ul>
                                    </Typography>
                                </div>

                                <div id="2" style={{ marginTop: 30 }}>
                                    <Typography variant="h3">2. Cybersecurity and Privacy by Design</Typography>
                                    <Typography variant="body1">
                                        <ul>
                                            <li>I servizi OnePlatform utilizzano processi automatizzati tramite <b>Biosphere</b></li>
                                            <li>Il processo di <b>revisione architetturale</b> convalida le varie fasi di sviluppo e gestione dei servizi in quanto la compliance di policies di cybersecurity e data privacy</li>
                                        </ul>

                                        <b>Biosphere</b>: Garantisce che i servizi 1P usufruiscano automaticamente degli strumenti:
                                        <ul>
                                            <li>Analisi statica del codice</li>
                                            <li>Infrastrutture-as-code</li>
                                            <li>Centralized secret manager</li>
                                            <li>Infrastructure policy manager</li>
                                        </ul>

                                        <b>Security Engineering</b>:
                                        <ul>
                                            <li>Gestione da Security Engineering in quanto ai processi di penetration testing (WAPT)</li>
                                            <li>Doppia certificazione (retest) WAPT tramite entità esterna</li>
                                            <li>Integrazione tramite canali autorizzati (API Gateway, Integration Hub) e Firewall imposto da policy management</li>
                                            <li>Continua formazione OWASP agli sviluppatori</li>
                                        </ul>

                                        <b>Data Privacy</b>:
                                        <ul>
                                            <li>analisi continua di compliance sull privacy (GDPR, MOD231)</li>
                                            <li>Analisi continua sui processi di Risk Management</li>
                                            <li>Coordinamento e verifica con DPO sui principi di Privacy by Design</li>
                                        </ul>
                                    </Typography>
                                </div>

                                <div id="3" style={{ marginTop: 30 }}>
                                    <Typography variant="h3">3. Collaborative platforms talking through APIs</Typography>
                                    <Typography variant="body1">
                                        <b>Agilità:</b>
                                        <ul>
                                            <li>Tramite modalità standard, espongono funzionalità di altre applicazioni, (qualsiasi tipo di entità a prescindere dal gestionale sottostante)</li>
                                            <li>Semplificano il dialogo tra le applicazioni, evitando ridondanze e replicazioni di codice e possibili incoerenze funzionali</li>
                                            <li>Aumentano velocità nella distribuzione delle nuove features</li>
                                        </ul>

                                        <b>Business</b>
                                        <ul>
                                            <li>Indispensabili per rendere disponibili i servizi per il business</li>
                                            <li>Abilitano la distribuzione dei servizi tradizionali su device digitali e generano nuove forme di revenue</li>
                                            <li>Riducono l'impatto delle personalizzazioni sullo standard</li>
                                        </ul>

                                        <b>Collaborazione</b>
                                        <ul>
                                            <li>estensione dei servizi attraverso API pubbliche a partner e developers</li>
                                        </ul>

                                        <b>UX</b>
                                        <ul>
                                            <li>forniscono un mezzo per proteggere l'utente dalla complessità e/o da eventuali interventi ai sistemi sottostanti</li>
                                        </ul>
                                    </Typography>
                                </div>

                                <div id="4" style={{ marginTop: 30 }}>
                                    <Typography variant="h3">4. Once only i.e. high adoption of OnePlatform</Typography>
                                    <Typography variant="body1">
                                        <ul>
                                            <li>I nuovi servizi devono essere concepiti "Once only"</li>
                                            <li>Servizi Back-End e servizi "Black-Box"</li>
                                        </ul>
                                        <b>Sono già stati realizzati su questo principio</b>:
                                        <ul>
                                            <li>TS ID</li>
                                            <li>Hub F.E.</li>
                                            <li>Bilancio</li>
                                        </ul>
                                    </Typography>
                                </div>

                                <div id="5" style={{ marginTop: 30 }}>
                                    <Typography variant="h3">5. Digital first / AI embedded</Typography>
                                    <Typography variant="body1">
                                        <ul>
                                            <li>Progettare il nostro Software pensando al contatto tra cliente, il suo business e la sua quotidianità già in maniera digitale</li>
                                            <li>Predittivo, proattivo, condivisibile, capace di apprendere e soprattutto semplice</li>
                                        </ul>
                                    </Typography>
                                </div>

                                <div id="6" style={{ marginTop: 30 }}>
                                    <Typography variant="h3">6. Design to: cost/ to onboard/ to retain/ to maintain</Typography>
                                    <Typography variant="body1">
                                        <b>Integrare nel disegno del prodotto gli elementi chiave per</b>:
                                        <ul>
                                            <li>Creare esperienza cliente efficiente ed integrata (che minimizza la necessità per il cliente di ricorrere al Customer Support)</li>
                                            <li>Ottenere percorso facile/ attrattivo per onboarding/ imparare ad usare il prodotto</li>
                                            <li>Stimolare retention proattiva/ tailored su reale utilizzo e agganciare cliente tramite feature</li>
                                            <li>Ottenere architettura efficiente per aggiornare/evolvere il prodotto</li>
                                        </ul>
                                    </Typography>
                                </div>

                                <div id="7" style={{ marginTop: 30 }}>
                                    <Typography variant="h3">7. Engagement in SW / Buy in SW</Typography>
                                    <Typography variant="body1">
                                        <ul>
                                            <li>To promote feature adoption</li>
                                            <li>To know more about users</li>
                                            <li>To improve user retention</li>
                                            <li>To boost cross/upselling</li>
                                        </ul>
                                    </Typography>
                                </div>

                                <div id="8" style={{ marginTop: 30 }}>
                                    <Typography variant="h3">8. Enhance collaboration between stakeholders</Typography>
                                    <Typography variant="body1">
                                        <b>Enhance collaboration between stakeholders</b>:
                                        <ul>
                                            <li>Integrare i sistemi per integrare i processi</li>
                                            <li>Stimolare e arricchire la comunicazione tra gli attori</li>
                                            <li>Sistema di notifiche push unificato</li>
                                            <li>Collaborazione real time su documenti e informazioni</li>
                                            <li>Anytime, anywhere from any device</li>
                                            <li>Interlocutori digitali (bot e AI)</li>
                                        </ul>
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Content>
            </PageWithHeader>
        </Fragment>
    )
};