import React, { useState, Fragment, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackstageTheme } from '@backstage/theme';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Box,
  Divider,
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
  useTheme,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
  PageWithHeader,
  Content,
  ItemCardHeader,
} from '@backstage/core-components';
import { NewAnnouncementBanner } from '@backstage-community/plugin-announcements';
import { SummarySentinels } from '@internal/plugin-sentinels';
import { SentinelsGetAll } from '@internal/plugin-sentinels';
import { CveSummaryCard } from '@giocolas/backstage-plugin-nvd';
import { Citymap } from '@teamsystem/backstage-plugin-citymap-react';

const useStyles = makeStyles<
  BackstageTheme,
  { fontColor: string; backgroundImage: string }
>(theme => ({
  cardHeader: {
    position: 'relative',
  },
  title: {
    backgroundImage: ({ backgroundImage }) => backgroundImage,
    color: ({ fontColor }) => fontColor,
  },
  box: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 10,
    '-webkit-box-orient': 'vertical',
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '0.65rem',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    lineHeight: 1,
    paddingBottom: '0.2rem',
  },
  linksLabel: {
    padding: '0 16px',
  },
  description: {
    '& p': {
      margin: '0px',
    },
  },
  leftButton: {
    marginRight: 'auto',
  },
  starButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    padding: '0.25rem',
    color: ({ fontColor }) => fontColor,
  },
  vaporHeroContainer: {
    height: '400px',
    padding: 28,
    backgroundImage: "url('/resources/images/vaporHero.png')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#FFFFFF',
    position: 'relative',
    height: '100%',
    opacity: '50%',
    background: 'rgba(0,0,0,0.2)',
  },
}));

export const HomePage = () => {
  const navigate = useNavigate();
  const backstageTheme = useTheme<BackstageTheme>();
  const themeId = 'documentation';
  const theme = backstageTheme.getPageTheme({ themeId });
  const classes = useStyles({
    fontColor: theme.fontColor,
    backgroundImage: theme.backgroundImage,
  });

  const [suggestedCloudProvider] = useState<'aws' | 'azure'>('azure');

  // eslint-disable-next-line new-cap
  const { sentinelsValueLoading } = SentinelsGetAll(undefined);

  const [selectedHcTabValue, setSelectedHcTabValue] = useState('dev');
  const handleHCTabChange = (event: ChangeEvent<{}>, newValue: String) => {
    event.stopPropagation();
    setSelectedHcTabValue(newValue.toString());
  };

  return (
    <Fragment>
      <PageWithHeader title="Development Portal" subtitle="Home" themeId="home">
        <Content noPadding>
          <Container
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: 45,
              paddingBottom: 45,
              maxWidth: '97%',
            }}
          >
            <Grid container alignItems="flex-end">
              <Grid item xs={12}>
                <NewAnnouncementBanner max={3} />
              </Grid>
              <Grid item xs={7}>
                <Typography variant="h2">Welcome to the Docs</Typography>
                <Typography variant="h4">
                  Here you'll find guides, resources, and references to build
                  your project
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Alert
                  severity="info"
                  icon={false}
                  style={{ marginBottom: 10 }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={10}>
                      <b>Cloud Provider Suggestion</b>
                      <br />
                      For your new Cloud project or service, we strongly
                      recommend to use
                    </Grid>
                    <Grid item xs={2}>
                      <img
                        style={{ width: '70%', marginTop: 10 }}
                        src={`resources/images/${
                          suggestedCloudProvider === 'aws'
                            ? 'awsLogo.png'
                            : 'msAzureLogo.png'
                        }`}
                        alt={`${
                          suggestedCloudProvider === 'aws' ? 'AWS' : 'Azure'
                        }`}
                      />
                    </Grid>
                  </Grid>
                </Alert>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: 10, marginBottom: 20 }} />

            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={3}>
                <Card>
                  <CardMedia>
                    <ItemCardHeader
                      title="Key Concepts"
                      subtitle="Discover the basics"
                    />
                  </CardMedia>
                  <CardContent>
                    <Box className={classes.box}>
                      <Typography
                        style={{ marginBottom: '4px' }}
                        variant="body2"
                      >
                        Learn about TeamSystem's key concepts before you
                        <br />
                        start coding
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button
                      color="primary"
                      onClick={() => {
                        navigate('/keyConcepts/systemArchitecture');
                      }}
                    >
                      Explore
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardMedia>
                    <ItemCardHeader
                      title="Getting started"
                      subtitle="Hands on code"
                    />
                  </CardMedia>
                  <CardContent>
                    <Box className={classes.box}>
                      <Typography
                        style={{ marginBottom: '4px' }}
                        variant="body2"
                      >
                        Discover how to begin to integrate the
                        <br />
                        TeamSystem's Platform Services
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button
                      color="primary"
                      onClick={() => {
                        navigate('/gettingStarted');
                      }}
                    >
                      Get started
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardMedia>
                    <ItemCardHeader
                      title="Catalog"
                      subtitle="Platform's Services"
                    />
                  </CardMedia>
                  <CardContent>
                    <Box className={classes.box}>
                      <Typography
                        style={{ marginBottom: '4px' }}
                        variant="body2"
                      >
                        Explore our Platform's services and discover their
                        <br />
                        features
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button
                      color="primary"
                      onClick={() => {
                        navigate('/catalog');
                      }}
                    >
                      Browse the Catalog
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardMedia>
                    <ItemCardHeader
                      title="API Reference"
                      subtitle="Integrate our services"
                    />
                  </CardMedia>
                  <CardContent>
                    <Box className={classes.box}>
                      <Typography
                        style={{ marginBottom: '4px' }}
                        variant="body2"
                      >
                        Explore Server-side API libraries and integrate with
                        <br />
                        API endpoints
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button
                      color="primary"
                      onClick={() => {
                        navigate('/api-docs');
                      }}
                    >
                      View reference
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              spacing={2}
              alignItems="stretch"
              style={{ height: '490px', marginTop: 40 }}
            >
              <Grid item xs={12}>
                <Citymap />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4">Vapor Design System</Typography>

                <div className={classes.vaporHeroContainer}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        style={{
                          color: '#FFFFFF',
                          width: '60%',
                          marginBottom: 20,
                        }}
                      >
                        Vapor is TeamSystem’s design system for digital products
                        and experiences. The system consists of user interface
                        guidelines, working code, design tools and resources.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          navigate('/keyConcepts/vaporDesign');
                        }}
                      >
                        Discover more
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="h4">
                  Latest security vulnerabilities
                </Typography>

                <div style={{ height: '400px', overflow: 'auto' }}>
                  <CveSummaryCard />
                </div>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="h4">Latest active sentinels</Typography>

                <div style={{ height: '400px', overflow: 'auto' }}>
                  {sentinelsValueLoading ? (
                    <Backdrop
                      className={classes.backdrop}
                      open={sentinelsValueLoading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  ) : (
                    <SummarySentinels classes={classes.box} />
                  )}
                </div>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="h4">Health Checks</Typography>

                <Tabs
                  value={selectedHcTabValue}
                  onChange={(e, value) => handleHCTabChange(e, value)}
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab value="dev" label="Dev" />
                  <Tab value="test" label="Test" />
                  <Tab value="prod" label="Prod" />
                </Tabs>

                {selectedHcTabValue === 'dev' ? (
                  <div style={{ height: '352px', overflow: 'auto' }}>
                    <Alert severity="warning" icon={false}>
                      Coming soon
                    </Alert>
                    {/* {hcDevItems.map((item) => (
                      <Fragment>
                        <Card style={{ boxShadow: "none" }}>
                          <CardContent>
                            <Box className={classes.box}>
                              <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={8}>
                                  <Typography variant="body2">
                                    <b>{item.name}</b>
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  {item.status === "Healthy" ? (<Chip size="small" label={item.status} style={{ backgroundColor: "#09822A", color: "#FFFFFF", marginBottom: 0 }} />) : null}
                                  {item.status === "Unhealthy" ? (<Chip size="small" label={item.status} style={{ backgroundColor: "#D82829", color: "#FFFFFF", marginBottom: 0 }} />) : null}
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                        </Card>
                        <Divider />
                      </Fragment>
                    ))} */}
                  </div>
                ) : null}

                {selectedHcTabValue === 'test' ? (
                  <div style={{ height: '352px', overflow: 'auto' }}>
                    <Alert severity="warning" icon={false}>
                      Coming soon
                    </Alert>
                    {/* {hcTestItems.map((item) => (
                      <Fragment>
                        <Card style={{ boxShadow: "none" }}>
                          <CardContent>
                            <Box className={classes.box}>
                              <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={8}>
                                  <Typography variant="body2">
                                    <b>{item.name}</b>
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  {item.status === "Healthy" ? (<Chip size="small" label={item.status} style={{ backgroundColor: "#09822A", color: "#FFFFFF", marginBottom: 0 }} />) : null}
                                  {item.status === "Unhealthy" ? (<Chip size="small" label={item.status} style={{ backgroundColor: "#D82829", color: "#FFFFFF", marginBottom: 0 }} />) : null}
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                        </Card>
                        <Divider />
                      </Fragment>
                    ))} */}
                  </div>
                ) : null}

                {selectedHcTabValue === 'prod' ? (
                  <div style={{ height: '352px', overflow: 'auto' }}>
                    <Alert severity="warning" icon={false}>
                      Coming soon
                    </Alert>
                    {/* {hcProdItems.map((item) => (
                      <Fragment>
                        <Card style={{ boxShadow: "none" }}>
                          <CardContent>
                            <Box className={classes.box}>
                              <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={8}>
                                  <Typography variant="body2">
                                    <b>{item.name}</b>
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  {item.status === "Healthy" ? (<Chip size="small" label={item.status} style={{ backgroundColor: "#09822A", color: "#FFFFFF", marginBottom: 0 }} />) : null}
                                  {item.status === "Unhealthy" ? (<Chip size="small" label={item.status} style={{ backgroundColor: "#D82829", color: "#FFFFFF", marginBottom: 0 }} />) : null}
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                        </Card>
                        <Divider />
                      </Fragment>
                    ))} */}
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Container>
        </Content>
      </PageWithHeader>
    </Fragment>
  );
};
