import React, { Fragment } from "react";
import { Typography, Container, Grid, Divider } from "@material-ui/core";
import { PageWithHeader, Content } from '@backstage/core-components';

export const OpenSourceLicences = () => {
    return (
        <Fragment>
            <PageWithHeader title="Open Source Licenses Utilization" subtitle="Guidelines" themeId="home">
                <Content noPadding>
                    <Container style={{ marginLeft: "auto", marginRight: "auto", paddingTop: 45, paddingBottom: 45, maxWidth: "97%" }}>
                        <Grid container spacing={0}>
                            <Typography variant="h3">Italian</Typography>
                            <Typography variant="body1"><b>Attraverso il form raggiungibile a questo <a href="https://forms.office.com/e/NJV7EUJE8n" target="_blank" style={{textDecoration: "underline"}}>link</a></b><br/>Potrai consultare l’elenco delle licenze open source già analizzate e/o richiedere al team di Direzione Affari Legali di analizzare una nuova licenza open source per ricevere approvazione o meno all’utilizzo della licenze opensource nelle attività di sviluppo software in TeamSystem.<br/>In caso di dubbi e/o laddove il Software Open Source che si intende utilizzare sia regolato da una licenza che non è espressamente indicata tra quelle vietate o ammesse indicate nel form, sarà necessario inviare una "Nuova autorizzazione".<br/>Per leggere il documento di policy relativo puoi far riferimento al portale <a href="https://tsspa.sharepoint.com/sites/InTeam/SitePages/Policy-e-Procedure(1).aspx" target="_blank" style={{textDecoration: "underline"}}>InTeam</a></Typography>
                            <Divider style={{marginTop: "50px", marginBottom: "50px"}} />
                            <Typography variant="h3">English</Typography>
                            <Typography variant="body1"><b>Through the form accessible at the this <a href="https://forms.office.com/e/NJV7EUJE8n" target="_blank" style={{textDecoration: "underline"}}>link</a></b><br/>You will be able to consult the list of open source licenses already analyzed and/or request the Legal Affairs Management team to analyze a new open source license to receive approval or otherwise for the use of open source licenses in software development activities in TeamSystem.<br/>In case of doubts and/or where the Open Source Software you intend to use is governed by a license that is not expressly indicated among the prohibited or permitted ones indicated in the form, it will be necessary to send a "New Authorization".<br/>To read the relevant policy document you can refer to the <a href="https://tsspa.sharepoint.com/sites/InTeam/SitePages/Policy-e-Procedure(1).aspx" target="_blank" style={{textDecoration: "underline"}}>InTeam</a> portal</Typography>
                        </Grid>                        
                    </Container>
                </Content>
            </PageWithHeader>
        </Fragment>
    )
};