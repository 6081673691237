import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { sentinelsApiRef, SentinelsClient } from './api';

export const sentinelsPlugin = createPlugin({
  id: 'sentinels',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: sentinelsApiRef,
      deps: { fetchApi: fetchApiRef, discoveryApi: discoveryApiRef },
      factory: ({ fetchApi, discoveryApi }) =>
        new SentinelsClient({ fetchApi, discoveryApi }),
    }),
  ],
});

export const SentinelsPage = sentinelsPlugin.provide(
  createRoutableExtension({
    name: 'SentinelsPage',
    component: () => import('./components/sentinels').then(m => m.Sentinels),
    mountPoint: rootRouteRef,
  }),
);
