import React, { Fragment } from "react";
import { PageWithHeader, Content } from '@backstage/core-components';
import { Container } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

export const Biosphere = () => {
    return (
        <Fragment>
            <PageWithHeader title="Biosphere" subtitle="Key Concepts" themeId="home">
                <Content noPadding>
                    <Container style={{ marginLeft: "auto", marginRight: "auto", paddingTop: 45, paddingBottom: 45, maxWidth: "97%" }}>
                        <Alert severity="warning" icon={false}>
                            Coming soon
                        </Alert>
                        {/* <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={2}>
                                                <ListIcon />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="h5">Table of Contents</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="row">
                                            <Button href="#1" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>1. General overview</Button>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={9} style={{ marginLeft: 50 }}>
                                <div id="1">
                                    <Typography variant="h3">1. General overview</Typography>
                                </div>
                            </Grid>
                        </Grid> */}
                    </Container>
                </Content>
            </PageWithHeader>
        </Fragment>
    )
};