import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Typography } from '@material-ui/core';

export const MuiSnackBar = ({
  snackOpen,
  onSnackBarClose,
  content,
}: {
  snackOpen: boolean;
  onSnackBarClose: () => void;
  content: string;
}) => {
  return (
    <>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={onSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={onSnackBarClose}
          severity="success"
          variant="standard"
          sx={{
            width: '100%',
            borderBottom: '4px  solid green',
            borderRight: '1px solid green',
            borderLeft: '1px solid green',
            borderTop: '1px solid green',
            backgroundColor: 'white',
            marginTop: 6.6,
            borderRadius: 'none',
          }}
        >
          <Typography>{content} successfully updated.</Typography>
        </Alert>
      </Snackbar>
    </>
  );
};
