import React, { Fragment } from "react";
import { PageWithHeader, Content } from '@backstage/core-components';
import { Grid, Typography, Container, Button, Accordion, AccordionSummary, AccordionDetails, Divider } from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const Glossary = () => {
    return (
        <Fragment>
            <PageWithHeader title="Glossary" subtitle="Key Concepts" themeId="home">
                <Content noPadding>
                    <Container style={{ marginLeft: "auto", marginRight: "auto", paddingTop: 45, paddingBottom: 45, maxWidth: "97%" }}>
                        <Grid container spacing={0}>
                            <Grid item xs={2}>
                                <Accordion defaultExpanded>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={2}>
                                                <ListIcon />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="h5">Table of Contents</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="row">
                                            <Button href="#1" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>1. General overview</Button>
                                            <Button href="#2" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>2. Some indications</Button>
                                            <Button href="#3" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>3. Common ground</Button>
                                            <Button href="#4" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>4. Defining Design System contribution processes</Button>
                                            <Button href="#5" color="primary" fullWidth style={{ justifyContent: "left", textTransform: "none", color: "#000000" }}>5. How we deal with Snowflakes</Button>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={9} style={{ marginLeft: 50 }}>
                                <div id="1">
                                    <Typography variant="h3">1. General overview</Typography>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center">
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                                Our job is to work for one and only one ecosystem, an association of elements present in an organic and functional complex.<br /><br />
                                                We can interpret these elements in this way. We can imagine OnePlatform as an ecosystem made up of different boxes, to which we will give two names: <b>Suite</b> or <b>Commercial Packages</b>.<br /><br />
                                                Inside each box we can find different folders, which we will call <b>Modules</b>, which will in turn contain different files, which we will call <b>features</b>.
                                            </Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={8} style={{ textAlign: "right" }}>
                                            <a href="/resources/images/glossary/glossary_1.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_1.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={8}>
                                            <a href="/resources/images/glossary/glossary_2.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_2.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                                The functionalities, therefore, are the basic unit of the offer. They represent specific functions/actions that the user can do in that specific section.<br /><br />
                                                One or more functions connected to each other by a common purpose form the module.<br /><br />
                                                When one or more modules are able to work autonomously, we speak of <b>Commercial Packages</b>, and when these commercial packages are very large and are designed for professional needs of the clients, we speak of <b>Commercial suites</b>.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            The two great examples of commercial suites that you all probably know are ComiC and CiC.<br /><br />
                                            In this example we show you how they have been divided into modules and how each module contains X functionality within it.<br /><br />
                                            It should also be noted that these modules are not exclusive, they do not belong to a single commercial suite, but we are designing these bricks precisely to allow the reuse of the same modules in different commercial suites or packages.
                                            </Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={8} style={{ textAlign: "right" }}>
                                            <a href="/resources/images/glossary/glossary_3.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_3.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={8}>
                                            <a href="/resources/images/glossary/glossary_4.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_4.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            Why? Because we are no longer working on static and standardized products, but we are working on a single common platform, which will allow the customer to access the various products purchased through a single access, the same for everyone.<br /><br />
                                            Furthermore, our customers may have common needs even if addressed to different markets, and to make our work efficient, the use of repeatable modules and patterns will allow us to reuse a single set of functions in different contexts and products.<br /><br />
                                            Furthermore, by changing the quantity and relationship of the modules to each other, we will be able to offer customers unique and personalized experiences starting from their needs and the roles they cover.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            In a future scenario, we will be able to create diversified commercial suites starting from the same modules, but identifying precisely those modules that respond to the individual needs of the markets we are targeting.<br /><br />
                                            It is important to underline that it is not a question of creating a unique experience, but a unique one for the single user.
                                            </Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={8} style={{ textAlign: "right" }}>
                                            <a href="/resources/images/glossary/glossary_5.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_5.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={8}>
                                            <a href="/resources/images/glossary/glossary_6.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_6.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            It will no longer be the various users that adapt  to our single product, but every slice of the market will be able to experience the diversified experiences offered by the platform, based on some criteria, including precisely the type of organisation, any purchases already made on the platform and above all, the role of the user.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            To allow this beautiful utopia, however, we must start from the inside, to review our way of working. Among us designers, we had become accustomed, even after the pandemic, to working within our teams.<br /><br />
                                            Each team worked on its own project and then, in moments of madness, we happened to turn up our noses and understand that someone was working on something that could be related to our work.
                                            </Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={8} style={{ textAlign: "right" }}>
                                            <a href="/resources/images/glossary/glossary_7.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_7.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={8}>
                                            <a href="/resources/images/glossary/glossary_8.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_8.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            So we started outlining what we were actually working on, and lo and behold, we realized that indeed, we were all working on products that somehow communicated.<br /><br />
                                            Everyone had their own team, but we understood that this team shouldn't keep information internally, but share it to allow you to create a common starting point for the various divisions.<br /><br />
                                            We therefore have the accounting team, which will have to communicate with the data analysis and deadlines team, and so on, but everyone will have to be in synergy with those transversal teams that have a balanced role on everything, i.e. the Vapor team, the of Research and the transversal Core to all products.<br /><br />
                                            We cannot say that this new organization is already 100% operational, but we are reviewing our way of working with this in mind.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div id="2" style={{ marginTop: 100 }}>
                                    <Typography variant="h3">2. Some indications</Typography>

                                    <Typography variant="body1">
                                    Starting from here, however, how could we understand how we were moving?<br /><br />
                                    We started mapping out what all these teams were doing, but this mapping, as you know, can't be finished, because we don't even know when it's going to end.<br /><br />
                                    It is only the starting point that has allowed us to sort out the very concept of OnePlatform, not just the individual products.
                                    </Typography>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            Starting from a macro-level vision, among the commercial suites, as we have already said, we can find ComiC, CiC, and even Horeca which we will map over time together with other commercial suites.
                                            </Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={8} style={{ textAlign: "right" }}>
                                            <a href="/resources/images/glossary/glossary_9.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_9.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={8}>
                                            <a href="/resources/images/glossary/glossary_10.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_10.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            In particular, ComiC will be the flagship suite for all users who will be identified as a studio, or as clients who work for other companies as well as their own.<br /><br />
                                            In particular, ComiC is the new product aimed at small studios to facilitate the accounting and tax management activities of client companies.<br /><br />
                                            It will therefore include a series of modules already included, starting from accounting, tax, deadlines, a module linked to collaboration and a section dedicated to customer data.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            What happens, however, if you want to work for your company?<br /><br />
                                            It will be offered to you as a flagship CiC or Cloud accounting suite, which will allow you to work on your company independently.<br /><br />
                                            It should be noted that the various modules are not related to the suite, but in CiC we can find the same modules present in ComiC for the management of the company's accounting.<br /><br />
                                            In this way, the collaboration between the two parties will also take place in a more intelligent way, allowing a unambiguous view from both sides.
                                            </Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={8} style={{ textAlign: "right" }}>
                                            <a href="/resources/images/glossary/glossary_11.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_11.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={8}>
                                            <a href="/resources/images/glossary/glossary_12.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_12.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            These modules, building blocks that define the suites, can however be used according to three different criteria.<br /><br />
                                            In fact, the modules can be viewed as individual commercial packages, therefore stand-alone (e.g. tax) integrated into commercial suites, as widgets that show data within the suite's pages, or even as dynamics that are not visible to the user.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            Finally, going down one last level, we arrive at the functions present in the individual modules.<br /><br />
                                            An example that we report are some of the functions of the Accounting module, which includes the management of the first note, reports, lists of assets and so on.
                                            </Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={8} style={{ textAlign: "right" }}>
                                            <a href="/resources/images/glossary/glossary_13.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_13.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={8}>
                                            <a href="/resources/images/glossary/glossary_14.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_14.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            To read the features on the map, we used expandable cards to be able to better describe the function in question.<br /><br />
                                            In this way, even the teams that have not worked strictly on that feature, will be able to have a general view and open any external links connected to the feature (e.g. Flow, Wireframe, UI).
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            Each feature was then identified using tags, which allow us to understand whether this feature is aimed only at the studio or only at the company, or if it is part of a stand-alone module and therefore can operate individually.
                                            </Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={8} style={{ textAlign: "right" }}>
                                            <a href="/resources/images/glossary/glossary_15.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_15.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={8}>
                                            <a href="/resources/images/glossary/glossary_16.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_16.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            Finally, each feature was mapped following a legend for progress, dividing the definition phase, with the design side, development and completed.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div id="3" style={{ marginTop: 100 }}>
                                    <Typography variant="h3">3. Common ground</Typography>

                                    <Typography variant="body1">
                                    So far we have talked about OnePlatform as a single ecosystem and now we talk about the individual suites as closed boxes?<br /><br />
                                    Yes, but there is a trick.<br /><br />
                                    There is, or rather there will be, a common ground for all commercial suites and packages that will allow all customers, regardless of the package purchased, to land and sail following the same guidelines.
                                    </Typography>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            In particular, the landing coincides with the login on the various products, the cover of what will be the personalized experience of our users.<br /><br />
                                            We will foresee three types of Onboarding, starting from the classic Login in case the user is already registered, through TS ID or other external providers.<br /><br />
                                            If the user is not registered, on the other hand, he can make a slightly longer registration which will allow a real customization of the product based on the data entered.<br /><br />
                                            Lastly, some products will offer a trial, free access to the purchased product for a limited period of time.<br /><br />
                                            Once the user has entered the purchased product, he can decide to move within the product, or even purchase additional products. To do this, all products will have access to the TS Shell, which will include ours
                                            </Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={8} style={{ textAlign: "right" }}>
                                            <a href="/resources/images/glossary/glossary_17.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_17.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={8}>
                                            <a href="/resources/images/glossary/glossary_18.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_18.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            MarketPlace, where you will have access to overviews of the various products both purchased and suggested, and the direct connection to the TS Store for the final purchase.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            However, the TS Shell will not only include the marketplace, but a whole series of common modules that do not refer so much to the purchased product, but to the user's role.
                                            </Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={8} style={{ textAlign: "right" }}>
                                            <a href="/resources/images/glossary/glossary_19.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_19.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={8}>
                                            <a href="/resources/images/glossary/glossary_18.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_18.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            MarketPlace, where you will have access to overviews of the various products both purchased and suggested, and the direct connection to the TS Store for the final purchase.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center" style={{ marginTop: 100 }}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1">
                                            The problem we have today, in fact, is precisely the number of Shells present on the various products, which lead us to have inconsistent and specific experiences for the product in question, which therefore lead the user to not have a standard on which to base himself.<br /><br />
                                            The future of the TS Shell, however, will be based on the standardization of items in specific portions of the shell, allowing users to easily find items. Each product, then, will have the various sections customized according to your needs such as, for example, the menu items.<br /><br />
                                            To do this, there is one team in particular that plays a fundamental role in the advancement not only of the TS Shell, but of the world of UI in particular, and it is precisely the Vapor Design Team.
                                            </Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4} style={{ textAlign: "center" }}>
                                            <a href="/resources/images/glossary/glossary_20_1.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_20_1.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                                        <Grid item xs={4} style={{ textAlign: "center" }}>
                                            <a href="/resources/images/glossary/glossary_20_2.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_20_2.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div id="4" style={{ marginTop: 100 }}>
                                    <Typography variant="h3">4. Defining Design System contribution processes</Typography>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <a href="/resources/images/glossary/glossary_21.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_21.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <a href="/resources/images/glossary/glossary_22.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_22.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <a href="/resources/images/glossary/glossary_23.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_23.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <a href="/resources/images/glossary/glossary_24.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_24.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <a href="/resources/images/glossary/glossary_25.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_25.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <a href="/resources/images/glossary/glossary_26.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_26.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <a href="/resources/images/glossary/glossary_27.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_27.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <a href="/resources/images/glossary/glossary_28.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_28.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div id="5" style={{ marginTop: 100 }}>
                                    <Typography variant="h3">5. How we deal with Snowflakes</Typography>

                                    <Grid container spacing={6} justifyContent="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <a href="/resources/images/glossary/glossary_29.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_29.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <a href="/resources/images/glossary/glossary_30.png" target="_blank">
                                                <img src="/resources/images/glossary/glossary_30.png" alt="Glossary" width="90%" />
                                            </a>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Content>
            </PageWithHeader>
        </Fragment>
    )
};