import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface Labels {
  [key: string]: string;
}

interface ScoringRadioGroupProps {
  value: number | null;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formLabel: string;
  labels: Labels;
  name: string;
  labelId: string;
}

export const ScoringRadioGroup: React.FC<ScoringRadioGroupProps> = ({
  value,
  handleChange,
  formLabel,
  labels,
  name,
  labelId,
}) => {
  return (
    <FormControl>
      <FormLabel id={labelId}>
        <b>{formLabel}</b>
      </FormLabel>
      <RadioGroup name={name} value={value} onChange={handleChange}>
        <FormControlLabel value={0} control={<Radio />} label={labels.label0} />
        <FormControlLabel value={1} control={<Radio />} label={labels.label1} />
        <FormControlLabel value={2} control={<Radio />} label={labels.label2} />
        <FormControlLabel value={3} control={<Radio />} label={labels.label3} />
        <FormControlLabel value={4} control={<Radio />} label={labels.label4} />
      </RadioGroup>
    </FormControl>
  );
};
