import { Config, readDurationFromConfig } from '@backstage/config';
import { durationToMilliseconds } from '@backstage/types';
import { DateTime } from 'luxon';

const SEVERITY_COLORS_KEY = 'nvd.severityColors';
const DETAIL_URL_KEY = 'nvd.detailUrl';
const DEFAULT_PUBLISHED_DATE_LIMIT_KEY = 'nvd.defaultPublishedDateLimit';

export type SeverityColor = {
  title?: string;
  backgroundColor?: string;
  foregroundColor?: string;
}

export type DefaultDateLimit = {
  startDate: Date,
  endDate: Date,
}

export class NvdConfig {

  private readonly config: Config;
  private readonly severityColors: SeverityColor[] = [];

  static create(config: Config): NvdConfig {
    return new NvdConfig(config);
  }

  private constructor(config: Config) {
    this.config = config;

    const severityColorsConfig = this.config.getOptionalConfigArray(SEVERITY_COLORS_KEY);
    severityColorsConfig?.map((item) => {
      this.severityColors.push({
        title: item.getOptionalString('title'),
        backgroundColor: item.getOptionalString('backgroundColor'),
        foregroundColor: item.getOptionalString('foregroundColor'),
      });
    });
  }

  readDetailUrl(): string {
    return this.config.getOptionalString(DETAIL_URL_KEY) ?? 'https://nvd.nist.gov/vuln/detail';
  }

  readSeverityColor(title: string | undefined,
  ): SeverityColor | undefined {
    return this.severityColors.find(item => item.title === title);
  }
  
  readDefaultDateLimit(): DefaultDateLimit {
    const defaultPublishedDateLimit = this.config.has(DEFAULT_PUBLISHED_DATE_LIMIT_KEY) ? readDurationFromConfig(this.config, { key: DEFAULT_PUBLISHED_DATE_LIMIT_KEY }) : { days: 7 };
    const endDate = DateTime.now();
    const startDate = endDate.minus(durationToMilliseconds(defaultPublishedDateLimit));
  
    return ({
      startDate: new Date(startDate.toFormat('yyyy-MM-dd')),
      endDate: new Date(endDate.toFormat('yyyy-MM-dd')),
    });
  }
}