import React from 'react';
import { Slider } from '@material-ui/core';
import { sliderUseStyles } from '../styles/ScoringStyles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const ScoringSlider = ({
  scoreValue,
  minValue,
  maxValue,
  isDisabled,
  sliderStyle,
}: {
  scoreValue: number;
  minValue: number;
  maxValue: number;
  isDisabled: boolean;
  sliderStyle?: CSSProperties;
}) => {
  const classes = sliderUseStyles({
    scoreValue: scoreValue ? scoreValue.toString() : '',
  });
  return scoreValue !== null ? (
    <Slider
      min={minValue}
      max={maxValue}
      disabled={isDisabled}
      classes={{
        thumb: classes.thumb,
        track: classes.track,
        rail: classes.rail,
      }}
      style={sliderStyle}
      value={Number(scoreValue)}
    />
  ) : null;
};
