import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const catalogTeamSystemComponentsPlugin = createPlugin({
  id: 'catalog-teamsystem-components',
  routes: {
    root: rootRouteRef,
  },
});

export const EntityGroupMembersList = catalogTeamSystemComponentsPlugin.provide(
  createRoutableExtension({
    name: 'CatalogTeamSystemComponentsPage',
    component: () =>
      import('./components/MemberListComponent').then(m => m.MemberListComponent),
    mountPoint: rootRouteRef,
  }),
);

export const EntityModulesOwnershipList = catalogTeamSystemComponentsPlugin.provide(
  createRoutableExtension({
    name: 'CatalogTeamSystemComponentsPage',
    component: () =>
      import('./components/ModuleListCard').then(m => m.ModuleListCard),
    mountPoint: rootRouteRef,
  }),
);