import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { m2mTokenApiRef, M2mtokenClient } from './api';

export const teamsystemM2MtokenPlugin = createPlugin({
  id: 'm2mtoken',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: m2mTokenApiRef,
      deps: { fetchApi: fetchApiRef, discoveryApi: discoveryApiRef },
      factory: ({ fetchApi, discoveryApi }) =>
        new M2mtokenClient({ fetchApi, discoveryApi }),
    }),
  ],
});

export const TeamsystemM2MtokenPage = teamsystemM2MtokenPlugin.provide(
  createRoutableExtension({
    name: 'TeamsystemM2MtokenPage',
    component: () => import('./components/m2mtoken').then(m => m.M2mtoken),
    mountPoint: rootRouteRef,
  }),
);
