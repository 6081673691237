import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  CatalogTable,
  CatalogTableColumnsFunc,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import LightIcon from '@material-ui/icons/WbSunny';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

import { microsoftAuthApiRef } from '@backstage/core-plugin-api';

import { BackstageTheme, lightTheme } from '@backstage/theme';
import {
  basicVaporTheme,
  createCustomThemeOverrides,
} from './themes/VaporThemes';

import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { QetaPage } from '@drodil/backstage-plugin-qeta';
import { AnnouncementsPage } from '@backstage-community/plugin-announcements';
import { TechnicalSupportPage } from './components/tech-support/TechnicalSupport';
import { TeamsystemM2MtokenPage } from '@internal/plugin-m2mtoken';

import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';

import { Biosphere } from './components/key-concepts/Biosphere';
import { DesignPrinciples } from './components/key-concepts/DesignPrinciples';
import { Glossary } from './components/key-concepts/Glossary';
import { OnePlatform } from './components/key-concepts/OnePlatform';
import { OneFront } from './components/key-concepts/OneFront';
import { PsByDesign } from './components/key-concepts/PsByDesign';
import { SystemArchitecture } from './components/key-concepts/SystemArchitecture';
import { Manifesto } from './components/key-concepts/Manifesto';
import { TsDigital } from './components/key-concepts/TsDigital';
import { VaporDesign } from './components/key-concepts/VaporDesign';

import { AccessibilityMainPage, AccessibilityItemPage } from '@teamsystem/backstage-plugin-accessibility';

import { OpenSourceLicences } from './components/guidelines/OpenSourceLicences';
import { PublicCloudGovernance } from './components/guidelines/PublicCloudGovernance';
import { Security } from './components/guidelines/Security';

import { ScoringPage } from '@internal/plugin-scoring';
import { ScoringDetailsPage } from '@internal/plugin-scoring';

import { GettingStarted } from './components/gettingStarted/GettingStarted';
import { SentinelsPage } from '@internal/plugin-sentinels';
import { CveIndexPage } from '@giocolas/backstage-plugin-nvd';
import { TechTalkPage } from '@internal/plugin-tech-talk';
import {
  createAPIModuleColumn,
  createComponentModuleColumn,
  createResourceModuleColumn,
  createModuleAreaColumn,
  createModuleFunctionalDomainColumn,
  createModuleLegacyColumn,
  createModuleOwnerColumn,
} from '@teamsystem/backstage-plugin-catalog-teamsystem-components';
import {
  AdoptionOverviewPage,
  AdoptionExportPage,
  AdoptionViewPage,
  AdoptionEditPage,
  AdoptionSummaryPage,
} from '@teamsystem/backstage-plugin-adoption-matrix';

const vaporTheme: BackstageTheme = {
  ...basicVaporTheme,
  ...lightTheme,
  overrides: {
    ...lightTheme.overrides,
    ...createCustomThemeOverrides(lightTheme),
  },
};

const app = createApp({
  apis,
  themes: [
    {
      id: 'vapor-theme',
      title: 'Vapor Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <ThemeProvider theme={vaporTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        provider={{
          id: 'microsoft-auth-provider',
          title: 'Microsoft',
          message: 'Sign in using microsoft',
          apiRef: microsoftAuthApiRef,
        }}
      />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const catalogColumnsFunc: CatalogTableColumnsFunc = (entityListContext) => {
  const kind = entityListContext.filters.kind?.value ?? '';

  if (kind.toLowerCase() === 'area') {
    return [
      CatalogTable.columns.createNameColumn(),
      CatalogTable.columns.createMetadataDescriptionColumn(),
    ];
  }

  if (kind.toLowerCase() === 'module') {
    return [
      createModuleAreaColumn(),
      createModuleFunctionalDomainColumn(),
      CatalogTable.columns.createNameColumn(),
      CatalogTable.columns.createMetadataDescriptionColumn(),
      createModuleOwnerColumn(),
      // CatalogTable.columns.createOwnerColumn(),
      // CatalogTable.columns.createSpecTypeColumn(),
      createModuleLegacyColumn(),
    ];
  }  

  if (kind.toLowerCase() === 'api') {
    return [
      CatalogTable.columns.createTitleColumn({ hidden: true }),
      CatalogTable.columns.createNameColumn({ defaultKind: kind }),
      createAPIModuleColumn(),
      CatalogTable.columns.createOwnerColumn(),
      CatalogTable.columns.createSpecTypeColumn(),
      CatalogTable.columns.createSpecLifecycleColumn(),
      CatalogTable.columns.createMetadataDescriptionColumn(),
      CatalogTable.columns.createTagsColumn(),
    ];
  }

  if (kind.toLowerCase() === 'component') {
    return [
      CatalogTable.columns.createTitleColumn({ hidden: true }),
      CatalogTable.columns.createNameColumn({ defaultKind: kind }),
      createComponentModuleColumn(),
      CatalogTable.columns.createOwnerColumn(),
      CatalogTable.columns.createSpecTypeColumn(),
      CatalogTable.columns.createSpecLifecycleColumn(),
      CatalogTable.columns.createMetadataDescriptionColumn(),
      CatalogTable.columns.createTagsColumn(),
    ];
  }

  if (kind.toLowerCase() === 'resource') {
    return [
      CatalogTable.columns.createTitleColumn({ hidden: true }),
      CatalogTable.columns.createNameColumn({ defaultKind: kind }),
      createResourceModuleColumn(),
      CatalogTable.columns.createOwnerColumn(),
      CatalogTable.columns.createSpecTypeColumn(),
      CatalogTable.columns.createSpecLifecycleColumn(),
      CatalogTable.columns.createMetadataDescriptionColumn(),
      CatalogTable.columns.createTagsColumn(),
    ];
  }

  return CatalogTable.defaultColumnsFunc(entityListContext);
};

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route
      path="/catalog"
      element={<CatalogIndexPage initialKind="Module" columns={catalogColumnsFunc} />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <Mermaid
          config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
        />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/qeta" element={<QetaPage title="Questions" />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/tech-support" element={<TechnicalSupportPage />} />
    <Route path="/teamsystem-m2mtoken" element={<TeamsystemM2MtokenPage />} />

    <Route
      path="/keyConcepts/systemArchitecture"
      element={<SystemArchitecture />}
    />
    <Route
      path="/keyConcepts/manifesto"
      element={<Manifesto />}
    />
    <Route
      path="/keyConcepts/designPrinciples"
      element={<DesignPrinciples />}
    />
    <Route path="/keyConcepts/glossary" element={<Glossary />} />
    <Route path="/keyConcepts/psByDesign" element={<PsByDesign />} />
    <Route path="/keyConcepts/vaporDesign" element={<VaporDesign />} />
    <Route path="/keyConcepts/biosphere" element={<Biosphere />} />
    <Route path="/keyConcepts/onePlatform" element={<OnePlatform />} />
    <Route path="/keyConcepts/oneFront" element={<OneFront />} />
    <Route path="/keyConcepts/tsDigital" element={<TsDigital />} />

    <Route path="/accessibility" element={<AccessibilityMainPage />} />
    <Route path="/accessibility/:item" element={<AccessibilityItemPage />} />

    <Route
      path="/guidelines/publicCloudGovernance"
      element={<PublicCloudGovernance />}
    />
    <Route
      path="/guidelines/openSourceLicenses"
      element={<OpenSourceLicences />}
    />
    <Route
      path="/guidelines/security"
      element={<Security />}
    />

    <Route path="/gettingStarted" element={<GettingStarted />} />
    <Route path="/sentinels" element={<SentinelsPage />} />

    <Route path="/scoring" element={<ScoringPage />} />
    <Route
      path="/scoring/softwaredetails/:softid"
      element={<ScoringDetailsPage />}
    />

    <Route path="/adoption" element={<AdoptionOverviewPage />} />
    <Route path="/adoption/export" element={<AdoptionExportPage />} />
    <Route path="/adoption/summary" element={<AdoptionSummaryPage />} />
    <Route path="/adoption/:name/view" element={<AdoptionViewPage />} />
    <Route path="/adoption/:name/edit" element={<AdoptionEditPage />} />

    <Route path="/sentinels/:id" element={<SentinelsPage />} />
    <Route path="/nvd" element={<CveIndexPage />} />
    <Route path="/tech-talk" element={<TechTalkPage />} />

  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
