import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Typography, Slider, Grid } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import Tooltip from '@mui/material/Tooltip';
import { ScoringModal } from './ScoringModal';
import { ScoringAttributeProps, AttributeData } from '../../types';
import Divider from '@material-ui/core/Divider';
import { MuiSnackBar } from '../microcomponents/MuiSnackBar';

const valueColor = (value: number | number[] | undefined) => {
  switch (value) {
    case 0:
      return 'grey';
    case 1:
      return '#D92B2C';
    case 2:
      return '#F5B9B5';
    case 3:
      return '#FDB51C';
    case 4:
      return '#097C28';
    default:
      if (value && Number(value) > 0 && Number(value) < 1) {
        return '#D92B2C';
      } else {
        return 'whitesmoke';
      }
  }
};

const ScoreSlider = styled(Slider)(({ value }) => ({
  '& .MuiSlider-thumb': {
    display: 'none',
  },
  '& .MuiSlider-track': {
    height: 15,
    borderRadius: 20,
    backgroundColor: valueColor(value),
  },
  '& .MuiSlider-rail': {
    height: 15,
    borderRadius: 20,
    backgroundColor: 'grey',
  },
}));

export const ScoringAttribute: React.FC<ScoringAttributeProps> = ({
  content,
  isTitle,
  titleId,
  attributeId,
  softId,
  titleName,
  attributeData,
  setAttributeData,
  scoreAverages,
  storeAttributeData,
  setStoreAttributeData,
  changedData,
  setChangedData,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  useEffect(() => {
    if (
      storeAttributeData &&
      softId === storeAttributeData.softId &&
      titleId === storeAttributeData.titleId &&
      attributeId === storeAttributeData.attributeId
    ) {
      setSnackOpen(true);
    } else {
      setSnackOpen(false);
    }
  }, [storeAttributeData]);

  const selectedAttributeData = attributeData.find(
    (item: { software_id: string; attribute_id: number; title_id: number }) =>
      item.software_id === softId &&
      item.title_id === titleId &&
      item.attribute_id === attributeId,
  );

  const onModalOpen = () => {
    if (selectedAttributeData) setChangedData(selectedAttributeData);
    setModalOpen(true);
  };
  const onModalClose = () => {
    if (Object.keys(changedData).length === 0) {
      setAttributeData((prevAttributeData: AttributeData[]) => {
        return prevAttributeData.filter(
          item =>
            item.software_id !== softId ||
            item.title_id !== titleId ||
            item.attribute_id !== attributeId,
        );
      });
      setModalOpen(false);
      setChangedData({} as AttributeData);
      return;
    } else {
      setAttributeData((prevAttributeData: AttributeData[]) => {
        return prevAttributeData.map(item => {
          if (
            item.software_id === changedData.software_id &&
            item.title_id === changedData.title_id &&
            item.attribute_id === changedData.attribute_id
          ) {
            return {
              ...changedData,
            };
          }
          return item;
        });
      });
      setModalOpen(false);
      setChangedData({} as AttributeData);
    }
  };
  const onSnackBarClose = () => {
    setSnackOpen(false);
  };

  const foundTitle = scoreAverages.find(
    (item: {
      software_id: string;
      titles: {
        title_id: number;
        title_asis_summary_score: number;
        title_tobe_summary_score: number;
      }[];
    }) =>
      item.software_id === softId &&
      item.titles.find(title => title.title_id === titleId),
  );

  const foundAttribute = attributeData.find(
    (item: { software_id: string; attribute_id: number; title_id: number }) =>
      item.software_id === softId &&
      item.attribute_id === attributeId &&
      item.title_id === titleId,
  );

  const foundAsIsTitleSumScore = foundTitle?.titles.find(
    (item: { title_id: number }) => item.title_id === titleId,
  )?.title_asis_summary_score;
  const foundToBeTitleSumScore = foundTitle?.titles.find(
    (item: { title_id: number }) => item.title_id === titleId,
  )?.title_tobe_summary_score;

  return (
    <>
      <Grid item xs={2} sm={2} md={4} lg={4} xl={4}>
        <Typography noWrap>{isTitle ? <b>{content}</b> : content}</Typography>
      </Grid>
      <Grid
        item
        xs={1}
        sm={1}
        md={1}
        lg={1}
        xl={1}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {isTitle && (
          <b>
            {foundTitle
              ? foundTitle.titles.find(
                  (item: { title_id: number }) => item.title_id === titleId,
                )?.title_asis_summary_score
                ? Number(
                    foundTitle.titles.find(
                      (item: { title_id: number }) => item.title_id === titleId,
                    )?.title_asis_summary_score,
                  ).toFixed(2)
                : '0.00'
              : '0.00'}
          </b>
        )}
      </Grid>
      <Grid
        item
        xs={2}
        sm={2}
        md={2}
        lg={2}
        xl={2}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <ScoreSlider
            min={0}
            max={4}
            disabled
            sx={{ display: 'flex', alignItems: 'center' }}
            value={
              isTitle
                ? foundTitle
                  ? foundTitle.titles.find(
                      (item: { title_id: number }) => item.title_id === titleId,
                    )?.title_asis_summary_score
                    ? Number(foundAsIsTitleSumScore) > 0 &&
                      Number(foundAsIsTitleSumScore) < 1
                      ? Number(foundAsIsTitleSumScore)
                      : Math.floor(Number(foundAsIsTitleSumScore))
                    : 0.0
                  : 0.0
                : foundAttribute
                ? foundAttribute.as_is_score ?? 0
                : 0.0
            }
          />
        </Stack>
      </Grid>
      <Grid
        item
        xs={1}
        sm={1}
        md={1}
        lg={1}
        xl={1}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {isTitle && (
          <b>
            {foundTitle
              ? foundTitle.titles.find(
                  (item: { title_id: number }) => item.title_id === titleId,
                )?.title_tobe_summary_score
                ? Number(
                    foundTitle.titles.find(
                      (item: { title_id: number }) => item.title_id === titleId,
                    )?.title_tobe_summary_score,
                  ).toFixed(2)
                : '0.00'
              : '0.00'}
          </b>
        )}
      </Grid>
      <Grid
        item
        xs={2}
        sm={2}
        md={2}
        lg={2}
        xl={2}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ScoreSlider
          min={0}
          max={4}
          disabled
          sx={{ display: 'flex', alignItems: 'center' }}
          value={
            isTitle
              ? foundTitle
                ? foundTitle.titles.find(
                    (item: { title_id: number }) => item.title_id === titleId,
                  )?.title_tobe_summary_score
                  ? Number(foundToBeTitleSumScore) > 0 &&
                    Number(foundToBeTitleSumScore) < 1
                    ? Number(foundToBeTitleSumScore)
                    : Math.floor(Number(foundToBeTitleSumScore))
                  : 0.0
                : 0.0
              : foundAttribute
              ? foundAttribute.to_be_score ?? 0
              : 0.0
          }
        />
      </Grid>
      <div style={{ width: '3%' }}></div>
      <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
        {!isTitle && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
          >
            {foundAttribute && !foundAttribute.is_declared ? (
              <Tooltip
                title={foundAttribute.not_declared_comment}
                placement="top"
              >
                <IconButton size="small">
                  <InfoOutlined color="primary" />
                </IconButton>
              </Tooltip>
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              ></Stack>
            )}
            <IconButton
              onClick={() => {
                onModalOpen();
              }}
              size="small"
            >
              <EditOutlined color="primary" />
            </IconButton>
          </Stack>
        )}
      </Grid>
      {!isTitle && <Divider style={{ width: '100%' }} />}
      {!isTitle && modalOpen && (
        <ScoringModal
          open={modalOpen}
          onModalClose={onModalClose}
          content={content}
          titleName={titleName}
          softId={softId}
          titleId={titleId}
          attributeId={attributeId}
          attributeData={attributeData}
          setAttributeData={setAttributeData}
          setStoreAttributeData={setStoreAttributeData}
          setSnackOpen={setSnackOpen}
          changedData={changedData}
        />
      )}
      {!isTitle && snackOpen && (
        <MuiSnackBar
          snackOpen={snackOpen}
          onSnackBarClose={onSnackBarClose}
          content={content}
        />
      )}
    </>
  );
};
