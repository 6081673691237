import { BadgeListResult } from '../types';
import { BadgesApi } from './BadgesApi';
import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';

export const badgesApiRef = createApiRef<BadgesApi>({
  id: 'plugin.badges.service',
});

export class BadgesClient implements BadgesApi {

  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { fetchApi: FetchApi; discoveryApi: DiscoveryApi; }) {
    this.fetchApi = options.fetchApi;
    this.discoveryApi = options.discoveryApi;
  }

  async getBaseUrl(): Promise<string> {
    return this.discoveryApi.getBaseUrl('badges');
  }

  async getList(email: string | undefined): Promise<BadgeListResult> {
    let url = `${await this.getBaseUrl()}/list`;
    url += `?email=${email}`;

    const response = await this.fetchApi.fetch(url);
    if (response.status === 403) {
      return {badges: []}
    }

    const data = (await response.json()) as BadgeListResult;
    return data;
  }
}