import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { adoptionMatrixApiRef, AdoptionMatrixClient } from './api';
import { adoptionRouteRef } from './routes';

export const adoptionMatrixPlugin = createPlugin({
  id: 'adoption-matrix',
  routes: {
    root: adoptionRouteRef,
  },
  apis: [
    createApiFactory({
      api: adoptionMatrixApiRef,
      deps: { fetchApi: fetchApiRef, discoveryApi: discoveryApiRef },
      factory: ({ fetchApi, discoveryApi }) =>
        new AdoptionMatrixClient({ fetchApi, discoveryApi }),
    }),
  ]
});

export const AdoptionOverviewPage = adoptionMatrixPlugin.provide(
  createRoutableExtension({
    name: 'AdoptionMatrixPage',
    component: () =>
      import('./components/AdoptionOverviewPage').then(m => m.AdoptionOverviewPage),
    mountPoint: adoptionRouteRef,
  }),
);

export const AdoptionViewPage = adoptionMatrixPlugin.provide(
  createRoutableExtension({
    name: 'AdoptionMatrixViewPage',
    component: () =>
      import('./components/AdoptionViewPage').then(m => m.AdoptionViewPage),
    mountPoint: adoptionRouteRef,
  }),
);

export const AdoptionEditPage = adoptionMatrixPlugin.provide(
  createRoutableExtension({
    name: 'AdoptionMatrixEditPage',
    component: () =>
      import('./components/AdoptionEditPage').then(m => m.AdoptionEditPage),
    mountPoint: adoptionRouteRef,
  }),
);

export const AdoptionExportPage = adoptionMatrixPlugin.provide(
  createRoutableExtension({
    name: 'AdoptionExportPage',
    component: () =>
      import('./components/AdoptionExportPage').then(m => m.AdoptionExportPage),
    mountPoint: adoptionRouteRef,
  }),
);

export const AdoptionSummaryPage = adoptionMatrixPlugin.provide(
  createRoutableExtension({
    name: 'AdoptionSummaryPage',
    component: () =>
      import('./components/AdoptionSummaryPage').then(m => m.AdoptionSummaryPage),
    mountPoint: adoptionRouteRef,
  }),
);