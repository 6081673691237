import { Cve } from '@giocolas/backstage-plugin-nvd-common';
import { createApiRef } from '@backstage/core-plugin-api';

/**
 * @public
 */
export const nvdApiRef = createApiRef<NvdApi>({
  id: 'plugin.nvd.service',
});

/**
 * @public
 */
export interface GetCvesUsingNamedParametersRequest {
  severityTypes?: string[],
  scoreMin?: number,
  scoreMax?: number,
  publishedDateFrom?: string,
  publishedDateTo?: string,
  orderColumn?: string,
  orderType?: 'asc' | 'desc',
  limit?: number
}

/**
 * @public
 */
export interface GetCvesUsingFiltersRequest {
  filter?:
    | Record<string, string | string[] | null>[]
    | Record<string, string | string[] | null>;
}

/**
 * @public
 */
export interface NvdApi {
  getSummary() : Promise<Cve[]>;
  getUsingNamedParameters(req: GetCvesUsingNamedParametersRequest): Promise<Cve[]>;
  getUsingFilters(req: GetCvesUsingFiltersRequest): Promise<Cve[]>;
}