import { useScoringApi } from '../../utils/hooks';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { SoftwareScoring } from '../../types';

export const ScoringCatalogSoftware = () => {
  const {
    value: catalogValue,
    loading: catalogLoading,
    error: catalogError,
  } = useScoringApi(api => api.getCatalog(), []);
  return {
    catalogValue,
    catalogLoading,
    catalogError,
  };
};

export const ScoringSoftwareAttributes = () => {
  const {
    value: attributeValue,
    loading: attributeLoading,
    error: attributeError,
  } = useScoringApi(api => api.getAttributes(), []);
  return {
    attributeValue,
    attributeLoading,
    attributeError,
  };
};

export const ScoringGetUser = () => {
  const identityApi = useApi(identityApiRef);
  const {
    value: userValue,
    loading: userLoading,
    error: userError,
  } = useAsync(async () => {
    return {
      profile: await identityApi.getProfileInfo(),
      identity: await identityApi.getBackstageIdentity(),
    };
  }, []);

  return {
    userValue,
    userLoading,
    userError,
  };
};

export const ScoringAddSoftwarePoints = (
  data: SoftwareScoring,
  onSaveCall: number,
) => {
  const {
    value: softwarePointsValue,
    loading: sofwarePointsLoading,
    error: softwarePointsError,
  } = useScoringApi(api => api.addScorePoints(data, onSaveCall), [onSaveCall]);
  return {
    softwarePointsValue,
    sofwarePointsLoading,
    softwarePointsError,
  };
};

export const ScoringGetSoftwarePoints = (storeAttributeData: object) => {
  const {
    value: pointsValue,
    loading: pointsLoading,
    error: pointsError,
  } = useScoringApi(
    api => api.getScoring(storeAttributeData),
    [storeAttributeData],
  );
  return {
    pointsValue,
    pointsLoading,
    pointsError,
  };
};

export const ScoringGetAttributeTitles = () => {
  const {
    value: titleValue,
    loading: titleLoading,
    error: titleError,
  } = useScoringApi(api => api.getAttributeTitles(), []);
  return {
    titleValue,
    titleLoading,
    titleError,
  };
};

export const ScoringSoftwareTitleSummary = () => {
  const {
    value: titleSummaryValue,
    loading: titleSummaryLoading,
    error: titleSummaryError,
  } = useScoringApi(api => api.getTitleSummaryScore(), []);
  return {
    titleSummaryValue,
    titleSummaryLoading,
    titleSummaryError,
  };
};

export const ScoringNewSoftwares = () => {
  const {
    value: newSoftwareValue,
    loading: newSoftwareLoading,
    error: newSoftwareError,
  } = useScoringApi(api => api.getNewSoftwares(), []);
  return {
    newSoftwareValue,
    newSoftwareLoading,
    newSoftwareError,
  };
};

export const ScoringSummaries = () => {
  const {
    value: sumValue,
    loading: sumLoading,
    error: sumError,
  } = useScoringApi(api => api.getScoringSummaries(), []);
  return {
    sumValue,
    sumLoading,
    sumError,
  };
};
