import React from 'react';
import { Grid } from '@material-ui/core';
import { Header, Page, Content } from '@backstage/core-components';
const {
  ScoringSoftwareAttributes,
  ScoringNewSoftwares,
  ScoringGetSoftwarePoints,
  ScoringSoftwareTitleSummary,
} = require('./ScoringApiClient');
import { ScoringMainPage } from './ScoringMainPage';

export const Scoring = () => {
  const { newSoftwareLoading } = ScoringNewSoftwares();
  const { attributeLoading } = ScoringSoftwareAttributes();
  const { pointsLoading } = ScoringGetSoftwarePoints();
  const { titleSummaryLoading } = ScoringSoftwareTitleSummary();

  if (
    attributeLoading ||
    pointsLoading ||
    newSoftwareLoading ||
    titleSummaryLoading
  ) {
    return <p>Loading...</p>;
  }

  return (
    <Page themeId="tool">
      <Header title="Service Excellence Maturity Model" subtitle=""></Header>
      <Content>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12}>
            <ScoringMainPage />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
