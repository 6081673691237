import { TechTalkApi } from './TechTalkApi';
import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { ServiceUnavailableError } from '@backstage/errors';
import {
  ApiResponseGetTable,
  PermmisionResponseApi,
  UpdateTechTalkApiResponse,
  InsertTechtalkApiResponse,
  InsertTechTalkParameter,
  UpdateTechTalkParameter,
} from '../types/index';

export const techTalkApiRef = createApiRef<TechTalkApi>({
  id: 'plugin.tech-talk.service',
});

export class TechTalkClient implements TechTalkApi {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { fetchApi: FetchApi; discoveryApi: DiscoveryApi }) {
    this.fetchApi = options.fetchApi;
    this.discoveryApi = options.discoveryApi;
  }

  async getBaseUrl(): Promise<string> {
    return this.discoveryApi.getBaseUrl('tech-talk');
  }

  async getTable(): Promise<ApiResponseGetTable> {
    const url = `${await this.getBaseUrl()}/getTable`;
    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 400) {
      throw new ServiceUnavailableError();
    }

    const data = (await response.json()) as ApiResponseGetTable;
    return data;
  }

  async deleteTechTalk(id: string): Promise<{ message: string }> {
    if (id) {
      const url = `${await this.getBaseUrl()}/${id}`;
      const response = await this.fetchApi.fetch(url, {
        method: 'Delete',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 400) {
        throw new ServiceUnavailableError();
      }

      const data = (await response.json()) as { message: string };
      return data;
    }
    throw new Error('ID is not provided');
  }

  async insertTechTalk({
    topic,
    date,
    link,
  }: InsertTechTalkParameter): Promise<InsertTechtalkApiResponse> {
    if (topic && date && link) {
      const url = `${await this.getBaseUrl()}/insert`;
      const response = await this.fetchApi.fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topic, date, link }),
      });
      if (response.status === 400) {
        throw new ServiceUnavailableError();
      }

      const data = (await response.json()) as InsertTechtalkApiResponse;
      return data;
    }
    throw new Error('Please add all fields');
  }

  async updateTechTalk({
    topic,
    date,
    link,
    id,
  }: UpdateTechTalkParameter): Promise<UpdateTechTalkApiResponse> {
    if (topic || date || link) {
      const url = `${await this.getBaseUrl()}/update/${id}`;
      const response = await this.fetchApi.fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topic, date, link }),
      });
      if (response.status === 400) {
        throw new ServiceUnavailableError();
      }

      const data = (await response.json()) as UpdateTechTalkApiResponse;
      return data;
    }
    throw new Error('Please add a field');
  }

  async permissions(user: { user: string }): Promise<PermmisionResponseApi> {
    if (user.user) {
      const url = `${await this.getBaseUrl()}/permissions`;
      const response = await this.fetchApi.fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });
      if (response.status === 400) {
        throw new ServiceUnavailableError();
      }

      const data = (await response.json()) as PermmisionResponseApi;
      return data;
    }
    throw new Error('Please add the email');
  }
}
