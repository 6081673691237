import { createApiRef } from '@backstage/core-plugin-api';

/**
 * @public
 */
export const adoptionMatrixApiRef = createApiRef<AdoptionMatrixApi>({
  id: 'plugin.adoption-matrix.service',
});

export type Title = {
  id: number;
  title_name: string;
  display_order: number;
}

export type AdoptionMatrixDetail = {
  title_id: number,
  title_name: string,
  display_order: number,
  status: number,
  deadline: number,
  note?: string,
}

export type AdoptionMatrix = {
  area_name: string,
  area_title: string,
  subarea_name: string,
  subarea_title: string,
  module_name: string,
  module_title: string,
  owner: string,
  eng_manager: string;
  super_po: string;
  legacy: boolean,
  type: string,
  adoption: AdoptionMatrixDetail[],
}

export type AdoptionMatrixUpdateDetail = {
  title_id: number,
  status: number,
  deadline: number,
  note?: string,
};

export type AdoptionMatrixUpdate = {
  area_name: string,
  subarea_name: string,
  module_name: string,
  adoption: AdoptionMatrixUpdateDetail[],
};

export type AdoptionMatrixRequestOptions = {
  type?: string;
  excludeLegacy?: boolean;
  areas?: string[];
  subareas?: string[];
  owners?: string[];
  engManagers?: string[];
  superPOs?: string[];
};

export type AdoptionMatrixSummary = {
  title_id: number,
  title_name: string,
  status: number,
  count: number,
};

export interface AdoptionMatrixApi {
  getTitles(): Promise<Title[]>;
  getAdoptions(props: AdoptionMatrixRequestOptions): Promise<AdoptionMatrix[]>;
  getAdoption(name: string): Promise<AdoptionMatrix>;
  postAdoption(newAdoptionMatrix: AdoptionMatrix): Promise<boolean>;
  getSummary(props: AdoptionMatrixRequestOptions): Promise<AdoptionMatrixSummary[]>;
}