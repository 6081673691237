import React from 'react';
import {
  CatalogTableRow,
} from '@backstage/plugin-catalog';
import {
  TableColumn,
} from '@backstage/core-components';
import { EntityRefLink, EntityRefLinks, humanizeEntityRef } from '@backstage/plugin-catalog-react';
import { ApiEntityV1, ComponentEntityV1, ResourceEntityV1, ModuleEntityV1 } from '@teamsystem/backstage-plugin-teamsystem-kinds-common';
import CheckIcon from '@material-ui/icons/Check';
import { DEFAULT_NAMESPACE, Entity } from '@backstage/catalog-model';

export const createAPIModuleColumn = (): TableColumn<CatalogTableRow> => {
  return {
    title: 'Module',
    field: 'entity.spec.module',
    render: ({ entity }) => {
      const api = entity as ApiEntityV1;
      if (!api.spec.module) {
        return (<></>);
      }
      const moduleRef = 'module:'.concat(entity.metadata?.namespace || DEFAULT_NAMESPACE).concat('/').concat(api.spec.module);
      return (<EntityRefLink entityRef={moduleRef} defaultKind="module" />);
    },
  };
};

export const createComponentModuleColumn = (): TableColumn<CatalogTableRow> => {
  return {
    title: 'Module',
    field: 'entity.spec.module',
    render: ({ entity }) => {
      const component = entity as ComponentEntityV1;
      if (!component.spec.module) {
        return (<></>);
      }
      const moduleRef = 'module:'.concat(entity.metadata?.namespace || DEFAULT_NAMESPACE).concat('/').concat(component.spec.module);
      return (<EntityRefLink entityRef={moduleRef} defaultKind="module" />);
    },
  };
};

export const createResourceModuleColumn = (): TableColumn<CatalogTableRow> => {
  return {
    title: 'Module',
    field: 'entity.spec.module',
    render: ({ entity }) => {
      const resource = entity as ResourceEntityV1;
      if (!resource.spec.module) {
        return (<></>);
      }
      const moduleRef = 'module:'.concat(entity.metadata?.namespace || DEFAULT_NAMESPACE).concat('/').concat(resource.spec.module);
      return (<EntityRefLink entityRef={moduleRef} defaultKind="module" />);
    },
  };
};

export const createModuleAreaColumn = (): TableColumn<CatalogTableRow> => {
  const formatContent = (entity: Entity): string => {
    return (
      entity.metadata?.title || humanizeEntityRef(entity)
    );
  };

  return {
    title: 'Area',
    field: 'entity.spec.area',
    width: 'auto',
    customSort({ entity: entity1 }, { entity: entity2 }) {
      return formatContent(entity1).localeCompare(formatContent(entity2));
    },
    render: ({ entity }) => {
      const module = entity as ModuleEntityV1;
      if (!module.spec.area) {
        return (<></>);
      }
      const moduleRef = 'area:'.concat(entity.metadata?.namespace || DEFAULT_NAMESPACE).concat('/').concat(module.spec.area);
      return (<EntityRefLink entityRef={moduleRef} defaultKind="area" />);
    },
  };
};

export const createModuleFunctionalDomainColumn = (): TableColumn<CatalogTableRow> => {
  function formatContent(entity: Entity): string {
    return (
      entity.metadata?.title ||
        humanizeEntityRef(entity, {
          defaultKind: 'area',
        })
    );
  };

  return {
    title: 'Functional Domain',
    field: 'entity.spec.subarea',
    width: 'auto',
    customSort({ entity: entity1 }, { entity: entity2 }) {
      return formatContent(entity1).localeCompare(formatContent(entity2));
    },
    render: ({ entity }) => {
      const module = entity as ModuleEntityV1;
      if (!module.spec.subarea) {
        return (<></>);
      }
      const moduleRef = 'area:'.concat(entity.metadata?.namespace || DEFAULT_NAMESPACE).concat('/').concat(module.spec.subarea);
      return (<EntityRefLink entityRef={moduleRef} defaultKind="area" />);
    },
  };
};

export const createModuleOwnerColumn = (): TableColumn<CatalogTableRow> => {
  return {
    title: 'Team',
    field: 'resolved.ownedByRelationsTitle',
    render: ({ resolved }) => {
      return (<EntityRefLinks
        entityRefs={resolved.ownedByRelations}
        defaultKind="group"
      />);
    },
  };
}

export const createModuleLegacyColumn = (): TableColumn<CatalogTableRow> => {
  return {
    title: 'Legacy',
    field: 'entity.spec.legacy',
    width: 'auto',
    render: ({ entity }) => {
      const module = entity as ModuleEntityV1;
      return (module.spec.legacy ? <CheckIcon /> : <></>);
    },
  };
};