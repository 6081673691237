import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Button, Divider } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import { MuiTextarea } from '../microcomponents/MuiTextarea';
import { AttributeData, ScoringModalProps } from '../../types';
import { ScoringRadioGroup } from './ScoringRadioGroup';
import {
  ScoringModalBoxStyle,
  ModalDividerStyle,
} from '../styles/ScoringStyles';

export const ScoringModal: React.FC<ScoringModalProps> = ({
  open,
  onModalClose,
  content,
  titleName,
  softId,
  titleId,
  attributeId,
  attributeData,
  setAttributeData,
  setStoreAttributeData,
  setSnackOpen,
}) => {
  const foundAttribute = attributeData.find(
    (item: { software_id: string; attribute_id: number; title_id: number }) =>
      item.software_id === softId &&
      item.title_id === titleId &&
      item.attribute_id === attributeId,
  );

  const updatedObjectIndex = attributeData.findIndex(
    (item: { software_id: string; attribute_id: number; title_id: number }) =>
      item.software_id === softId &&
      item.title_id === titleId &&
      item.attribute_id === attributeId,
  );

  const onCancelButtonClick = () => {
    setSnackOpen(false);
    onModalClose();
  };

  const onSaveClick = () => {
    const updatedAttributeData = attributeData[updatedObjectIndex];
    if (updatedObjectIndex !== -1) {
      setStoreAttributeData({
        softId: updatedAttributeData.software_id,
        titleId: updatedAttributeData.title_id,
        attributeId: updatedAttributeData.attribute_id,
        asIsScore: updatedAttributeData.as_is_score,
        toBeScore: updatedAttributeData.to_be_score,
        isDeclared:
          updatedAttributeData.as_is_score === 0 &&
          updatedAttributeData.to_be_score === 0
            ? false
            : true,
        commentField: updatedAttributeData.not_declared_comment
          ? updatedAttributeData.not_declared_comment
          : '',
      });
    } else {
      setStoreAttributeData({
        softId: updatedAttributeData.software_id,
        titleId: updatedAttributeData.title_id,
        attributeId: updatedAttributeData.attribute_id,
        asIsScore: updatedAttributeData.as_is_score,
        toBeScore: updatedAttributeData.to_be_score,
        isDeclared:
          updatedAttributeData.as_is_score === 0 &&
          updatedAttributeData.to_be_score === 0
            ? false
            : true,
        commentField: updatedAttributeData.not_declared_comment
          ? updatedAttributeData.not_declared_comment
          : '',
      });
    }

    onModalClose();
  };

  const handleAsIsScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedAttributeData: AttributeData[] = attributeData.map(
      (item: AttributeData) => {
        if (
          item.software_id === softId &&
          item.title_id === titleId &&
          item.attribute_id === attributeId
        ) {
          return {
            ...item,
            as_is_score: parseInt(e.target.value),
            not_declared_comment:
              parseInt(e.target.value) === 0 && item.to_be_score === 0
                ? item.not_declared_comment
                : '',
          };
        }
        return item;
      },
    );

    const existingAttribute = updatedAttributeData.find(
      (item: { software_id: string; attribute_id: number; title_id: number }) =>
        item.software_id === softId &&
        item.title_id === titleId &&
        item.attribute_id === attributeId,
    );
    const toBeScore = existingAttribute
      ? existingAttribute.to_be_score
        ? existingAttribute.to_be_score
        : null
      : null;
    const isAsIsDeclared = existingAttribute
      ? existingAttribute.as_is_score === 0 &&
        existingAttribute.to_be_score === 0
        ? false
        : true
      : true;
    const asIsNotDeclaredComment =
      parseInt(e.target.value) === 0 && foundAttribute?.to_be_score === 0
        ? existingAttribute
          ? existingAttribute.not_declared_comment !== '' ||
            existingAttribute.not_declared_comment !== undefined
            ? existingAttribute.not_declared_comment
            : ''
          : ''
        : '';

    if (!existingAttribute) {
      updatedAttributeData.push({
        software_id: softId,
        title_id: titleId,
        attribute_id: attributeId,
        as_is_score: parseInt(e.target.value),
        to_be_score: toBeScore,
        changed_at: null,
        is_declared: isAsIsDeclared,
        not_declared_comment: asIsNotDeclaredComment,
      });
    }
    setAttributeData(updatedAttributeData);
  };

  const handleToBeScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedAttributeData: AttributeData[] = attributeData.map(
      (item: AttributeData) => {
        if (
          item.software_id === softId &&
          item.title_id === titleId &&
          item.attribute_id === attributeId
        ) {
          return {
            ...item,
            to_be_score: parseInt(e.target.value),
            not_declared_comment:
              parseInt(e.target.value) === 0 && item.as_is_score === 0
                ? item.not_declared_comment
                : '',
          };
        }
        return item;
      },
    );
    const existingAttribute = updatedAttributeData.find(
      (item: { software_id: string; attribute_id: number; title_id: number }) =>
        item.software_id === softId &&
        item.title_id === titleId &&
        item.attribute_id === attributeId,
    );
    const asIsScore = existingAttribute
      ? existingAttribute.as_is_score
        ? existingAttribute.as_is_score
        : null
      : null;
    const isToBeDeclared = existingAttribute
      ? existingAttribute.as_is_score === 0 &&
        existingAttribute.to_be_score === 0
        ? false
        : true
      : true;
    const toBeNotDeclaredComment =
      parseInt(e.target.value) === 0 && foundAttribute?.as_is_score === 0
        ? existingAttribute
          ? existingAttribute.not_declared_comment !== '' ||
            existingAttribute.not_declared_comment !== undefined
            ? existingAttribute.not_declared_comment
            : ''
          : ''
        : '';

    if (!existingAttribute) {
      updatedAttributeData.push({
        software_id: softId,
        title_id: titleId,
        attribute_id: attributeId,
        as_is_score: asIsScore,
        to_be_score: parseInt(e.target.value),
        changed_at: null,
        is_declared: isToBeDeclared,
        not_declared_comment: toBeNotDeclaredComment,
      });
    }
    setAttributeData(updatedAttributeData);
  };

  const onCommentChange = (newComment: string) => {
    setAttributeData((prevData: any) =>
      prevData.map(
        (item: {
          software_id: string;
          title_id: number;
          attribute_id: number;
        }) =>
          item.software_id === softId &&
          item.title_id === titleId &&
          item.attribute_id === attributeId
            ? { ...item, not_declared_comment: newComment }
            : item,
      ),
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: { backgroundColor: '#0050754D' },
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={ScoringModalBoxStyle}>
            <Typography
              variant="h4"
              color="#004E75"
              fontFamily="Cairo"
              padding={3}
            >
              Status update
            </Typography>
            <Divider style={ModalDividerStyle} />
            <Grid
              container
              direction="row"
              paddingLeft={3}
              paddingTop={3}
              paddingRight={3}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="h6" fontFamily="Roboto">
                  {titleName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" fontFamily="Roboto">
                  {content}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                lg={6}
                xl={6}
                display="flex"
                paddingTop={1}
              >
                <ScoringRadioGroup
                  handleChange={handleAsIsScoreChange}
                  formLabel="AS IS"
                  labels={{
                    label0: '0 - N/A',
                    label1: '1 - Not Adopted',
                    label2: '2 - In definition/verify',
                    label3: '3 - Partially adopted',
                    label4: '4 - Adopted',
                  }}
                  name="asisscore"
                  labelId="asis"
                  value={foundAttribute ? foundAttribute.as_is_score : null}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={6} xl={6} display="flex">
                <ScoringRadioGroup
                  value={foundAttribute ? foundAttribute.to_be_score : null}
                  handleChange={handleToBeScoreChange}
                  formLabel="TO BE"
                  labels={{
                    label0: '0 - N/A',
                    label1: '1 - To Adopt',
                    label2: '2 - To define/verify',
                    label3: '3 - To adopt partially',
                    label4: '4 - To adopt',
                  }}
                  name="tobescore"
                  labelId="tobe"
                />
              </Grid>
              {foundAttribute &&
                foundAttribute.as_is_score === 0 &&
                foundAttribute.to_be_score === 0 && (
                  <Grid container paddingTop={2}>
                    <Box sx={{ maxWidth: '100%', width: '100%' }}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Why is not adopted?
                        </Typography>
                      </Grid>
                    </Box>
                    <Grid item xs={12}>
                      <MuiTextarea
                        value={
                          foundAttribute
                            ? foundAttribute.not_declared_comment
                            : ''
                        }
                        handleChange={onCommentChange}
                      />
                    </Grid>
                  </Grid>
                )}
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                sx={{ backgroundColor: '#F2F6F8', padding: '12px' }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: '12px', fontFamily: 'Roboto' }}
                  onClick={() => onCancelButtonClick()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    foundAttribute
                      ? foundAttribute.as_is_score === 0 &&
                        foundAttribute.to_be_score === 0 &&
                        foundAttribute.not_declared_comment === ''
                        ? true
                        : false
                      : false
                  }
                  onClick={() => onSaveClick()}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
