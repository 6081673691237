import React, { Fragment } from 'react';
import {
  // Box,
  Button,
  Grid,
  Typography,
  // makeStyles,
} from '@material-ui/core';
// import citymapImage from './citymap.png';
// import { saveAs } from 'file-saver';

// const tsInCloudModules = [
//   'Fatture In Cloud',
//   'Commercialisti In Cloud',
//   'Hotel In Cloud',
//   'Condomini In Cloud',
//   'Cassa In Cloud',
//   'Wellness In Cloud',
//   'Associazioni In Cloud',
//   'Sportivi In Cloud',
//   'Netlex In Cloud',
// ];

// const tsStudioCloudModules = [
//   'TS Studio',
//   'TS Azienda',
// ];

// const tsMidMarketCloudModules = [
//   'TS Enterprise',
//   'TSE Construction',
//   'TSE Fashion',
//   'TSE Industry 4.0',
//   'TSE Wine&Oil',
//   'TSE Waste',
// ];

// const financeModules = [
//   'Asset Mgmt.("Cespiti")',
//   'Treasury',
//   'Accounting',
//   'Balance Sheet',
//   'Real Estate',
//   'Accounts P&R',
// ];

// const taxModules = [
//   'Withholding Tax (770)',
//   'Tax fillings',
//   'Tax Compliance'
// ];

// const complianceModules = [
//   'AML',
//   'Privacy',
// ];

// const scmModules = [
//   'Agents',
//   'Billing',
//   'Supply Chain Mgmt.',
//   'Price lists',
//   'Warehouse',
// ];

// const manufacturingModules = [
//   'MES',
//   'CRP',
// ];

// const hrServicesModules = [
//   'HCM',
//   'Payroll',
//   'Timesheet',
//   'BPaaS',
//   'Welfare',
//   'Health & Safety',
// ];

// const marketingModules = [
//   'E-Commerce',
//   'Marketing automation',
//   'CRM',
// ];

// const identifyModules = [
//   'E-Sign',
//   'E-Archiving',
//   'Contract Manager',
//   'E-Identity',
//   'PEC Manager',
// ];

// const finTechModules = [
//   'Payment',
//   'Invoice & Fiscal Trading',
//   'E-Lending',
//   'Rating',
//   'ESG Rating',
//   'Banklink',
// ];

// const eDocumentsModules = [
//   'E-Invoicing',
//   'Document management',
//   'Telematici',
//   'Collaboration',
// ];

// const integrationModules = [
//   'Data Streaming (Hermes)',
//   'API Gateway',
//   'Integration HUB',
// ];

// const dataCloudPlatformModules = [
//   'Data Lake',
//   'Data Processing',
//   'Data Understanding',
//   'Data Orchestration',
//   'Data Observability',
//   'Data Retention',
// ];

// const coreServicesFoundationModules = [
//   'Company registry',
//   'IAM (TS-ID)',
//   'Workspace',
//   'Policy Manager',
//   'Connections',
//   'Notification center',
// ];

// const coreServicesExperienceModules = [
//   'Application Frame',
//   'Design System (Vapor)',
//   'Customization (Launchpad)',
//   'Smart Components',
//   'Customization (Powerboard)',
//   'Session Management',
// ];

// const coreServicesMonetizationModules = [
//   'Licensing',
//   'Customer Usage Metering',
//   'Engagement in SW',
// ];

// const coreServicesAIandDataModules = [
//   'Copilot',
//   'Analytics',
//   'Insights',
//   'Business Intelligence',
//   'Document Storage',
// ];

// const infrastructureMultiCloudModules = [
//   'AWS / Azure / GCP',
//   'Kubernetes',
//   'Storage',
//   'IaaS',
//   'Disaster Recovery',
//   'Security',
// ];

// const devAndDeployBiosphereModules = [
//   'Observability (Helk)',
//   'SW lifecycle',
//   'Copilot',
//   'Dev Portal\n(service catalog)',
//   'Source Control',
//   'Deployment',
//   'Infra as Code\n(Terraform)',
//   'Code Security',
// ];

// const useStyles = makeStyles({
//   cityMapContainer: {
//     padding: 0,
//   },
//   commercialOffering: {
//     backgroundColor: '#70c0eb',
//     padding: 5,
//   },
//   commercialOfferingItems: {
//     color: 'white',
//     backgroundColor: '#99d2f1',
//     borderRadius: 3,
//   },
//   businessCapabilities: {
//     backgroundColor: '#209eda',
//     padding: 5,
//   },
//   businessCapabilitiesItems: {
//     color: 'white',
//     backgroundColor: '#88ceec',
//     borderRadius: 3,
//   },
//   integration: {
//     backgroundColor: '#164491',
//     padding: 5,
//   },
//   dataCloudPlatform: {
//     backgroundColor: '#e72e72',
//     padding: 5,
//   },
//   coreServices: {
//     backgroundColor: '#c0096b',
//     padding: 5,
//   },
//   coreServicesItems: {
//     color: 'white',
//     backgroundColor: '#d25296',
//     borderRadius: 3,
//   },
//   infrastructureMultiCloud: {
//     backgroundColor: '#ec694a',
//     padding: 5,
//   },
//   devDeployBiosphere: {
//     backgroundColor: '#f8b133',
//     padding: 5,
//   },
//   boxContainer: {
//     display: 'flex',
//   },
//   boxTitle: {
//     width: '180px',
//   },
//   title: {
//     color: 'white',
//     fontWeight: 'bold',
//     paddingLeft: '15px',
//   },
//   subtitle: {
//     color: 'white',
//     fontWeight: 'bold',
//   },
//   module: {
//     color: 'black',
//     backgroundColor: 'white',
//     width: '100%',
//     height: '100%',
//     maxHeight: '30px',
//     borderRadius: 3,
//     alignContent: 'center',
//     justifyItems: 'center',
//   },
// });

export const Citymap = () => {
  // const classes = useStyles();

  // const saveImage = () => {
  //   saveAs(citymapImage, 'citymap.png');
  // };

  return (
    <Fragment>
      <Grid container direction='row' spacing={1} style={{ marginBottom: 10 }}>
        <Grid item>
          <Typography variant='h4'>TeamSystem Citymap - "1 platform, 3 suites, many capabilities"</Typography>
        </Grid>
        <Grid item>
          <Button variant='contained' color='primary' onClick={() => {
            window.open(
              'https://tsspa.sharepoint.com/:p:/r/sites/G365-ts-rnd/Documenti%20condivisi/General/Citymap_Ufficiale%20per%20Dev%20Portal.pptx?d=w3d162d4afd98492d9cd8cb72ab287c62&csf=1&web=1&e=QdxXSG',
              '_blank',
              'noreferrer',
            );
          }}>
            Download
          </Button>
        </Grid>
      </Grid>
      {/* <Grid container className={classes.cityMapContainer}>
        <Grid item xs={12} className={classes.commercialOffering}>
          <Box className={classes.boxContainer}>
            <Box className={classes.boxTitle}>
              <Typography className={classes.title}>
                COMMERCIAL<br/>OFFERING
              </Typography>
            </Box>
            <Grid container direction='row' spacing={1}>
              <Grid item xs={1}>
                <Box sx={{ height: '100%', alignContent: 'center', justifyItems: 'center' }} className={classes.commercialOfferingItems}>
                  <Typography variant='body1' className={classes.subtitle}>Suites</Typography>
                </Box>
              </Grid>
              <Grid item xs={11}>
                <Grid container direction='row' spacing={1}>
                  <Grid item xs={5}>
                    <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.commercialOfferingItems}>
                      <Typography variant='body1' className={classes.subtitle}>TS in Cloud</Typography>
                      <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                        {tsInCloudModules.map((item) => (
                          <Grid item xs={4}>
                            <Box className={classes.module}>
                              <Typography variant='body2'>{item}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.commercialOfferingItems}>
                      <Typography variant='body1' className={classes.subtitle}>TS Studio Cloud</Typography>
                      <Grid container direction='column' spacing={1} alignItems='stretch' style={{ padding: 5 }}>
                        {tsStudioCloudModules.map((item) => (
                          <Grid item xs={12}>
                            <Box className={classes.module}>
                              <Typography variant='body2'>{item}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.commercialOfferingItems}>
                      <Typography variant='body1' className={classes.subtitle}>TS Mid-Market Cloud</Typography>
                      <Grid container direction='row' spacing={1} alignItems='stretch' style={{ padding: 5 }}>
                        {tsMidMarketCloudModules.map((item) => (
                            <Grid item xs={4}>
                              <Box className={classes.module}>
                                <Typography variant='body2'>{item}</Typography>
                              </Box>
                            </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <Box sx={{ height: '100%', alignContent: 'center', justifyItems: 'center' }} className={classes.commercialOfferingItems}>
                  <Typography variant='body1' className={classes.subtitle}>Horizontal</Typography>
                </Box>
              </Grid>
              <Grid item xs={11}>
                <Grid container direction='row' spacing={1}>
                  <Grid item xs={3}>
                    <Box sx={{ height: '100%', alignContent: 'center', justifyItems: 'center' }} className={classes.commercialOfferingItems}>
                      <Typography variant='body1' className={classes.subtitle}>TS HR</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box sx={{ height: '100%', alignContent: 'center', justifyItems: 'center' }} className={classes.commercialOfferingItems}>
                      <Typography variant='body1' className={classes.subtitle}>TS Digital Commerce</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box sx={{ height: '100%', alignContent: 'center', justifyItems: 'center' }} className={classes.commercialOfferingItems}>
                      <Typography variant='body1' className={classes.subtitle}>TS Trust Services</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box sx={{ height: '100%', alignContent: 'center', justifyItems: 'center' }} className={classes.commercialOfferingItems}>
                      <Typography variant='body1' className={classes.subtitle}>TS Digital Finance</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.businessCapabilities}>
          <Box className={classes.boxContainer}>
            <Box className={classes.boxTitle}>
              <Typography className={classes.title}>
                BUSINESS<br/>CAPABILITIES
              </Typography>
            </Box>
            <Grid container direction='row' spacing={1}>
              <Grid item xs={12}>
                <Grid container direction='row' spacing={1}>
                  <Grid item xs>
                    <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.businessCapabilitiesItems}>
                      <Typography variant='body1' className={classes.subtitle}>Finance</Typography>
                      <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                        {financeModules.map((item) => (
                          <Grid item xs={12}>
                            <Box className={classes.module}>
                              <Typography variant='body2'>{item}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Grid container direction='column' spacing={1}>
                      <Grid item xs>
                        <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.businessCapabilitiesItems}>
                          <Typography variant='body1' className={classes.subtitle}>Tax</Typography>
                          <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                            {taxModules.map((item) => (
                              <Grid item xs={12}>
                                <Box className={classes.module}>
                                  <Typography variant='body2'>{item}</Typography>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs>
                        <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.businessCapabilitiesItems}>
                          <Typography variant='body1' className={classes.subtitle}>Compliance</Typography>
                          <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                            {complianceModules.map((item) => (
                              <Grid item xs={12}>
                                <Box className={classes.module}>
                                  <Typography variant='body2'>{item}</Typography>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.businessCapabilitiesItems}>
                      <Typography variant='body1' className={classes.subtitle}>SCM</Typography>
                      <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                        {scmModules.map((item) => (
                          <Grid item xs={12}>
                            <Box className={classes.module}>
                              <Typography variant='body2'>{item}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.businessCapabilitiesItems}>
                      <Typography variant='body1' className={classes.subtitle}>Manufacturing</Typography>
                      <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                        {manufacturingModules.map((item) => (
                          <Grid item xs={12}>
                            <Box className={classes.module}>
                              <Typography variant='body2'>{item}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.businessCapabilitiesItems}>
                      <Typography variant='body1' className={classes.subtitle}>HR services</Typography>
                      <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                        {hrServicesModules.map((item) => (
                          <Grid item xs={12}>
                            <Box className={classes.module}>
                              <Typography variant='body2'>{item}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.businessCapabilitiesItems}>
                      <Typography variant='body1' className={classes.subtitle}>Marketing</Typography>
                      <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                        {marketingModules.map((item) => (
                          <Grid item xs={12}>
                            <Box className={classes.module}>
                              <Typography variant='body2'>{item}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.businessCapabilitiesItems}>
                      <Typography variant='body1' className={classes.subtitle}>Identity</Typography>
                      <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                        {identifyModules.map((item) => (
                          <Grid item xs={12}>
                            <Box className={classes.module}>
                              <Typography variant='body2'>{item}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.businessCapabilitiesItems}>
                      <Typography variant='body1' className={classes.subtitle}>FinTech</Typography>
                      <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                        {finTechModules.map((item) => (
                          <Grid item xs={12}>
                            <Box className={classes.module}>
                              <Typography variant='body2'>{item}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ height: '100%', alignItems: 'center', display: 'flex', flexDirection: 'row' }} className={classes.businessCapabilitiesItems}>
                  <Typography variant='body1' className={classes.subtitle} style={{ marginLeft: 8 }}>eDocuments</Typography>
                  <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                    {eDocumentsModules.map((item) => (
                      <Grid item xs>
                        <Box className={classes.module}>
                          <Typography variant='body2'>{item}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.integration}>
          <Box className={classes.boxContainer}>
            <Box className={classes.boxTitle}>
              <Typography className={classes.title}>
                INTEGRATION
              </Typography>
            </Box>
            <Grid container direction='row' spacing={1}>
              {integrationModules.map((item) => (
                <Grid item xs>
                  <Box className={classes.module}>
                    <Typography variant='body2'>{item}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.dataCloudPlatform}>
          <Box className={classes.boxContainer}>
            <Box className={classes.boxTitle}>
              <Typography className={classes.title}>
                DATA CLOUD<br/>PLATFORM
              </Typography>
            </Box>
            <Grid container direction='row' spacing={1}>
              {dataCloudPlatformModules.map((item) => (
                <Grid item xs>
                  <Box className={classes.module}>
                    <Typography variant='body2'>{item}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.coreServices}>
          <Box className={classes.boxContainer}>
            <Box className={classes.boxTitle}>
              <Typography className={classes.title}>
                CORE<br/>SERVICES
              </Typography>
            </Box>
            <Grid container direction='row' spacing={1}>
              <Grid item xs>
                <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.coreServicesItems}>
                  <Typography variant='body1' className={classes.subtitle}>Foundation</Typography>
                  <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                    {coreServicesFoundationModules.map((item) => (
                      <Grid item xs={6}>
                        <Box className={classes.module}>
                          <Typography variant='body2'>{item}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs>
                <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.coreServicesItems}>
                  <Typography variant='body1' className={classes.subtitle}>Experience</Typography>
                  <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                    {coreServicesExperienceModules.map((item) => (
                      <Grid item xs={6}>
                        <Box className={classes.module}>
                          <Typography variant='body2'>{item}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.coreServicesItems}>
                  <Typography variant='body1' className={classes.subtitle}>Monetization</Typography>
                  <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                    {coreServicesMonetizationModules.map((item) => (
                      <Grid item xs={12}>
                        <Box className={classes.module}>
                          <Typography variant='body2'>{item}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs>
                <Box sx={{ height: '100%', justifyItems: 'center' }} className={classes.coreServicesItems}>
                  <Typography variant='body1' className={classes.subtitle}>AI & Data</Typography>
                  <Grid container direction='row' spacing={1} style={{ padding: 5 }}>
                    {coreServicesAIandDataModules.map((item) => (
                      <Grid item xs={6}>
                        <Box className={classes.module}>
                          <Typography variant='body2'>{item}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.infrastructureMultiCloud}>
          <Box className={classes.boxContainer}>
            <Box className={classes.boxTitle}>
              <Typography className={classes.title}>
                INFRASTRUCTURE:<br/>MULTICLOUD
              </Typography>
            </Box>
            <Grid container direction='row' spacing={1}>
              {infrastructureMultiCloudModules.map((item) => (
                <Grid item xs>
                  <Box className={classes.module}>
                    <Typography variant='body2'>{item}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.devDeployBiosphere}>
          <Box className={classes.boxContainer}>
            <Box className={classes.boxTitle}>
              <Typography className={classes.title}>
                DEV&DEPLOY:<br/>BIOSPHERE
              </Typography>
            </Box>
            <Grid container direction='row' spacing={1}>
              {devAndDeployBiosphereModules.map((item) => (
                <Grid item xs>
                  <Box className={classes.module}>
                    <Typography variant='body2'>{item}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid> */}
    </Fragment>
  );
};