import { GainSightApi } from './GainSightApi';
import {
  AnalyticsEvent,
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';

export const gainsightApiRef = createApiRef<GainSightApi>({
  id: 'plugin.gainsight.service',
});

export class GainSightClient implements GainSightApi {

  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: {fetchApi: FetchApi, discoveryApi: DiscoveryApi}) {
    this.fetchApi = options.fetchApi;
    this.discoveryApi = options.discoveryApi;
  }

  async getBaseUrl(): Promise<string> {
    return this.discoveryApi.getBaseUrl('gainsight');
  }

  async postAccount(): Promise<void> {
    const url = `${await this.getBaseUrl()}/account`;
    await this.fetchApi.fetch(url, {
      method: 'POST'
    });
  }

  async postUser(userId: string, displayName: string, email: string): Promise<void> {
    const url = `${await this.getBaseUrl()}/user`;
    await this.fetchApi.fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        userId: userId,
        displayName: displayName,
        email: email,
      }),
      headers: { 'Content-Type': 'application/json' },
    });
  }

  async postEvent(userId: string, event: AnalyticsEvent): Promise<void> {
    const url = `${await this.getBaseUrl()}/event`;
    await this.fetchApi.fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        userId: userId,
        event: event,
      }),
      headers: { 'Content-Type': 'application/json' },
    });
  }

}