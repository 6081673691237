import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { CatalogFilterLayout } from '@backstage/plugin-catalog-react';
import {
  Content,
  EmptyState,
  ErrorPanel,
  LinkButton,
  OverflowTooltip,
  PageWithHeader,
  Table,
} from '@backstage/core-components';
import { Cve } from '@giocolas/backstage-plugin-nvd-common';
import { Chip, Divider } from '@material-ui/core';
import { nvdApiRef } from '../../api';
import { NvdConfig } from '../../config';
import { SeverityTypePicker } from '../SeverityTypePicker';
import { DateTime } from 'luxon';
import { DateTimePicker } from '../DateTimePicker';
import { trimEnd } from 'lodash';

type Props = {
  title?: string;
  subtitle?: string;
};

export const CveIndexPage = (props: Props) => {

  const configApi = useApi(configApiRef);
  const nvdApi = useApi(nvdApiRef);

  const nvdConfig = NvdConfig.create(configApi);
  const detailUrl = nvdConfig.readDetailUrl();
  const defaultDateLimit = nvdConfig.readDefaultDateLimit();

  const [ severityTypes, setSeverityTypes ] = useState<string[]>([]);
  const [ publishedFrom, setPublishedFrom ] = useState<Date>(defaultDateLimit.startDate);
  const [ publishedTo, setPublishedTo ] = useState<Date>(defaultDateLimit.endDate);

  const { value, loading, error } = useAsync(() => {
    return nvdApi.getUsingNamedParameters({
      severityTypes: severityTypes.length ? severityTypes : undefined,
      publishedDateFrom: DateTime.fromJSDate(publishedFrom).toFormat('yyyy-MM-dd'),
      publishedDateTo: DateTime.fromJSDate(publishedTo).toFormat('yyyy-MM-dd'),
      orderColumn: 'published',
      orderType: 'desc',
    });
  }, [ nvdApi, severityTypes, publishedFrom, publishedTo, ]);

  if (error) {
    return (
      <>
        <ErrorPanel error={error} />
        <EmptyState
          missing='info'
          title='No information to display'
          description='There is no CVEs or there was an issue communicating with backend.'
        />
      </>
    );
  }
  
  return(
    <PageWithHeader title={props.title ?? 'National Vulnerability Database'} subtitle={props.subtitle} themeId='tool'>
      <Content>
        <CatalogFilterLayout>
          <CatalogFilterLayout.Filters>
            <SeverityTypePicker initialSeverityTypes={severityTypes} onSelectSeverityTypes={values => setSeverityTypes(values)} />
            <Divider />
            <DateTimePicker title='Published From' value={publishedFrom} onChangeValue={fromDate => setPublishedFrom(fromDate || defaultDateLimit.startDate)} />
            <DateTimePicker title='Published To' value={publishedTo} onChangeValue={toDate => setPublishedTo(toDate || defaultDateLimit.endDate)}/>
          </CatalogFilterLayout.Filters>
          <CatalogFilterLayout.Content>
            <Table<Cve>
              isLoading={loading}
              data={value ?? []}
              title={`Vulnerabilities (${value?.length})`}
              options={{
                pageSize: 20,
                pageSizeOptions: [20, 50, 100],
              }}
              columns={[
                {
                  title: 'Id',
                  field: 'id',
                  render: row => (
                    <LinkButton
                      color='primary'
                      aria-label={`${row.id}`}
                      to={`${trimEnd(detailUrl, '/')}/${row.id}`}
                    >
                      {row.id}
                    </LinkButton>
                  ),
                  width: '200px',
                },
                {
                  title: 'Score',
                  field: 'score',
                  render: row => (
                    <Chip
                      size='small'
                      label={row.score || 'N/A'}
                    />
                  ),
                  width: 'auto',
                },
                {
                  title: 'Severity',
                  field: 'severity',
                  render: row => (
                    <Chip
                      size='small'
                      label={row.severity || 'N/A'}
                      style={{
                        backgroundColor: nvdConfig.readSeverityColor(row.severity)?.backgroundColor,
                        color: nvdConfig.readSeverityColor(row.severity)?.foregroundColor
                      }}
                    />
                  ),
                  width: 'auto',
                },
                {
                  title: 'Description',
                  field: 'description',
                  render: row => (
                    <OverflowTooltip
                      text={row.description}
                      placement='bottom-start'
                    />
                  ),
                  width: 'auto'
                },
              ]}
            />
          </CatalogFilterLayout.Content>
        </CatalogFilterLayout>      
      </Content>
    </PageWithHeader>
  );
}