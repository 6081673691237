import React from 'react';
import capitalize from 'lodash/capitalize';
import { SeverityTypeStringArray } from '@giocolas/backstage-plugin-nvd-common';
import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export const SeverityTypePicker = (props: {
  initialSeverityTypes?: string[],
  onSelectSeverityTypes: (severityTypes: string[]) => void;
}) => {

  const { initialSeverityTypes, onSelectSeverityTypes } = props;

  return (
    <Box pb={1} pt={1}>
      <Typography
        variant='button'
        component='label'
        htmlFor='severity-picker'
      >
        Severities
      </Typography>
      <Autocomplete<string, true>
        id='severity-picker'
        multiple
        options={SeverityTypeStringArray}
        value={initialSeverityTypes || []}
        onChange={(_: object, value: string[]) => onSelectSeverityTypes(value)}
        renderOption={(option, { selected }) => (
          <FormControlLabel
            control={
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
            }
            label={capitalize(option)}
          />
        )}
        size='small'
        popupIcon={<ExpandMoreIcon data-testid='severities-picker-expand' />}
        renderInput={params => <TextField {...params} variant='outlined' />}
      />
    </Box>
  );
}